//RTK
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Activity } from '@priceApi/priceApi';
import { RootState } from '@app/store';

export interface IActivitiesState {
  activityOverlayOpen: boolean; //all state data, with additional properties
  seeAll: boolean;
  watchedActivities: Activity[];
}

const initialState: IActivitiesState = {
  activityOverlayOpen: false,
  seeAll: true,
  watchedActivities: []
};

// This is the state slice for Activities (batch jobs etc)
export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    toggleActivityOverlay: (state) => {
      state.activityOverlayOpen = !state.activityOverlayOpen;
    },
    toggleSeeAll: (state) => {
      state.seeAll = !state.seeAll;
    },
    addWatchedActivity: (state, action: PayloadAction<Activity>) => {
      state.watchedActivities.push(action.payload);
    },
    setWatchedActivities: (state, action: PayloadAction<Activity[]>) => {
      state.watchedActivities = action.payload;
    },
    clearWatchedActivities: (state) => {
      state.watchedActivities = initialState.watchedActivities;
    }
  }
});

export const { toggleActivityOverlay, toggleSeeAll, addWatchedActivity, setWatchedActivities, clearWatchedActivities } =
  activitiesSlice.actions;
export const selectActivityOverlay = (state: RootState) => state.activities.activityOverlayOpen;
export const selectSeeAll = (state: RootState) => state.activities.seeAll;
export const selectActivityCount = (state: RootState) => state.activities.watchedActivities.length;
export const selectWatchedActivities = (state: RootState) => state.activities.watchedActivities;
export default activitiesSlice.reducer;
