import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RiShieldKeyholeFill } from 'react-icons/ri';

export const AccessDeniedHeader = styled('h3')(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h3.fontWeight,
  textAlign: 'center',
  fontFamily: 'Arial',
  marginTop: '0.2em'
}));

export const AccessDeniedParagraph = styled('p')(({ theme }) => ({
  color: theme.typography.body1.color,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 'bold',
  fontFace: theme.typography.body1.FontFace,
  textAlign: 'center',
  fontFamily: 'Arial'
}));

export const RoleRouteContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh', // change this line
  width: '100vw',
  overflow: 'hidden',
  backgroundColor: theme.palette.primary.dark,
  boxSizing: 'border-box'
}));

export const RoleRouteContainerImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  backgroundColor: 'transparent',
  background: 'url("/ab-logo.svg") no-repeat center center fixed',
  backgroundPositionX: '-6em',
  backgroundPositionY: '80%',
  overflow: 'hidden'
}));

export const ShieldIcon = styled(RiShieldKeyholeFill)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '4em',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'Arial',
  margin: 'auto',
  display: 'flex'
}));

export const RoleRouteContainerCard = styled(Card)(({ theme }) => ({
  margin: 'auto',
  padding: '2em',
  zIndex: 2,
  border: `4px solid ${theme.palette.error.light}`
}));
