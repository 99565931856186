import { emptySplitApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOdataPlansByKeyFlatFrontlines: build.query<
      GetOdataPlansByKeyFlatFrontlinesApiResponse,
      GetOdataPlansByKeyFlatFrontlinesApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/plans(${queryArg.key})/flatFrontlines`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply
        }
      })
    }),
    getOdataPlansByKeyFlatFrontlinesCount: build.query<
      GetOdataPlansByKeyFlatFrontlinesCountApiResponse,
      GetOdataPlansByKeyFlatFrontlinesCountApiArg
    >({
      query: (queryArg) => ({ url: `/odata/plans(${queryArg.key})/flatFrontlines/$count` })
    }),
    getOdataPlansByKeyFlatDiscounts: build.query<
      GetOdataPlansByKeyFlatDiscountsApiResponse,
      GetOdataPlansByKeyFlatDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/plans(${queryArg.key})/flatDiscounts`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply,
          $expand: queryArg.$expand
        }
      })
    }),
    getOdataPlansByKeyFlatDiscountsCount: build.query<
      GetOdataPlansByKeyFlatDiscountsCountApiResponse,
      GetOdataPlansByKeyFlatDiscountsCountApiArg
    >({
      query: (queryArg) => ({ url: `/odata/plans(${queryArg.key})/flatDiscounts/$count` })
    }),
    getOdataPlansByKeyGuidelineExceptions: build.query<
      GetOdataPlansByKeyGuidelineExceptionsApiResponse,
      GetOdataPlansByKeyGuidelineExceptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/plans(${queryArg.key})/guidelineExceptions`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply
        }
      })
    }),
    getOdataPlansByKeyGuidelineExceptionsCount: build.query<
      GetOdataPlansByKeyGuidelineExceptionsCountApiResponse,
      GetOdataPlansByKeyGuidelineExceptionsCountApiArg
    >({
      query: (queryArg) => ({ url: `/odata/plans(${queryArg.key})/guidelineExceptions/$count` })
    }),
    getOdataPriceChangeRequestsByKeyFlatFrontlines: build.query<
      GetOdataPriceChangeRequestsByKeyFlatFrontlinesApiResponse,
      GetOdataPriceChangeRequestsByKeyFlatFrontlinesApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/priceChangeRequests(${queryArg.key})/flatFrontlines`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply
        }
      })
    }),
    getOdataPriceChangeRequestsByKeyFlatFrontlinesCount: build.query<
      GetOdataPriceChangeRequestsByKeyFlatFrontlinesCountApiResponse,
      GetOdataPriceChangeRequestsByKeyFlatFrontlinesCountApiArg
    >({
      query: (queryArg) => ({ url: `/odata/priceChangeRequests(${queryArg.key})/flatFrontlines/$count` })
    }),
    getOdataPriceChangeRequestsByKeyFlatDiscounts: build.query<
      GetOdataPriceChangeRequestsByKeyFlatDiscountsApiResponse,
      GetOdataPriceChangeRequestsByKeyFlatDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/priceChangeRequests(${queryArg.key})/flatDiscounts`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply,
          $expand: queryArg.$expand
        }
      })
    }),
    getOdataPriceChangeRequestsByKeyFlatDiscountsCount: build.query<
      GetOdataPriceChangeRequestsByKeyFlatDiscountsCountApiResponse,
      GetOdataPriceChangeRequestsByKeyFlatDiscountsCountApiArg
    >({
      query: (queryArg) => ({ url: `/odata/priceChangeRequests(${queryArg.key})/flatDiscounts/$count` })
    }),
    getOdataPriceChangeRequestsByKeyGuidelineExceptions: build.query<
      GetOdataPriceChangeRequestsByKeyGuidelineExceptionsApiResponse,
      GetOdataPriceChangeRequestsByKeyGuidelineExceptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/priceChangeRequests(${queryArg.key})/guidelineExceptions`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply
        }
      })
    }),
    getOdataPriceChangeRequestsByKeyGuidelineExceptionsCount: build.query<
      GetOdataPriceChangeRequestsByKeyGuidelineExceptionsCountApiResponse,
      GetOdataPriceChangeRequestsByKeyGuidelineExceptionsCountApiArg
    >({
      query: (queryArg) => ({ url: `/odata/priceChangeRequests(${queryArg.key})/guidelineExceptions/$count` })
    }),
    getOdataWholesalerGroups: build.query<GetOdataWholesalerGroupsApiResponse, GetOdataWholesalerGroupsApiArg>({
      query: (queryArg) => ({
        url: `/odata/wholesalerGroups`,
        params: {
          $filter: queryArg.$filter,
          $select: queryArg.$select,
          $count: queryArg.$count,
          $top: queryArg.$top,
          $skip: queryArg.$skip,
          $orderby: queryArg.$orderby,
          $apply: queryArg.$apply
        }
      })
    }),
    getOdataWholesalerGroupsCount: build.query<
      GetOdataWholesalerGroupsCountApiResponse,
      GetOdataWholesalerGroupsCountApiArg
    >({
      query: () => ({ url: `/odata/wholesalerGroups/$count` })
    }),
    getApiUserMe: build.query<GetApiUserMeApiResponse, GetApiUserMeApiArg>({
      query: () => ({ url: `/api/user/me` })
    }),
    getApiStates: build.query<GetApiStatesApiResponse, GetApiStatesApiArg>({
      query: () => ({ url: `/api/states` })
    }),
    getApiPlans: build.query<GetApiPlansApiResponse, GetApiPlansApiArg>({
      query: (queryArg) => ({
        url: `/api/plans`,
        params: {
          planSeqId: queryArg.planSeqId,
          planYear: queryArg.planYear,
          stateCode: queryArg.stateCode,
          refreshFlag: queryArg.refreshFlag,
          statusCode: queryArg.statusCode,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    postApiPlans: build.mutation<PostApiPlansApiResponse, PostApiPlansApiArg>({
      query: (queryArg) => ({ url: `/api/plans`, method: 'POST', body: queryArg.createPlanRequest })
    }),
    getApiPlansByPlanSeqId: build.query<GetApiPlansByPlanSeqIdApiResponse, GetApiPlansByPlanSeqIdApiArg>({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}` })
    }),
    getApiPlansByPlanSeqIdUser: build.query<GetApiPlansByPlanSeqIdUserApiResponse, GetApiPlansByPlanSeqIdUserApiArg>({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/user` })
    }),
    getApiPlansView: build.query<GetApiPlansViewApiResponse, GetApiPlansViewApiArg>({
      query: (queryArg) => ({
        url: `/api/plans/view`,
        params: {
          planId: queryArg.planId,
          planYear: queryArg.planYear,
          stateCode: queryArg.stateCode,
          regionCode: queryArg.regionCode,
          purged: queryArg.purged,
          approved: queryArg.approved,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiPlansByPlanSeqIdGuidelines: build.query<
      GetApiPlansByPlanSeqIdGuidelinesApiResponse,
      GetApiPlansByPlanSeqIdGuidelinesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/guidelines`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiPlansByPlanSeqIdGuidelinesLastRan: build.query<
      GetApiPlansByPlanSeqIdGuidelinesLastRanApiResponse,
      GetApiPlansByPlanSeqIdGuidelinesLastRanApiArg
    >({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/guidelines/last-ran` })
    }),
    getApiPlansByPlanSeqIdGuidelinesView: build.query<
      GetApiPlansByPlanSeqIdGuidelinesViewApiResponse,
      GetApiPlansByPlanSeqIdGuidelinesViewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/guidelines/view`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiPlansByPlanSeqIdFrontlinesUniqueDimensions: build.query<
      GetApiPlansByPlanSeqIdFrontlinesUniqueDimensionsApiResponse,
      GetApiPlansByPlanSeqIdFrontlinesUniqueDimensionsApiArg
    >({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/frontlines/unique-dimensions` })
    }),
    getApiPlansByPlanSeqIdDiscounts: build.query<
      GetApiPlansByPlanSeqIdDiscountsApiResponse,
      GetApiPlansByPlanSeqIdDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts`,
        params: { includeChildren: queryArg.includeChildren, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    postApiPlansByPlanSeqIdDiscounts: build.mutation<
      PostApiPlansByPlanSeqIdDiscountsApiResponse,
      PostApiPlansByPlanSeqIdDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts`,
        method: 'POST',
        body: queryArg.createDiscountRequest
      })
    }),
    getApiPlansByPlanSeqIdDiscountsUniqueDimensions: build.query<
      GetApiPlansByPlanSeqIdDiscountsUniqueDimensionsApiResponse,
      GetApiPlansByPlanSeqIdDiscountsUniqueDimensionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts/unique-dimensions`,
        params: { includeSubGeographies: queryArg.includeSubGeographies }
      })
    }),
    getApiPlansByPlanSeqIdDiscountsView: build.query<
      GetApiPlansByPlanSeqIdDiscountsViewApiResponse,
      GetApiPlansByPlanSeqIdDiscountsViewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts/view`,
        params: {
          includeChildren: queryArg.includeChildren,
          includeInactive: queryArg.includeInactive,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiPlansDetailsView: build.query<GetApiPlansDetailsViewApiResponse, GetApiPlansDetailsViewApiArg>({
      query: (queryArg) => ({
        url: `/api/plans/details/view`,
        params: {
          dealHdrSeqId: queryArg.dealHdrSeqId,
          pcrSeqId: queryArg.pcrSeqId,
          prcChgHdrSeqId: queryArg.prcChgHdrSeqId,
          nrpVersSeqId: queryArg.nrpVersSeqId,
          dealDtlSeqId: queryArg.dealDtlSeqId,
          varSpltsSeqId: queryArg.varSpltsSeqId,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiPcrs: build.query<GetApiPcrsApiResponse, GetApiPcrsApiArg>({
      query: (queryArg) => ({
        url: `/api/pcrs`,
        params: {
          pcrSeqId: queryArg.pcrSeqId,
          statCd: queryArg.statCd,
          yearSubmitted: queryArg.yearSubmitted,
          pcrTyp: queryArg.pcrTyp,
          stateCd: queryArg.stateCd,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    postApiPcrs: build.mutation<PostApiPcrsApiResponse, PostApiPcrsApiArg>({
      query: (queryArg) => ({ url: `/api/pcrs`, method: 'POST', body: queryArg.createPcrRequest })
    }),
    getApiPcrsByPcrSeqId: build.query<GetApiPcrsByPcrSeqIdApiResponse, GetApiPcrsByPcrSeqIdApiArg>({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}` })
    }),
    getApiPcrsView: build.query<GetApiPcrsViewApiResponse, GetApiPcrsViewApiArg>({
      query: (queryArg) => ({
        url: `/api/pcrs/view`,
        params: {
          pcrSeqId: queryArg.pcrSeqId,
          status: queryArg.status,
          startModifiedDate: queryArg.startModifiedDate,
          endModifiedDate: queryArg.endModifiedDate,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiPcrsStagingById: build.query<GetApiPcrsStagingByIdApiResponse, GetApiPcrsStagingByIdApiArg>({
      query: (queryArg) => ({
        url: `/api/pcrs/staging/${queryArg.id}`,
        params: { includeAllRows: queryArg.includeAllRows }
      })
    }),
    getApiPcrsStagingByIdFrontlines: build.query<
      GetApiPcrsStagingByIdFrontlinesApiResponse,
      GetApiPcrsStagingByIdFrontlinesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/staging/${queryArg.id}/frontlines`,
        params: { includeAllRows: queryArg.includeAllRows }
      })
    }),
    getApiPcrsStagingByIdDiscounts: build.query<
      GetApiPcrsStagingByIdDiscountsApiResponse,
      GetApiPcrsStagingByIdDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/staging/${queryArg.id}/discounts`,
        params: { includeAllRows: queryArg.includeAllRows }
      })
    }),
    getApiPcrsByPcrSeqIdGuidelines: build.query<
      GetApiPcrsByPcrSeqIdGuidelinesApiResponse,
      GetApiPcrsByPcrSeqIdGuidelinesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/guidelines`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiPcrsByPcrSeqIdGuidelinesLastRan: build.query<
      GetApiPcrsByPcrSeqIdGuidelinesLastRanApiResponse,
      GetApiPcrsByPcrSeqIdGuidelinesLastRanApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/guidelines/last-ran` })
    }),
    getApiPcrsByPcrSeqIdGuidelinesView: build.query<
      GetApiPcrsByPcrSeqIdGuidelinesViewApiResponse,
      GetApiPcrsByPcrSeqIdGuidelinesViewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/guidelines/view`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiPcrsByPcrSeqIdFrontlinesFlatNoFilter: build.query<
      GetApiPcrsByPcrSeqIdFrontlinesFlatNoFilterApiResponse,
      GetApiPcrsByPcrSeqIdFrontlinesFlatNoFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/flat-no-filter`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiPcrsByPcrSeqIdFrontlinesUniqueDimensions: build.query<
      GetApiPcrsByPcrSeqIdFrontlinesUniqueDimensionsApiResponse,
      GetApiPcrsByPcrSeqIdFrontlinesUniqueDimensionsApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/unique-dimensions` })
    }),
    getApiPcrsByPcrSeqIdDiscounts: build.query<
      GetApiPcrsByPcrSeqIdDiscountsApiResponse,
      GetApiPcrsByPcrSeqIdDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts`,
        params: { includeChildren: queryArg.includeChildren, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    postApiPcrsByPcrSeqIdDiscounts: build.mutation<
      PostApiPcrsByPcrSeqIdDiscountsApiResponse,
      PostApiPcrsByPcrSeqIdDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts`,
        method: 'POST',
        body: queryArg.createDiscountRequest
      })
    }),
    getApiPcrsByPcrSeqIdDiscountsUniqueDimensions: build.query<
      GetApiPcrsByPcrSeqIdDiscountsUniqueDimensionsApiResponse,
      GetApiPcrsByPcrSeqIdDiscountsUniqueDimensionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/unique-dimensions`,
        params: { includeSubGeographies: queryArg.includeSubGeographies }
      })
    }),
    getApiPcrsByPcrSeqIdDiscountsView: build.query<
      GetApiPcrsByPcrSeqIdDiscountsViewApiResponse,
      GetApiPcrsByPcrSeqIdDiscountsViewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/view`,
        params: {
          includeChildren: queryArg.includeChildren,
          includeInactive: queryArg.includeInactive,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiPcrsByPcrSeqIdDiscountsConcurrencyViolations: build.query<
      GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsApiResponse,
      GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/concurrency-violations`,
        params: { includeChildren: queryArg.includeChildren, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsStatus: build.query<
      GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsStatusApiResponse,
      GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsStatusApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/concurrency-violations/status` })
    }),
    getApiPackagesSelections: build.query<GetApiPackagesSelectionsApiResponse, GetApiPackagesSelectionsApiArg>({
      query: (queryArg) => ({
        url: `/api/packages/selections`,
        params: {
          pkgGrpSeqId: queryArg.pkgGrpSeqId,
          description: queryArg.description,
          draftOption: queryArg.draftOption,
          includeInactive: queryArg.includeInactive,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiLiquidtypes: build.query<GetApiLiquidtypesApiResponse, GetApiLiquidtypesApiArg>({
      query: () => ({ url: `/api/liquidtypes` })
    }),
    getApiLiquidtypesByLiquidTypeMappings: build.query<
      GetApiLiquidtypesByLiquidTypeMappingsApiResponse,
      GetApiLiquidtypesByLiquidTypeMappingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/liquidtypes/${queryArg.liquidType}/mappings`,
        params: {
          stateCode: queryArg.stateCode,
          brandSeqId: queryArg.brandSeqId,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiLegendPcrs: build.query<GetApiLegendPcrsApiResponse, GetApiLegendPcrsApiArg>({
      query: (queryArg) => ({
        url: `/api/legend/pcrs`,
        params: { id: queryArg.id, pcrTypes: queryArg.pcrTypes, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiGuidelinesByExcpSeqIdSimilar: build.query<
      GetApiGuidelinesByExcpSeqIdSimilarApiResponse,
      GetApiGuidelinesByExcpSeqIdSimilarApiArg
    >({
      query: (queryArg) => ({
        url: `/api/guidelines/${queryArg.excpSeqId}/similar`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiGeographies: build.query<GetApiGeographiesApiResponse, GetApiGeographiesApiArg>({
      query: (queryArg) => ({
        url: `/api/geographies`,
        params: {
          wslrCustGroupSeqId: queryArg.wslrCustGroupSeqId,
          wslrCustGroupTypeCd: queryArg.wslrCustGroupTypeCd,
          wslr: queryArg.wslr,
          stateCode: queryArg.stateCode,
          statusCode: queryArg.statusCode,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiGeographiesView: build.query<GetApiGeographiesViewApiResponse, GetApiGeographiesViewApiArg>({
      query: (queryArg) => ({
        url: `/api/geographies/view`,
        params: {
          wslrCustGroupSeqId: queryArg.wslrCustGroupSeqId,
          wslr: queryArg.wslr,
          stateCode: queryArg.stateCode,
          statusCode: queryArg.statusCode,
          wslrCustGroupTypeCd: queryArg.wslrCustGroupTypeCd,
          includeBreweryAccounts: queryArg.includeBreweryAccounts,
          includeSalesForecastingAccounts: queryArg.includeSalesForecastingAccounts,
          includeMerchandisingAccounts: queryArg.includeMerchandisingAccounts,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiGeographiesWholesalersSelections: build.query<
      GetApiGeographiesWholesalersSelectionsApiResponse,
      GetApiGeographiesWholesalersSelectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/geographies/wholesalers/selections`,
        params: {
          subWholesalerLevel: queryArg.subWholesalerLevel,
          wslrCustGrpSeqId: queryArg.wslrCustGrpSeqId,
          wslrNumber: queryArg.wslrNumber,
          stateCode: queryArg.stateCode,
          regionCode: queryArg.regionCode,
          includeInactive: queryArg.includeInactive,
          includeNonEquity: queryArg.includeNonEquity,
          excludeSpecialty: queryArg.excludeSpecialty,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiGeographiesWholesalersSelectionsGroupsByWholesalerGroupIdMembers: build.query<
      GetApiGeographiesWholesalersSelectionsGroupsByWholesalerGroupIdMembersApiResponse,
      GetApiGeographiesWholesalersSelectionsGroupsByWholesalerGroupIdMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/geographies/wholesalers/selections/groups/${queryArg.wholesalerGroupId}/members`
      })
    }),
    getApiFrontlines: build.query<GetApiFrontlinesApiResponse, GetApiFrontlinesApiArg>({
      query: (queryArg) => ({
        url: `/api/frontlines`,
        params: {
          frontlineId: queryArg.frontlineId,
          planSeqId: queryArg.planSeqId,
          pcrSeqId: queryArg.pcrSeqId,
          masterDealSeqId: queryArg.masterDealSeqId,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiFrontlinesByFrontlineId: build.query<
      GetApiFrontlinesByFrontlineIdApiResponse,
      GetApiFrontlinesByFrontlineIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/frontlines/${queryArg.frontlineId}` })
    }),
    getApiFeaturesByNameEnabled: build.query<GetApiFeaturesByNameEnabledApiResponse, GetApiFeaturesByNameEnabledApiArg>(
      {
        query: (queryArg) => ({ url: `/api/features/${queryArg.name}/enabled` })
      }
    ),
    getApiFeatures: build.query<GetApiFeaturesApiResponse, GetApiFeaturesApiArg>({
      query: () => ({ url: `/api/features` })
    }),
    getApiDiscountsByDealHdrSeqIdAccountDetails: build.query<
      GetApiDiscountsByDealHdrSeqIdAccountDetailsApiResponse,
      GetApiDiscountsByDealHdrSeqIdAccountDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/api/discounts/${queryArg.dealHdrSeqId}/account-details` })
    }),
    getApiDiscountsByDealHdrSeqIdView: build.query<
      GetApiDiscountsByDealHdrSeqIdViewApiResponse,
      GetApiDiscountsByDealHdrSeqIdViewApiArg
    >({
      query: (queryArg) => ({ url: `/api/discounts/${queryArg.dealHdrSeqId}/view` })
    }),
    getApiBrands: build.query<GetApiBrandsApiResponse, GetApiBrandsApiArg>({
      query: (queryArg) => ({
        url: `/api/brands`,
        params: {
          brandSeqId: queryArg.brandSeqId,
          statCd: queryArg.statCd,
          brandName: queryArg.brandName,
          brandCode: queryArg.brandCode,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiBrandsByBrandSeqId: build.query<GetApiBrandsByBrandSeqIdApiResponse, GetApiBrandsByBrandSeqIdApiArg>({
      query: (queryArg) => ({ url: `/api/brands/${queryArg.brandSeqId}` })
    }),
    getApiBrandsSelections: build.query<GetApiBrandsSelectionsApiResponse, GetApiBrandsSelectionsApiArg>({
      query: (queryArg) => ({
        url: `/api/brands/selections`,
        params: {
          brandSeqId: queryArg.brandSeqId,
          name: queryArg.name,
          brandType: queryArg.brandType,
          varietyPack: queryArg.varietyPack,
          includeInactive: queryArg.includeInactive,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiBrandsGroups: build.query<GetApiBrandsGroupsApiResponse, GetApiBrandsGroupsApiArg>({
      query: (queryArg) => ({
        url: `/api/brands/groups`,
        params: {
          brandGrpId: queryArg.brandGrpId,
          brandGroupType: queryArg.brandGroupType,
          includeInactive: queryArg.includeInactive,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiBrandsGroupsByBrandGrpId: build.query<
      GetApiBrandsGroupsByBrandGrpIdApiResponse,
      GetApiBrandsGroupsByBrandGrpIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/brands/groups/${queryArg.brandGrpId}` })
    }),
    getApiBrandsGroupsByBrandGrpIdMembers: build.query<
      GetApiBrandsGroupsByBrandGrpIdMembersApiResponse,
      GetApiBrandsGroupsByBrandGrpIdMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/brands/groups/${queryArg.brandGrpId}/members`,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiBatch: build.query<GetApiBatchApiResponse, GetApiBatchApiArg>({
      query: (queryArg) => ({
        url: `/api/batch`,
        params: { batchJobId: queryArg.batchJobId, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    getApiBatchByBatchJobId: build.query<GetApiBatchByBatchJobIdApiResponse, GetApiBatchByBatchJobIdApiArg>({
      query: (queryArg) => ({ url: `/api/batch/${queryArg.batchJobId}` })
    }),
    getApiAlcohols: build.query<GetApiAlcoholsApiResponse, GetApiAlcoholsApiArg>({
      query: () => ({ url: `/api/alcohols` })
    }),
    getApiHealth: build.query<GetApiHealthApiResponse, GetApiHealthApiArg>({
      query: () => ({ url: `/api/_health` })
    }),
    getApiAdminNow: build.query<GetApiAdminNowApiResponse, GetApiAdminNowApiArg>({
      query: () => ({ url: `/api/admin/now` })
    }),
    getApiAdminServerError: build.query<GetApiAdminServerErrorApiResponse, GetApiAdminServerErrorApiArg>({
      query: () => ({ url: `/api/admin/server-error` })
    }),
    getApiActivities: build.query<GetApiActivitiesApiResponse, GetApiActivitiesApiArg>({
      query: (queryArg) => ({
        url: `/api/activities`,
        params: {
          activityId: queryArg.activityId,
          userId: queryArg.userId,
          start: queryArg.start,
          end: queryArg.end,
          limit: queryArg.limit,
          offset: queryArg.offset
        }
      })
    }),
    getApiActivitiesByActivityId: build.query<
      GetApiActivitiesByActivityIdApiResponse,
      GetApiActivitiesByActivityIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/activities/${queryArg.activityId}` })
    }),
    getOdataMetadata: build.query<GetOdataMetadataApiResponse, GetOdataMetadataApiArg>({
      query: () => ({ url: `/odata/$metadata` })
    }),
    getOdata: build.query<GetOdataApiResponse, GetOdataApiArg>({
      query: () => ({ url: `/odata` })
    }),
    postApiPlansByPlanSeqIdApprove: build.mutation<
      PostApiPlansByPlanSeqIdApproveApiResponse,
      PostApiPlansByPlanSeqIdApproveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/approve`,
        method: 'POST',
        params: { setPlanAsCurrent: queryArg.setPlanAsCurrent }
      })
    }),
    postApiPlansByPlanSeqIdCalendarRollover: build.mutation<
      PostApiPlansByPlanSeqIdCalendarRolloverApiResponse,
      PostApiPlansByPlanSeqIdCalendarRolloverApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/calendar-rollover`,
        method: 'POST',
        body: queryArg.calendarRolloverSelections
      })
    }),
    postApiPlansByPlanSeqIdCalendarRolloverDebug: build.mutation<
      PostApiPlansByPlanSeqIdCalendarRolloverDebugApiResponse,
      PostApiPlansByPlanSeqIdCalendarRolloverDebugApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/calendar-rollover-debug`,
        method: 'POST',
        body: queryArg.calendarRolloverSelectionsDebug
      })
    }),
    postApiPlansByPlanSeqIdCheckIn: build.mutation<
      PostApiPlansByPlanSeqIdCheckInApiResponse,
      PostApiPlansByPlanSeqIdCheckInApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/check-in`,
        method: 'POST',
        params: { overrideUserCheck: queryArg.overrideUserCheck }
      })
    }),
    postApiPlansByPlanSeqIdCheckOut: build.mutation<
      PostApiPlansByPlanSeqIdCheckOutApiResponse,
      PostApiPlansByPlanSeqIdCheckOutApiArg
    >({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/check-out`, method: 'POST' })
    }),
    postApiPlansByPlanSeqIdDelete: build.mutation<
      PostApiPlansByPlanSeqIdDeleteApiResponse,
      PostApiPlansByPlanSeqIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/delete`, method: 'POST' })
    }),
    postApiPlansByPlanSeqIdRefresh: build.mutation<
      PostApiPlansByPlanSeqIdRefreshApiResponse,
      PostApiPlansByPlanSeqIdRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/refresh`,
        method: 'POST',
        params: { runAsBatch: queryArg.runAsBatch }
      })
    }),
    postApiPlansByPlanSeqIdSetCurrent: build.mutation<
      PostApiPlansByPlanSeqIdSetCurrentApiResponse,
      PostApiPlansByPlanSeqIdSetCurrentApiArg
    >({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/set-current`, method: 'POST' })
    }),
    postApiPlansByPlanSeqIdSetDescription: build.mutation<
      PostApiPlansByPlanSeqIdSetDescriptionApiResponse,
      PostApiPlansByPlanSeqIdSetDescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/set-description`,
        method: 'POST',
        body: queryArg.descriptionEntry
      })
    }),
    postApiPlansByPlanSeqIdUpdateNote: build.mutation<
      PostApiPlansByPlanSeqIdUpdateNoteApiResponse,
      PostApiPlansByPlanSeqIdUpdateNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/update-note`,
        method: 'POST',
        body: queryArg.updateNoteRequestBody
      })
    }),
    postApiPlansByPlanSeqIdGuidelinesOverride: build.mutation<
      PostApiPlansByPlanSeqIdGuidelinesOverrideApiResponse,
      PostApiPlansByPlanSeqIdGuidelinesOverrideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/guidelines/override`,
        method: 'POST',
        body: queryArg.overrideAllGuidelinesRequestBody
      })
    }),
    postApiPlansByPlanSeqIdGuidelinesRun: build.mutation<
      PostApiPlansByPlanSeqIdGuidelinesRunApiResponse,
      PostApiPlansByPlanSeqIdGuidelinesRunApiArg
    >({
      query: (queryArg) => ({ url: `/api/plans/${queryArg.planSeqId}/guidelines/run`, method: 'POST' })
    }),
    postApiPlansByPlanSeqIdFrontlinesChangeEffectiveDate: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesChangeEffectiveDateApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesChangeEffectiveDateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/change-effective-date`,
        method: 'POST',
        body: queryArg.changeEffectiveDateRequestBody
      })
    }),
    postApiPlansByPlanSeqIdFrontlineMassCopy: build.mutation<
      PostApiPlansByPlanSeqIdFrontlineMassCopyApiResponse,
      PostApiPlansByPlanSeqIdFrontlineMassCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontline/mass-copy`,
        method: 'POST',
        body: queryArg.planFrontlineMassCopyRequestBody
      })
    }),
    postApiPlansByPlanSeqIdFrontlinesParityWithExisting: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesParityWithExistingApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesParityWithExistingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/parity-with-existing`,
        method: 'POST',
        body: queryArg.parityWithExistingRequestBody
      })
    }),
    postApiPlansByPlanSeqIdFrontlinesPtrIncrement: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesPtrIncrementApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesPtrIncrementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/ptr-increment`,
        method: 'POST',
        body: queryArg.ptrIncrementRequestBody
      })
    }),
    postApiPlansByPlanSeqIdFrontlinesUpdate: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesUpdateApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/update`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postApiPlansByPlanSeqIdFrontlinesUpdateBatch: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesUpdateBatchApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesUpdateBatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/update-batch`,
        method: 'POST',
        body: queryArg.updateBatchRequestBody
      })
    }),
    postApiPlansByPlanSeqIdFrontlinesAndFrontlineIdUpdate: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesAndFrontlineIdUpdateApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesAndFrontlineIdUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/${queryArg.frontlineId}/update`,
        method: 'POST',
        body: queryArg.updateByIdRequestBody
      })
    }),
    postApiPlansByPlanSeqIdFrontlinesView: build.mutation<
      PostApiPlansByPlanSeqIdFrontlinesViewApiResponse,
      PostApiPlansByPlanSeqIdFrontlinesViewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/frontlines/view`,
        method: 'POST',
        body: queryArg.dimensionSelections,
        params: { includeChildren: queryArg.includeChildren, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    postApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdCancel: build.mutation<
      PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdCancelApiResponse,
      PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdCancelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts/${queryArg.dealHdrSeqId}/cancel`,
        method: 'POST'
      })
    }),
    postApiPlansByPlanSeqIdDiscountsMassCopy: build.mutation<
      PostApiPlansByPlanSeqIdDiscountsMassCopyApiResponse,
      PostApiPlansByPlanSeqIdDiscountsMassCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts/mass-copy`,
        method: 'POST',
        body: queryArg.discountMassCopyOptions
      })
    }),
    postApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdRemove: build.mutation<
      PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdRemoveApiResponse,
      PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/discounts/${queryArg.dealHdrSeqId}/remove`,
        method: 'POST',
        params: { deleteRelated: queryArg.deleteRelated, deleteFrontlineDeals: queryArg.deleteFrontlineDeals }
      })
    }),
    postApiPlansByPlanSeqIdRmDiscounts: build.mutation<
      PostApiPlansByPlanSeqIdRmDiscountsApiResponse,
      PostApiPlansByPlanSeqIdRmDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/plans/${queryArg.planSeqId}/rm-discounts`,
        method: 'POST',
        body: queryArg.rmCreateDiscountRequest
      })
    }),
    postApiPcrsByPcrSeqIdApprove: build.mutation<
      PostApiPcrsByPcrSeqIdApproveApiResponse,
      PostApiPcrsByPcrSeqIdApproveApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/approve`, method: 'POST' })
    }),
    postApiPcrsByPcrSeqIdCascadeDiscounts: build.mutation<
      PostApiPcrsByPcrSeqIdCascadeDiscountsApiResponse,
      PostApiPcrsByPcrSeqIdCascadeDiscountsApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/cascade-discounts`, method: 'POST' })
    }),
    postApiPcrsCascadeDiscounts: build.mutation<
      PostApiPcrsCascadeDiscountsApiResponse,
      PostApiPcrsCascadeDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/cascade-discounts`,
        method: 'POST',
        body: queryArg.cascadeDiscountsBatchRequest
      })
    }),
    postApiPcrsByPcrSeqIdCheckIn: build.mutation<
      PostApiPcrsByPcrSeqIdCheckInApiResponse,
      PostApiPcrsByPcrSeqIdCheckInApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/check-in`,
        method: 'POST',
        params: { overrideUserCheck: queryArg.overrideUserCheck }
      })
    }),
    postApiPcrsByPcrSeqIdCheckOut: build.mutation<
      PostApiPcrsByPcrSeqIdCheckOutApiResponse,
      PostApiPcrsByPcrSeqIdCheckOutApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/check-out`, method: 'POST' })
    }),
    postApiPcrsCreateHeader: build.mutation<PostApiPcrsCreateHeaderApiResponse, PostApiPcrsCreateHeaderApiArg>({
      query: (queryArg) => ({ url: `/api/pcrs/create-header`, method: 'POST', body: queryArg.createPcrHeaderRequest })
    }),
    postApiPcrsByPcrSeqIdRefresh: build.mutation<
      PostApiPcrsByPcrSeqIdRefreshApiResponse,
      PostApiPcrsByPcrSeqIdRefreshApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/refresh`, method: 'POST' })
    }),
    postApiPcrsByPcrSeqIdReject: build.mutation<
      PostApiPcrsByPcrSeqIdRejectApiResponse,
      PostApiPcrsByPcrSeqIdRejectApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/reject`, method: 'POST' })
    }),
    postApiPcrsByPcrSeqIdSetDescription: build.mutation<
      PostApiPcrsByPcrSeqIdSetDescriptionApiResponse,
      PostApiPcrsByPcrSeqIdSetDescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/set-description`,
        method: 'POST',
        body: queryArg.descriptionEntry
      })
    }),
    postApiPcrsByPcrSeqIdUpdateNote: build.mutation<
      PostApiPcrsByPcrSeqIdUpdateNoteApiResponse,
      PostApiPcrsByPcrSeqIdUpdateNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/update-note`,
        method: 'POST',
        body: queryArg.updateNoteRequestBody
      })
    }),
    postApiPcrsStagingLegendByLegendPcrId: build.mutation<
      PostApiPcrsStagingLegendByLegendPcrIdApiResponse,
      PostApiPcrsStagingLegendByLegendPcrIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/staging/legend/${queryArg.legendPcrId}`,
        method: 'POST',
        params: { pcrTypes: queryArg.pcrTypes, effectiveDate: queryArg.effectiveDate }
      })
    }),
    postApiPcrsStagingPricingForm: build.mutation<
      PostApiPcrsStagingPricingFormApiResponse,
      PostApiPcrsStagingPricingFormApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/staging/pricing-form`,
        method: 'POST',
        body: queryArg.body,
        params: { pcrTypes: queryArg.pcrTypes, proposedEffectiveDate: queryArg.proposedEffectiveDate }
      })
    }),
    postApiPcrsStagingByIdSubmit: build.mutation<
      PostApiPcrsStagingByIdSubmitApiResponse,
      PostApiPcrsStagingByIdSubmitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/staging/${queryArg.id}/submit`,
        method: 'POST',
        params: { pcrSeqId: queryArg.pcrSeqId }
      })
    }),
    postApiPcrsByPcrSeqIdGuidelinesOverride: build.mutation<
      PostApiPcrsByPcrSeqIdGuidelinesOverrideApiResponse,
      PostApiPcrsByPcrSeqIdGuidelinesOverrideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/guidelines/override`,
        method: 'POST',
        body: queryArg.overrideAllGuidelinesRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdGuidelinesRun: build.mutation<
      PostApiPcrsByPcrSeqIdGuidelinesRunApiResponse,
      PostApiPcrsByPcrSeqIdGuidelinesRunApiArg
    >({
      query: (queryArg) => ({ url: `/api/pcrs/${queryArg.pcrSeqId}/guidelines/run`, method: 'POST' })
    }),
    postApiPcrsByPcrSeqIdFrontlinesChangeEffectiveDate: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesChangeEffectiveDateApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesChangeEffectiveDateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/change-effective-date`,
        method: 'POST',
        body: queryArg.changeEffectiveDateRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesFlatFilter: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesFlatFilterApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesFlatFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/flat-filter`,
        method: 'POST',
        body: queryArg.uniqueDimensions,
        params: { limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    postApiPcrsByPcrSeqIdFrontlineMassCopy: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlineMassCopyApiResponse,
      PostApiPcrsByPcrSeqIdFrontlineMassCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontline/mass-copy`,
        method: 'POST',
        body: queryArg.pcrFrontlineMassCopyRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesParityWithExisting: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesParityWithExistingApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesParityWithExistingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/parity-with-existing`,
        method: 'POST',
        body: queryArg.parityWithExistingRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesPtrIncrement: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesPtrIncrementApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesPtrIncrementApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/ptr-increment`,
        method: 'POST',
        body: queryArg.ptrIncrementRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdRemove: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdRemoveApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/${queryArg.frontlineId}/remove`,
        method: 'POST',
        params: { deleteRelated: queryArg.deleteRelated, deleteFrontlineDeals: queryArg.deleteFrontlineDeals }
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesSearchAndAdd: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesSearchAndAddApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesSearchAndAddApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/search-and-add`,
        method: 'POST',
        body: queryArg.searchAndAddRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesUpdate: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesUpdateApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/update`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesUpdateBatch: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesUpdateBatchApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesUpdateBatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/update-batch`,
        method: 'POST',
        body: queryArg.updateBatchRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdUpdate: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdUpdateApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/${queryArg.frontlineId}/update`,
        method: 'POST',
        body: queryArg.updateByIdRequestBody
      })
    }),
    postApiPcrsByPcrSeqIdFrontlinesView: build.mutation<
      PostApiPcrsByPcrSeqIdFrontlinesViewApiResponse,
      PostApiPcrsByPcrSeqIdFrontlinesViewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/frontlines/view`,
        method: 'POST',
        body: queryArg.dimensionSelections,
        params: { includeChildren: queryArg.includeChildren, limit: queryArg.limit, offset: queryArg.offset }
      })
    }),
    postApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdCancel: build.mutation<
      PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdCancelApiResponse,
      PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdCancelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/${queryArg.dealHdrSeqId}/cancel`,
        method: 'POST'
      })
    }),
    postApiPcrsByPcrSeqIdDiscountsMassCopy: build.mutation<
      PostApiPcrsByPcrSeqIdDiscountsMassCopyApiResponse,
      PostApiPcrsByPcrSeqIdDiscountsMassCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/mass-copy`,
        method: 'POST',
        body: queryArg.discountMassCopyOptions
      })
    }),
    postApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdRemove: build.mutation<
      PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdRemoveApiResponse,
      PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/${queryArg.dealHdrSeqId}/remove`,
        method: 'POST',
        params: { deleteRelated: queryArg.deleteRelated, deleteFrontlineDeals: queryArg.deleteFrontlineDeals }
      })
    }),
    postApiPcrsByPcrSeqIdRmDiscounts: build.mutation<
      PostApiPcrsByPcrSeqIdRmDiscountsApiResponse,
      PostApiPcrsByPcrSeqIdRmDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/rm-discounts`,
        method: 'POST',
        body: queryArg.rmCreateDiscountRequest
      })
    }),
    postApiPcrsByPcrSeqIdDiscountsSearchAndAdd: build.mutation<
      PostApiPcrsByPcrSeqIdDiscountsSearchAndAddApiResponse,
      PostApiPcrsByPcrSeqIdDiscountsSearchAndAddApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/search-and-add`,
        method: 'POST',
        body: queryArg.searchAndAddOptions
      })
    }),
    postApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsAdd: build.mutation<
      PostApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsAddApiResponse,
      PostApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsAddApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pcrs/${queryArg.pcrSeqId}/discounts/concurrency-violations/add`,
        method: 'POST'
      })
    }),
    postApiGuidelinesByExcpSeqIdOverride: build.mutation<
      PostApiGuidelinesByExcpSeqIdOverrideApiResponse,
      PostApiGuidelinesByExcpSeqIdOverrideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/guidelines/${queryArg.excpSeqId}/override`,
        method: 'POST',
        body: queryArg.comment
      })
    }),
    postApiGuidelinesOverride: build.mutation<PostApiGuidelinesOverrideApiResponse, PostApiGuidelinesOverrideApiArg>({
      query: (queryArg) => ({
        url: `/api/guidelines/override`,
        method: 'POST',
        body: queryArg.overrideGuidelinesRequest
      })
    }),
    postApiFrontlinesFobDelete: build.mutation<PostApiFrontlinesFobDeleteApiResponse, PostApiFrontlinesFobDeleteApiArg>(
      {
        query: (queryArg) => ({ url: `/api/frontlines/fob-delete`, method: 'POST', body: queryArg.fobDeleteRequest })
      }
    ),
    postApiFrontlinesRemove: build.mutation<PostApiFrontlinesRemoveApiResponse, PostApiFrontlinesRemoveApiArg>({
      query: (queryArg) => ({ url: `/api/frontlines/remove`, method: 'POST', body: queryArg.removeFrontlinesRequest })
    }),
    postApiFrontlinesUpdateBatch: build.mutation<
      PostApiFrontlinesUpdateBatchApiResponse,
      PostApiFrontlinesUpdateBatchApiArg
    >({
      query: (queryArg) => ({ url: `/api/frontlines/update-batch`, method: 'POST', body: queryArg.updateBatchRequest })
    }),
    postApiDiscountsByDealHdrSeqIdUpdate: build.mutation<
      PostApiDiscountsByDealHdrSeqIdUpdateApiResponse,
      PostApiDiscountsByDealHdrSeqIdUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/${queryArg.dealHdrSeqId}/update`,
        method: 'POST',
        body: queryArg.discount
      })
    }),
    postApiDiscountsUpdate: build.mutation<PostApiDiscountsUpdateApiResponse, PostApiDiscountsUpdateApiArg>({
      query: (queryArg) => ({ url: `/api/discounts/update`, method: 'POST', body: queryArg.updateBatchDiscountRequest })
    }),
    postApiDiscountsUpdateHeaders: build.mutation<
      PostApiDiscountsUpdateHeadersApiResponse,
      PostApiDiscountsUpdateHeadersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/update-headers`,
        method: 'POST',
        body: queryArg.updateDiscountHeadersRequest
      })
    }),
    postApiDiscountsUpdateLevels: build.mutation<
      PostApiDiscountsUpdateLevelsApiResponse,
      PostApiDiscountsUpdateLevelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/update-levels`,
        method: 'POST',
        body: queryArg.updateDiscountLevelsRequest
      })
    }),
    postApiDiscountsUpdateTiers: build.mutation<
      PostApiDiscountsUpdateTiersApiResponse,
      PostApiDiscountsUpdateTiersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/update-tiers`,
        method: 'POST',
        body: queryArg.updateDiscountTiersRequest
      })
    }),
    postApiDiscountsUpdateTierValues: build.mutation<
      PostApiDiscountsUpdateTierValuesApiResponse,
      PostApiDiscountsUpdateTierValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/update-tier-values`,
        method: 'POST',
        body: queryArg.updateDiscountTierValuesRequest
      })
    }),
    postApiDiscountsUpdateValues: build.mutation<
      PostApiDiscountsUpdateValuesApiResponse,
      PostApiDiscountsUpdateValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/update-values`,
        method: 'POST',
        body: queryArg.updateDiscountValuesRequest
      })
    }),
    postApiDiscountsMixMatchAssociate: build.mutation<
      PostApiDiscountsMixMatchAssociateApiResponse,
      PostApiDiscountsMixMatchAssociateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/mix-match/associate`,
        method: 'POST',
        body: queryArg.associateMixMatchGroupRequest
      })
    }),
    postApiDiscountsMixMatchByDealHdrSeqIdClear: build.mutation<
      PostApiDiscountsMixMatchByDealHdrSeqIdClearApiResponse,
      PostApiDiscountsMixMatchByDealHdrSeqIdClearApiArg
    >({
      query: (queryArg) => ({ url: `/api/discounts/mix-match/${queryArg.dealHdrSeqId}/clear`, method: 'POST' })
    }),
    postApiDiscountsMixMatchClear: build.mutation<
      PostApiDiscountsMixMatchClearApiResponse,
      PostApiDiscountsMixMatchClearApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/mix-match/clear`,
        method: 'POST',
        body: queryArg.clearMixMatchGroupRequest
      })
    }),
    postApiDiscountsMixMatchCreate: build.mutation<
      PostApiDiscountsMixMatchCreateApiResponse,
      PostApiDiscountsMixMatchCreateApiArg
    >({
      query: () => ({ url: `/api/discounts/mix-match/create`, method: 'POST' })
    }),
    postApiDiscountsMixMatchByDealHdrSeqIdSet: build.mutation<
      PostApiDiscountsMixMatchByDealHdrSeqIdSetApiResponse,
      PostApiDiscountsMixMatchByDealHdrSeqIdSetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/discounts/mix-match/${queryArg.dealHdrSeqId}/set`,
        method: 'POST',
        params: { mixMatchGrpNbr: queryArg.mixMatchGrpNbr }
      })
    }),
    postApiAdminBodySizeDebug: build.mutation<PostApiAdminBodySizeDebugApiResponse, PostApiAdminBodySizeDebugApiArg>({
      query: () => ({ url: `/api/admin/body-size-debug`, method: 'POST' })
    }),
    postOdataPlansByKeyFlatFrontlinesQuery: build.mutation<
      PostOdataPlansByKeyFlatFrontlinesQueryApiResponse,
      PostOdataPlansByKeyFlatFrontlinesQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/plans(${queryArg.key})/flatFrontlines/$query`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postOdataPlansByKeyFlatDiscountsQuery: build.mutation<
      PostOdataPlansByKeyFlatDiscountsQueryApiResponse,
      PostOdataPlansByKeyFlatDiscountsQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/plans(${queryArg.key})/flatDiscounts/$query`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postOdataPlansByKeyGuidelineExceptionsQuery: build.mutation<
      PostOdataPlansByKeyGuidelineExceptionsQueryApiResponse,
      PostOdataPlansByKeyGuidelineExceptionsQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/plans(${queryArg.key})/guidelineExceptions/$query`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postOdataPriceChangeRequestsByKeyFlatFrontlinesQuery: build.mutation<
      PostOdataPriceChangeRequestsByKeyFlatFrontlinesQueryApiResponse,
      PostOdataPriceChangeRequestsByKeyFlatFrontlinesQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/priceChangeRequests(${queryArg.key})/flatFrontlines/$query`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postOdataPriceChangeRequestsByKeyFlatDiscountsQuery: build.mutation<
      PostOdataPriceChangeRequestsByKeyFlatDiscountsQueryApiResponse,
      PostOdataPriceChangeRequestsByKeyFlatDiscountsQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/priceChangeRequests(${queryArg.key})/flatDiscounts/$query`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postOdataPriceChangeRequestsByKeyGuidelineExceptionsQuery: build.mutation<
      PostOdataPriceChangeRequestsByKeyGuidelineExceptionsQueryApiResponse,
      PostOdataPriceChangeRequestsByKeyGuidelineExceptionsQueryApiArg
    >({
      query: (queryArg) => ({
        url: `/odata/priceChangeRequests(${queryArg.key})/guidelineExceptions/$query`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postOdataWholesalerGroupsQuery: build.mutation<
      PostOdataWholesalerGroupsQueryApiResponse,
      PostOdataWholesalerGroupsQueryApiArg
    >({
      query: (queryArg) => ({ url: `/odata/wholesalerGroups/$query`, method: 'POST', body: queryArg.body })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as priceApi };
export type GetOdataPlansByKeyFlatFrontlinesApiResponse = /** status 200 Success */ FlatFrontlineODataQueryResult;
export type GetOdataPlansByKeyFlatFrontlinesApiArg = {
  /** The planSeqId. */
  key: number;
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
};
export type GetOdataPlansByKeyFlatFrontlinesCountApiResponse = /** status 200 Success */ FlatFrontlineODataQueryResult;
export type GetOdataPlansByKeyFlatFrontlinesCountApiArg = {
  /** The planSeqId. */
  key: number;
};
export type GetOdataPlansByKeyFlatDiscountsApiResponse = /** status 200 Success */ FlatDiscountODataQueryResult;
export type GetOdataPlansByKeyFlatDiscountsApiArg = {
  /** The planSeqId. */
  key: number;
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
  /** Requests related data to expand. */
  $expand?: string[];
};
export type GetOdataPlansByKeyFlatDiscountsCountApiResponse = /** status 200 Success */ FlatDiscountODataQueryResult;
export type GetOdataPlansByKeyFlatDiscountsCountApiArg = {
  /** The planSeqId. */
  key: number;
};
export type GetOdataPlansByKeyGuidelineExceptionsApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionODataQueryResult;
export type GetOdataPlansByKeyGuidelineExceptionsApiArg = {
  /** PlanSeqId */
  key: number;
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
};
export type GetOdataPlansByKeyGuidelineExceptionsCountApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionODataQueryResult;
export type GetOdataPlansByKeyGuidelineExceptionsCountApiArg = {
  /** PlanSeqId */
  key: number;
};
export type GetOdataPriceChangeRequestsByKeyFlatFrontlinesApiResponse =
  /** status 200 Success */ FlatFrontlineODataQueryResult;
export type GetOdataPriceChangeRequestsByKeyFlatFrontlinesApiArg = {
  /** The pcrSeqId. */
  key: number;
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
};
export type GetOdataPriceChangeRequestsByKeyFlatFrontlinesCountApiResponse =
  /** status 200 Success */ FlatFrontlineODataQueryResult;
export type GetOdataPriceChangeRequestsByKeyFlatFrontlinesCountApiArg = {
  /** The pcrSeqId. */
  key: number;
};
export type GetOdataPriceChangeRequestsByKeyFlatDiscountsApiResponse =
  /** status 200 Success */ FlatDiscountODataQueryResult;
export type GetOdataPriceChangeRequestsByKeyFlatDiscountsApiArg = {
  /** The pcrSeqId. */
  key: number;
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
  /** Requests related data to expand. */
  $expand?: string[];
};
export type GetOdataPriceChangeRequestsByKeyFlatDiscountsCountApiResponse =
  /** status 200 Success */ FlatDiscountODataQueryResult;
export type GetOdataPriceChangeRequestsByKeyFlatDiscountsCountApiArg = {
  /** The pcrSeqId. */
  key: number;
};
export type GetOdataPriceChangeRequestsByKeyGuidelineExceptionsApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionODataQueryResult;
export type GetOdataPriceChangeRequestsByKeyGuidelineExceptionsApiArg = {
  /** PcrSeqId */
  key: number;
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
};
export type GetOdataPriceChangeRequestsByKeyGuidelineExceptionsCountApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionODataQueryResult;
export type GetOdataPriceChangeRequestsByKeyGuidelineExceptionsCountApiArg = {
  /** PcrSeqId */
  key: number;
};
export type GetOdataWholesalerGroupsApiResponse = /** status 200 Success */ WholesalerGroupODataQueryResult;
export type GetOdataWholesalerGroupsApiArg = {
  /** Filter a collection of resources that are addressed by a request URL.<br/>
                    For examples, see <a target="_blank" href="https://learn.microsoft.com/en-us/odata/concepts/queryoptions-overview#filter">Microsoft's docs</a>. */
  $filter?: string;
  /** Requests a limited set of properties for each entity or complex type. */
  $select?: string[];
  /** Request a count of the matching resources included with the resources in the response. */
  $count?: boolean;
  /** The number of items in the queried collection to be included in the result. */
  $top?: number;
  /** The number of items in the queried collection that are to be skipped and not included in the result */
  $skip?: number;
  /** Request resources in either ascending order using <code>asc</code> or descending order using <code>desc</code>. The default is ascending order. */
  $orderby?: string[];
  /** Specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.<br/>
                    For more information see <a target="_blank" href="https://devblogs.microsoft.com/odata/aggregation-extensions-in-odata-asp-net-core/#apply">Microsoft's dev blogs</a>.<br/>
                    This operation is currently <a target="_blank" href="https://github.com/OData/AspNetCoreOData/issues/420">not functional</a> with paging and ordering. */
  $apply?: string;
};
export type GetOdataWholesalerGroupsCountApiResponse = /** status 200 Success */ WholesalerGroupODataQueryResult;
export type GetOdataWholesalerGroupsCountApiArg = void;
export type GetApiUserMeApiResponse = /** status 200 Success */ UserIdentityData;
export type GetApiUserMeApiArg = void;
export type GetApiStatesApiResponse = /** status 200 Success */ State[];
export type GetApiStatesApiArg = void;
export type GetApiPlansApiResponse = /** status 200 Success */ NrpVersPaginationResultWithLinks;
export type GetApiPlansApiArg = {
  /** Filter on NRP_VERS_SEQ_ID. */
  planSeqId?: number;
  /** Filter on Plan year. */
  planYear?: number;
  /** Filter on State. */
  stateCode?: string;
  /** Filter on Refresh flag. */
  refreshFlag?: string;
  /** Filter on Status. */
  statusCode?: string;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type PostApiPlansApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansApiArg = {
  createPlanRequest: CreatePlanRequest;
};
export type GetApiPlansByPlanSeqIdApiResponse = /** status 200 Success */ NrpVers;
export type GetApiPlansByPlanSeqIdApiArg = {
  planSeqId: number;
};
export type GetApiPlansByPlanSeqIdUserApiResponse = /** status 200 Success */ NrpUser;
export type GetApiPlansByPlanSeqIdUserApiArg = {
  planSeqId: number;
};
export type GetApiPlansViewApiResponse = /** status 200 Success */ ViewPlansPaginationResultWithLinks;
export type GetApiPlansViewApiArg = {
  planId?: number;
  planYear?: number;
  stateCode?: string;
  regionCode?: string;
  purged?: boolean;
  approved?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPlansByPlanSeqIdGuidelinesApiResponse =
  /** status 200 Success */ GdlineExcpLogPaginationResultWithLinks;
export type GetApiPlansByPlanSeqIdGuidelinesApiArg = {
  planSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPlansByPlanSeqIdGuidelinesLastRanApiResponse = /** status 200 Success */ GuidelinesLastRanDetails;
export type GetApiPlansByPlanSeqIdGuidelinesLastRanApiArg = {
  planSeqId: number;
};
export type GetApiPlansByPlanSeqIdGuidelinesViewApiResponse =
  /** status 200 Success */ ViewPlanGuidelineExceptionPaginationResultWithLinks;
export type GetApiPlansByPlanSeqIdGuidelinesViewApiArg = {
  planSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPlansByPlanSeqIdFrontlinesUniqueDimensionsApiResponse = /** status 200 Success */ UniqueDimensions;
export type GetApiPlansByPlanSeqIdFrontlinesUniqueDimensionsApiArg = {
  planSeqId: number;
};
export type GetApiPlansByPlanSeqIdDiscountsApiResponse = /** status 200 Success */ DealHdrPaginationResultWithLinks;
export type GetApiPlansByPlanSeqIdDiscountsApiArg = {
  planSeqId: number;
  includeChildren?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type PostApiPlansByPlanSeqIdDiscountsApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdDiscountsApiArg = {
  planSeqId: number;
  createDiscountRequest: CreateDiscountRequest;
};
export type GetApiPlansByPlanSeqIdDiscountsUniqueDimensionsApiResponse = /** status 200 Success */ UniqueDimensions;
export type GetApiPlansByPlanSeqIdDiscountsUniqueDimensionsApiArg = {
  planSeqId: number;
  includeSubGeographies?: boolean;
};
export type GetApiPlansByPlanSeqIdDiscountsViewApiResponse =
  /** status 200 Success */ DiscountPaginationResultWithLinks;
export type GetApiPlansByPlanSeqIdDiscountsViewApiArg = {
  planSeqId: number;
  includeChildren?: boolean;
  includeInactive?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPlansDetailsViewApiResponse = /** status 200 Success */ ViewPlanDetailsPaginationResultWithLinks;
export type GetApiPlansDetailsViewApiArg = {
  dealHdrSeqId?: number;
  pcrSeqId?: number;
  prcChgHdrSeqId?: number;
  nrpVersSeqId?: number;
  dealDtlSeqId?: number;
  varSpltsSeqId?: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsApiResponse = /** status 200 Success */ PrcChgReqstPaginationResultWithLinks;
export type GetApiPcrsApiArg = {
  pcrSeqId?: number;
  statCd?: string;
  yearSubmitted?: number;
  pcrTyp?: string;
  stateCd?: string;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type PostApiPcrsApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsApiArg = {
  createPcrRequest: CreatePcrRequest;
};
export type GetApiPcrsByPcrSeqIdApiResponse = /** status 200 Success */ PrcChgReqst;
export type GetApiPcrsByPcrSeqIdApiArg = {
  pcrSeqId: number;
};
export type GetApiPcrsViewApiResponse = /** status 200 Success */ ViewPcrPaginationResultWithLinks;
export type GetApiPcrsViewApiArg = {
  /** Can limit to a single RevenueManagement.Models.ViewPCR.PCR_SEQ_ID. */
  pcrSeqId?: number;
  /** Defaults to `Pending` and `Ready for Approval` for RevenueManagement.Models.ViewPCR.Status. Can also limit to `Approved` or `Rejected`. */
  status?: PcrStatusFilter;
  /** The inclusive start for the RevenueManagement.Models.ViewPCR.Modify_Date. */
  startModifiedDate?: string;
  /** The inclusive end for the RevenueManagement.Models.ViewPCR.Modify_Date. */
  endModifiedDate?: string;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsStagingByIdApiResponse = /** status 200 Success */ StagingDiscountData;
export type GetApiPcrsStagingByIdApiArg = {
  id: number;
  includeAllRows?: boolean;
};
export type GetApiPcrsStagingByIdFrontlinesApiResponse = /** status 200 Success */ StagingFrontlineData;
export type GetApiPcrsStagingByIdFrontlinesApiArg = {
  id: number;
  includeAllRows?: boolean;
};
export type GetApiPcrsStagingByIdDiscountsApiResponse = /** status 200 Success */ StagingDiscountData;
export type GetApiPcrsStagingByIdDiscountsApiArg = {
  id: number;
  includeAllRows?: boolean;
};
export type GetApiPcrsByPcrSeqIdGuidelinesApiResponse =
  /** status 200 Success */ GdlineExcpLogPaginationResultWithLinks;
export type GetApiPcrsByPcrSeqIdGuidelinesApiArg = {
  pcrSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsByPcrSeqIdGuidelinesLastRanApiResponse = /** status 200 Success */ GuidelinesLastRanDetails;
export type GetApiPcrsByPcrSeqIdGuidelinesLastRanApiArg = {
  pcrSeqId: number;
};
export type GetApiPcrsByPcrSeqIdGuidelinesViewApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionPaginationResultWithLinks;
export type GetApiPcrsByPcrSeqIdGuidelinesViewApiArg = {
  pcrSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsByPcrSeqIdFrontlinesFlatNoFilterApiResponse =
  /** status 200 Success */ FlatFrontlinePaginationResultWithLinks;
export type GetApiPcrsByPcrSeqIdFrontlinesFlatNoFilterApiArg = {
  pcrSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsByPcrSeqIdFrontlinesUniqueDimensionsApiResponse = /** status 200 Success */ UniqueDimensions;
export type GetApiPcrsByPcrSeqIdFrontlinesUniqueDimensionsApiArg = {
  pcrSeqId: number;
};
export type GetApiPcrsByPcrSeqIdDiscountsApiResponse = /** status 200 Success */ DealHdrPaginationResultWithLinks;
export type GetApiPcrsByPcrSeqIdDiscountsApiArg = {
  pcrSeqId: number;
  includeChildren?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type PostApiPcrsByPcrSeqIdDiscountsApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdDiscountsApiArg = {
  pcrSeqId: number;
  createDiscountRequest: CreateDiscountRequest;
};
export type GetApiPcrsByPcrSeqIdDiscountsUniqueDimensionsApiResponse = /** status 200 Success */ UniqueDimensions;
export type GetApiPcrsByPcrSeqIdDiscountsUniqueDimensionsApiArg = {
  pcrSeqId: number;
  includeSubGeographies?: boolean;
};
export type GetApiPcrsByPcrSeqIdDiscountsViewApiResponse = /** status 200 Success */ DiscountPaginationResultWithLinks;
export type GetApiPcrsByPcrSeqIdDiscountsViewApiArg = {
  pcrSeqId: number;
  includeChildren?: boolean;
  includeInactive?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsApiResponse =
  /** status 200 Success */ DiscountPaginationResultWithLinks;
export type GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsApiArg = {
  pcrSeqId: number;
  includeChildren?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsStatusApiResponse =
  /** status 200 Success */ ViewConcurrencyViolationStatus;
export type GetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsStatusApiArg = {
  pcrSeqId: number;
};
export type GetApiPackagesSelectionsApiResponse = /** status 200 Success */ PackageSelectionPaginationResultWithLinks;
export type GetApiPackagesSelectionsApiArg = {
  /** Filter by PKG_GRP_SEQ_ID. */
  pkgGrpSeqId?: number;
  /** Filter by description (Case-insensitive, Reserved characters: '_', '%'). */
  description?: string;
  /** Filter by draft / package. */
  draftOption?: DraftOpt;
  /** Include inactive packages in results. */
  includeInactive?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiLiquidtypesApiResponse = /** status 200 Success */ LiqTyp[];
export type GetApiLiquidtypesApiArg = void;
export type GetApiLiquidtypesByLiquidTypeMappingsApiResponse =
  /** status 200 Success */ LiqTypMappingPaginationResultWithLinks;
export type GetApiLiquidtypesByLiquidTypeMappingsApiArg = {
  /** The liquid type. */
  liquidType: string;
  /** Filter by state code. */
  stateCode?: string;
  /** Filter by BRAND_SEQ_ID. */
  brandSeqId?: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiLegendPcrsApiResponse =
  /** status 200 Success */ LegendPriceChangeRequestSummaryPaginationResultWithLinks;
export type GetApiLegendPcrsApiArg = {
  id?: string;
  pcrTypes?: RecordTypes;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiGuidelinesByExcpSeqIdSimilarApiResponse =
  /** status 200 Success */ GdlineExcpLogPaginationResultWithLinks;
export type GetApiGuidelinesByExcpSeqIdSimilarApiArg = {
  excpSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiGeographiesApiResponse = /** status 200 Success */ WslrCustGrpPaginationResultWithLinks;
export type GetApiGeographiesApiArg = {
  wslrCustGroupSeqId?: number;
  wslrCustGroupTypeCd?: string;
  wslr?: string;
  stateCode?: string;
  statusCode?: string;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiGeographiesViewApiResponse = /** status 200 Success */ ViewGeographiesPaginationResultWithLinks;
export type GetApiGeographiesViewApiArg = {
  wslrCustGroupSeqId?: number;
  wslr?: string;
  stateCode?: string;
  statusCode?: string;
  wslrCustGroupTypeCd?: string;
  includeBreweryAccounts?: boolean;
  includeSalesForecastingAccounts?: boolean;
  includeMerchandisingAccounts?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiGeographiesWholesalersSelectionsApiResponse =
  /** status 200 Success */ WholesalerSelectionPaginationResultWithLinks;
export type GetApiGeographiesWholesalersSelectionsApiArg = {
  /** Filter by Sub-Wholesaler level. */
  subWholesalerLevel?: SubWholesalerLevel;
  /** Filter by WslrCustGrpSeqId. */
  wslrCustGrpSeqId?: number;
  /** Filter by wholesaler number. */
  wslrNumber?: string;
  /** Filter by state. */
  stateCode?: string;
  /** Filter by region. */
  regionCode?: string;
  /** Include inactive wholesalers in results. */
  includeInactive?: boolean;
  /** Include non-equity wholesalers in results. */
  includeNonEquity?: boolean;
  /** Exclude specialty (ES, CW, SC, BG, EP, JP, ML) wholesalers in results. */
  excludeSpecialty?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiGeographiesWholesalersSelectionsGroupsByWholesalerGroupIdMembersApiResponse =
  /** status 200 Success */ WholesalerSelection[];
export type GetApiGeographiesWholesalersSelectionsGroupsByWholesalerGroupIdMembersApiArg = {
  wholesalerGroupId: number;
};
export type GetApiFrontlinesApiResponse = /** status 200 Success */ FrontlinePaginationResultWithLinks;
export type GetApiFrontlinesApiArg = {
  /** The frontline primary key, which in the DB is PRC_CHG_HDR_SEQ_ID. */
  frontlineId?: number;
  /** A plan number, which in the DB is NRP_VERS_SEQ_ID. */
  planSeqId?: number;
  /** A pcr number, which in the DB is PCR_SEQ_ID. */
  pcrSeqId?: number;
  /** The Master Deal number, which in the DB is RM_DEAL_HDR.MASTER_DEAL */
  masterDealSeqId?: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiFrontlinesByFrontlineIdApiResponse = /** status 200 Success */ Frontline;
export type GetApiFrontlinesByFrontlineIdApiArg = {
  frontlineId: number;
};
export type GetApiFeaturesByNameEnabledApiResponse = /** status 200 Success */ boolean;
export type GetApiFeaturesByNameEnabledApiArg = {
  name: FeatureFlag;
};
export type GetApiFeaturesApiResponse = /** status 200 Success */ Feature[];
export type GetApiFeaturesApiArg = void;
export type GetApiDiscountsByDealHdrSeqIdAccountDetailsApiResponse = /** status 200 Success */ WslrCustGrp[];
export type GetApiDiscountsByDealHdrSeqIdAccountDetailsApiArg = {
  dealHdrSeqId: number;
};
export type GetApiDiscountsByDealHdrSeqIdViewApiResponse = /** status 200 Success */ Discount;
export type GetApiDiscountsByDealHdrSeqIdViewApiArg = {
  dealHdrSeqId: number;
};
export type GetApiBrandsApiResponse = /** status 200 Success */ BrandVersPaginationResultWithLinks;
export type GetApiBrandsApiArg = {
  brandSeqId?: number;
  statCd?: string;
  brandName?: string;
  brandCode?: string;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiBrandsByBrandSeqIdApiResponse = /** status 200 Success */ BrandVers;
export type GetApiBrandsByBrandSeqIdApiArg = {
  brandSeqId: number;
};
export type GetApiBrandsSelectionsApiResponse = /** status 200 Success */ BrandSelectionPaginationResultWithLinks;
export type GetApiBrandsSelectionsApiArg = {
  /** Filter by BRAND_SEQ_ID. */
  brandSeqId?: number;
  /** Filter by name (Case-insensitive, Reserved characters: '_', '%'). */
  name?: string;
  /** Filter by brand type. */
  brandType?: BrandType;
  /** Filter by variety pack flag. */
  varietyPack?: boolean;
  /** Include inactive brands in results. */
  includeInactive?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiBrandsGroupsApiResponse = /** status 200 Success */ BrandGrpPaginationResultWithLinks;
export type GetApiBrandsGroupsApiArg = {
  /** Filter by BRAND_GRP_ID. */
  brandGrpId?: number;
  /** Filter by brand group type. */
  brandGroupType?: BrandGroupType;
  /** Include inactive brand groups in results. */
  includeInactive?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiBrandsGroupsByBrandGrpIdApiResponse = /** status 200 Success */ BrandGrp;
export type GetApiBrandsGroupsByBrandGrpIdApiArg = {
  brandGrpId: number;
};
export type GetApiBrandsGroupsByBrandGrpIdMembersApiResponse =
  /** status 200 Success */ BrandMbrPaginationResultWithLinks;
export type GetApiBrandsGroupsByBrandGrpIdMembersApiArg = {
  /** BRAND_GRP_ID of the brand group. */
  brandGrpId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiBatchApiResponse = /** status 200 Success */ BatchLogPaginationResultWithLinks;
export type GetApiBatchApiArg = {
  /** Filter by BATCH_JOB_ID. */
  batchJobId?: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiBatchByBatchJobIdApiResponse = /** status 200 Success */ BatchLog;
export type GetApiBatchByBatchJobIdApiArg = {
  batchJobId: number;
};
export type GetApiAlcoholsApiResponse = /** status 200 Success */ AlchlStrength[];
export type GetApiAlcoholsApiArg = void;
export type GetApiHealthApiResponse = /** status 200 Success */ undefined;
export type GetApiHealthApiArg = void;
export type GetApiAdminNowApiResponse = /** status 200 Success */ ResponseObject;
export type GetApiAdminNowApiArg = void;
export type GetApiAdminServerErrorApiResponse = /** status 200 Success */ undefined;
export type GetApiAdminServerErrorApiArg = void;
export type GetApiActivitiesApiResponse = /** status 200 Success */ ActivityPaginationResultWithLinks;
export type GetApiActivitiesApiArg = {
  /** Find a specific activity record. */
  activityId?: string;
  /** The identifier for the user.  This is normally the user's email. Use @me to filter to the current user. */
  userId?: string;
  /** The inclusive start for the date range to include messages by RevenueManagement.Messaging.Models.Activity.CreatedOn. */
  start?: string;
  /** The inclusive end for the date range to include messages by RevenueManagement.Messaging.Models.Activity.CreatedOn. */
  end?: string;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
};
export type GetApiActivitiesByActivityIdApiResponse = /** status 200 Success */ Activity;
export type GetApiActivitiesByActivityIdApiArg = {
  activityId: string;
};
export type GetOdataMetadataApiResponse = /** status 200 Success */ IEdmModel;
export type GetOdataMetadataApiArg = void;
export type GetOdataApiResponse = /** status 200 Success */ ODataServiceDocument;
export type GetOdataApiArg = void;
export type PostApiPlansByPlanSeqIdApproveApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdApproveApiArg = {
  planSeqId: number;
  setPlanAsCurrent?: boolean;
};
export type PostApiPlansByPlanSeqIdCalendarRolloverApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdCalendarRolloverApiArg = {
  /** NRP_VERS_SEQ_ID of the Plan to perform calendar rollover on. */
  planSeqId: number;
  calendarRolloverSelections: CalendarRolloverSelections;
};
export type PostApiPlansByPlanSeqIdCalendarRolloverDebugApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdCalendarRolloverDebugApiArg = {
  /** NRP_VERS_SEQ_ID of the Plan to perform calendar rollover on. */
  planSeqId: number;
  calendarRolloverSelectionsDebug: CalendarRolloverSelectionsDebug;
};
export type PostApiPlansByPlanSeqIdCheckInApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdCheckInApiArg = {
  planSeqId: number;
  /** If `true`, will check-in the record regardless of user who checked-out.
    If `false`, the check-in user must match the check-out user. */
  overrideUserCheck?: boolean;
};
export type PostApiPlansByPlanSeqIdCheckOutApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdCheckOutApiArg = {
  planSeqId: number;
};
export type PostApiPlansByPlanSeqIdDeleteApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdDeleteApiArg = {
  planSeqId: number;
};
export type PostApiPlansByPlanSeqIdRefreshApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdRefreshApiArg = {
  planSeqId: number;
  runAsBatch?: boolean;
};
export type PostApiPlansByPlanSeqIdSetCurrentApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdSetCurrentApiArg = {
  planSeqId: number;
};
export type PostApiPlansByPlanSeqIdSetDescriptionApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdSetDescriptionApiArg = {
  planSeqId: number;
  descriptionEntry: DescriptionEntry;
};
export type PostApiPlansByPlanSeqIdUpdateNoteApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdUpdateNoteApiArg = {
  planSeqId: number;
  updateNoteRequestBody: UpdateNoteRequestBody;
};
export type PostApiPlansByPlanSeqIdGuidelinesOverrideApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdGuidelinesOverrideApiArg = {
  planSeqId: number;
  overrideAllGuidelinesRequestBody: OverrideAllGuidelinesRequestBody;
};
export type PostApiPlansByPlanSeqIdGuidelinesRunApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdGuidelinesRunApiArg = {
  planSeqId: number;
};
export type PostApiPlansByPlanSeqIdFrontlinesChangeEffectiveDateApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlinesChangeEffectiveDateApiArg = {
  planSeqId: number;
  changeEffectiveDateRequestBody: ChangeEffectiveDateRequestBody;
};
export type PostApiPlansByPlanSeqIdFrontlineMassCopyApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlineMassCopyApiArg = {
  planSeqId: number;
  planFrontlineMassCopyRequestBody: FrontlineMassCopyRequestBody;
};
export type PostApiPlansByPlanSeqIdFrontlinesParityWithExistingApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlinesParityWithExistingApiArg = {
  planSeqId: number;
  parityWithExistingRequestBody: ParityWithExistingRequestBody;
};
export type PostApiPlansByPlanSeqIdFrontlinesPtrIncrementApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlinesPtrIncrementApiArg = {
  planSeqId: number;
  ptrIncrementRequestBody: PtrIncrementRequestBody;
};
export type PostApiPlansByPlanSeqIdFrontlinesUpdateApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlinesUpdateApiArg = {
  planSeqId: number;
  body: UpdateRequestBody[];
};
export type PostApiPlansByPlanSeqIdFrontlinesUpdateBatchApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlinesUpdateBatchApiArg = {
  planSeqId: number;
  updateBatchRequestBody: UpdateBatchRequestBody;
};
export type PostApiPlansByPlanSeqIdFrontlinesAndFrontlineIdUpdateApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdFrontlinesAndFrontlineIdUpdateApiArg = {
  planSeqId: number;
  frontlineId: number;
  updateByIdRequestBody: UpdateByIdRequestBody;
};
export type PostApiPlansByPlanSeqIdFrontlinesViewApiResponse =
  /** status 200 Success */ ViewFrontlinePaginationResultWithLinks;
export type PostApiPlansByPlanSeqIdFrontlinesViewApiArg = {
  planSeqId: number;
  includeChildren?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
  dimensionSelections: DimensionSelections;
};
export type PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdCancelApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdCancelApiArg = {
  /** NRP_VERS_SEQ_ID of the plan containing the discount. */
  planSeqId: number;
  /** DEAL_HDR_SEQ_ID of the discount to be cancelled. */
  dealHdrSeqId: number;
};
export type PostApiPlansByPlanSeqIdDiscountsMassCopyApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdDiscountsMassCopyApiArg = {
  planSeqId: number;
  discountMassCopyOptions: DiscountMassCopyOptions;
};
export type PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdRemoveApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdRemoveApiArg = {
  planSeqId: number;
  dealHdrSeqId: number;
  deleteRelated?: boolean;
  deleteFrontlineDeals?: boolean;
};
export type PostApiPlansByPlanSeqIdRmDiscountsApiResponse = /** status 201 Created */ Activity;
export type PostApiPlansByPlanSeqIdRmDiscountsApiArg = {
  planSeqId: number;
  rmCreateDiscountRequest: RmCreateDiscountRequest;
};
export type PostApiPcrsByPcrSeqIdApproveApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdApproveApiArg = {
  pcrSeqId: number;
};
export type PostApiPcrsByPcrSeqIdCascadeDiscountsApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdCascadeDiscountsApiArg = {
  pcrSeqId: number;
};
export type PostApiPcrsCascadeDiscountsApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsCascadeDiscountsApiArg = {
  cascadeDiscountsBatchRequest: CascadeDiscountsBatchRequest;
};
export type PostApiPcrsByPcrSeqIdCheckInApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdCheckInApiArg = {
  pcrSeqId: number;
  /** If `true`, will check-in the record regardless of user who checked-out.
    If `false`, the check-in user must match the check-out user. */
  overrideUserCheck?: boolean;
};
export type PostApiPcrsByPcrSeqIdCheckOutApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdCheckOutApiArg = {
  /** Id of the Price Change Request */
  pcrSeqId: number;
};
export type PostApiPcrsCreateHeaderApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsCreateHeaderApiArg = {
  createPcrHeaderRequest: CreatePcrHeaderRequest;
};
export type PostApiPcrsByPcrSeqIdRefreshApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdRefreshApiArg = {
  pcrSeqId: number;
};
export type PostApiPcrsByPcrSeqIdRejectApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdRejectApiArg = {
  pcrSeqId: number;
};
export type PostApiPcrsByPcrSeqIdSetDescriptionApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdSetDescriptionApiArg = {
  pcrSeqId: number;
  descriptionEntry: DescriptionEntry;
};
export type PostApiPcrsByPcrSeqIdUpdateNoteApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdUpdateNoteApiArg = {
  pcrSeqId: number;
  updateNoteRequestBody: UpdateNoteRequestBody;
};
export type PostApiPcrsStagingLegendByLegendPcrIdApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsStagingLegendByLegendPcrIdApiArg = {
  legendPcrId: string;
  pcrTypes?: RecordTypes;
  effectiveDate?: string;
};
export type PostApiPcrsStagingPricingFormApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsStagingPricingFormApiArg = {
  pcrTypes?: RecordTypes;
  proposedEffectiveDate?: string;
  body: {
    file: Blob;
  };
};
export type PostApiPcrsStagingByIdSubmitApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsStagingByIdSubmitApiArg = {
  id: number;
  /** Optionally process a staging record into an existing PCR. */
  pcrSeqId?: number;
};
export type PostApiPcrsByPcrSeqIdGuidelinesOverrideApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdGuidelinesOverrideApiArg = {
  pcrSeqId: number;
  overrideAllGuidelinesRequestBody: OverrideAllGuidelinesRequestBody;
};
export type PostApiPcrsByPcrSeqIdGuidelinesRunApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdGuidelinesRunApiArg = {
  pcrSeqId: number;
};
export type PostApiPcrsByPcrSeqIdFrontlinesChangeEffectiveDateApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesChangeEffectiveDateApiArg = {
  pcrSeqId: number;
  changeEffectiveDateRequestBody: ChangeEffectiveDateRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesFlatFilterApiResponse =
  /** status 200 Success */ FlatFrontlinePaginationResultWithLinks;
export type PostApiPcrsByPcrSeqIdFrontlinesFlatFilterApiArg = {
  pcrSeqId: number;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
  uniqueDimensions: UniqueDimensions;
};
export type PostApiPcrsByPcrSeqIdFrontlineMassCopyApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlineMassCopyApiArg = {
  pcrSeqId: number;
  pcrFrontlineMassCopyRequestBody: FrontlineMassCopyRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesParityWithExistingApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesParityWithExistingApiArg = {
  pcrSeqId: number;
  parityWithExistingRequestBody: ParityWithExistingRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesPtrIncrementApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesPtrIncrementApiArg = {
  pcrSeqId: number;
  ptrIncrementRequestBody: PtrIncrementRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdRemoveApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdRemoveApiArg = {
  pcrSeqId: number;
  frontlineId: number;
  deleteRelated?: boolean;
  deleteFrontlineDeals?: boolean;
};
export type PostApiPcrsByPcrSeqIdFrontlinesSearchAndAddApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesSearchAndAddApiArg = {
  pcrSeqId: number;
  searchAndAddRequestBody: SearchAndAddRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesUpdateApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesUpdateApiArg = {
  pcrSeqId: number;
  body: UpdateRequestBody[];
};
export type PostApiPcrsByPcrSeqIdFrontlinesUpdateBatchApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesUpdateBatchApiArg = {
  pcrSeqId: number;
  updateBatchRequestBody: UpdateBatchRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdUpdateApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdUpdateApiArg = {
  pcrSeqId: number;
  frontlineId: number;
  updateByIdRequestBody: UpdateByIdRequestBody;
};
export type PostApiPcrsByPcrSeqIdFrontlinesViewApiResponse =
  /** status 200 Success */ ViewFrontlinePaginationResultWithLinks;
export type PostApiPcrsByPcrSeqIdFrontlinesViewApiArg = {
  pcrSeqId: number;
  includeChildren?: boolean;
  /** The limited number or results to return. */
  limit?: number;
  /** The number of records to skip before returning results. */
  offset?: number;
  dimensionSelections: DimensionSelections;
};
export type PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdCancelApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdCancelApiArg = {
  /** NRP_VERS_SEQ_ID of the plan containing the discount. */
  pcrSeqId: number;
  /** DEAL_HDR_SEQ_ID of the discount to be cancelled. */
  dealHdrSeqId: number;
};
export type PostApiPcrsByPcrSeqIdDiscountsMassCopyApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdDiscountsMassCopyApiArg = {
  pcrSeqId: number;
  discountMassCopyOptions: DiscountMassCopyOptions;
};
export type PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdRemoveApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdRemoveApiArg = {
  pcrSeqId: number;
  dealHdrSeqId: number;
  deleteRelated?: boolean;
  deleteFrontlineDeals?: boolean;
};
export type PostApiPcrsByPcrSeqIdRmDiscountsApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdRmDiscountsApiArg = {
  pcrSeqId: number;
  rmCreateDiscountRequest: RmCreateDiscountRequest;
};
export type PostApiPcrsByPcrSeqIdDiscountsSearchAndAddApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdDiscountsSearchAndAddApiArg = {
  pcrSeqId: number;
  searchAndAddOptions: SearchAndAddOptions;
};
export type PostApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsAddApiResponse = /** status 201 Created */ Activity;
export type PostApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsAddApiArg = {
  pcrSeqId: number;
};
export type PostApiGuidelinesByExcpSeqIdOverrideApiResponse = /** status 201 Created */ Activity;
export type PostApiGuidelinesByExcpSeqIdOverrideApiArg = {
  excpSeqId: number;
  comment: Comment;
};
export type PostApiGuidelinesOverrideApiResponse = /** status 201 Created */ Activity;
export type PostApiGuidelinesOverrideApiArg = {
  overrideGuidelinesRequest: OverrideGuidelinesRequest;
};
export type PostApiFrontlinesFobDeleteApiResponse = /** status 201 Created */ Activity;
export type PostApiFrontlinesFobDeleteApiArg = {
  fobDeleteRequest: FobDeleteRequest;
};
export type PostApiFrontlinesRemoveApiResponse = /** status 201 Created */ Activity;
export type PostApiFrontlinesRemoveApiArg = {
  removeFrontlinesRequest: RemoveFrontlinesRequest;
};
export type PostApiFrontlinesUpdateBatchApiResponse = /** status 201 Created */ Activity;
export type PostApiFrontlinesUpdateBatchApiArg = {
  updateBatchRequest: UpdateBatchRequest;
};
export type PostApiDiscountsByDealHdrSeqIdUpdateApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsByDealHdrSeqIdUpdateApiArg = {
  dealHdrSeqId: number;
  discount: Discount;
};
export type PostApiDiscountsUpdateApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsUpdateApiArg = {
  updateBatchDiscountRequest: UpdateBatchDiscountRequest;
};
export type PostApiDiscountsUpdateHeadersApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsUpdateHeadersApiArg = {
  updateDiscountHeadersRequest: UpdateDiscountHeadersRequest;
};
export type PostApiDiscountsUpdateLevelsApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsUpdateLevelsApiArg = {
  updateDiscountLevelsRequest: UpdateDiscountLevelsRequest;
};
export type PostApiDiscountsUpdateTiersApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsUpdateTiersApiArg = {
  updateDiscountTiersRequest: UpdateDiscountTiersRequest;
};
export type PostApiDiscountsUpdateTierValuesApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsUpdateTierValuesApiArg = {
  updateDiscountTierValuesRequest: UpdateDiscountTierValuesRequest;
};
export type PostApiDiscountsUpdateValuesApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsUpdateValuesApiArg = {
  updateDiscountValuesRequest: UpdateDiscountValuesRequest;
};
export type PostApiDiscountsMixMatchAssociateApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsMixMatchAssociateApiArg = {
  associateMixMatchGroupRequest: AssociateMixMatchGroupRequest;
};
export type PostApiDiscountsMixMatchByDealHdrSeqIdClearApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsMixMatchByDealHdrSeqIdClearApiArg = {
  dealHdrSeqId: number;
};
export type PostApiDiscountsMixMatchClearApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsMixMatchClearApiArg = {
  clearMixMatchGroupRequest: ClearMixMatchGroupRequest;
};
export type PostApiDiscountsMixMatchCreateApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsMixMatchCreateApiArg = void;
export type PostApiDiscountsMixMatchByDealHdrSeqIdSetApiResponse = /** status 201 Created */ Activity;
export type PostApiDiscountsMixMatchByDealHdrSeqIdSetApiArg = {
  dealHdrSeqId: number;
  mixMatchGrpNbr: number;
};
export type PostApiAdminBodySizeDebugApiResponse = /** status 200 Success */ string;
export type PostApiAdminBodySizeDebugApiArg = void;
export type PostOdataPlansByKeyFlatFrontlinesQueryApiResponse = /** status 200 Success */ FlatFrontlineODataQueryResult;
export type PostOdataPlansByKeyFlatFrontlinesQueryApiArg = {
  /** The planSeqId. */
  key: number;
  body: string;
};
export type PostOdataPlansByKeyFlatDiscountsQueryApiResponse = /** status 200 Success */ FlatDiscountODataQueryResult;
export type PostOdataPlansByKeyFlatDiscountsQueryApiArg = {
  /** The planSeqId. */
  key: number;
  body: string;
};
export type PostOdataPlansByKeyGuidelineExceptionsQueryApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionODataQueryResult;
export type PostOdataPlansByKeyGuidelineExceptionsQueryApiArg = {
  /** PlanSeqId */
  key: number;
  body: string;
};
export type PostOdataPriceChangeRequestsByKeyFlatFrontlinesQueryApiResponse =
  /** status 200 Success */ FlatFrontlineODataQueryResult;
export type PostOdataPriceChangeRequestsByKeyFlatFrontlinesQueryApiArg = {
  /** The pcrSeqId. */
  key: number;
  body: string;
};
export type PostOdataPriceChangeRequestsByKeyFlatDiscountsQueryApiResponse =
  /** status 200 Success */ FlatDiscountODataQueryResult;
export type PostOdataPriceChangeRequestsByKeyFlatDiscountsQueryApiArg = {
  /** The pcrSeqId. */
  key: number;
  body: string;
};
export type PostOdataPriceChangeRequestsByKeyGuidelineExceptionsQueryApiResponse =
  /** status 200 Success */ ViewGuidelineExceptionODataQueryResult;
export type PostOdataPriceChangeRequestsByKeyGuidelineExceptionsQueryApiArg = {
  /** PcrSeqId */
  key: number;
  body: string;
};
export type PostOdataWholesalerGroupsQueryApiResponse = /** status 200 Success */ WholesalerGroupODataQueryResult;
export type PostOdataWholesalerGroupsQueryApiArg = {
  body: string;
};
export type CivMil = 'Military' | 'Civilian' | 'CivMil';
export type FlatFrontline = {
  frontlineId?: number;
  wslrCustGrpSeqId?: number;
  wslrNumber?: string;
  wslrCityState?: string;
  brandSeqId?: number;
  brandCode?: string;
  brandName?: string;
  brandDisplayName?: string;
  pkgGrpSeqId?: number;
  packageDescription?: string;
  alcoholStrengths?: string;
  beginEffectiveDate?: string | null;
  proposedBeginEffectiveDate?: string | null;
  fob?: number | null;
  proposedFob?: number | null;
  recommendedPtr?: number | null;
  proposedRecommendedPtr?: number | null;
  verifiedPtr?: number | null;
  recommendedPtrIncrease?: number | null;
  civMil?: CivMil;
  alcoholStrength?: string;
  abSplitPtrAmount?: number | null;
  wslrSplitPtrAmount?: number | null;
  freightAmount?: number | null;
  stateTaxAmount?: number | null;
  deliverAmount?: number | null;
};
export type FlatFrontlineODataQueryResult = {
  '@odata.context'?: string | null;
  '@odata.count'?: number | null;
  value?: FlatFrontline[];
  '@odata.nextLink'?: number | null;
};
export type ODataTypeAnnotation = {
  typeName?: string | null;
};
export type ODataErrorDetail = {
  errorCode?: string | null;
  message?: string | null;
  target?: string | null;
};
export type ODataValue = {
  typeAnnotation?: ODataTypeAnnotation;
};
export type ODataInnerError = {
  properties?: {
    [key: string]: ODataValue;
  } | null;
  message?: string | null;
  typeName?: string | null;
  stackTrace?: string | null;
  innerError?: ODataInnerError;
};
export type ODataInstanceAnnotation = {
  typeAnnotation?: ODataTypeAnnotation;
  name?: string | null;
  value?: ODataValue;
};
export type ODataError = {
  typeAnnotation?: ODataTypeAnnotation;
  errorCode?: string | null;
  message?: string | null;
  target?: string | null;
  details?: ODataErrorDetail[] | null;
  innerError?: ODataInnerError;
  instanceAnnotations?: ODataInstanceAnnotation[] | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type SubWholesalerLevel =
  | 'GeneralMarket'
  | 'CountySpecific'
  | 'RPFAttributeGroupSpecific'
  | 'ChainSpecific'
  | 'VenueSpecific';
export type ConsumptionType = 'OnPremise' | 'OffPremise' | 'Combined';
export type DealQdTyp = 'Cumulative' | 'FirstDelivery' | 'PerDrop' | 'NotApplicable';
export type DlDlvryFreqcCd = 'WEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'ANNUALLY';
export type DiscountType = 'FixedSplit' | 'VariableSplit' | 'QDMixAndMatch' | 'QDNonMixAndMatch' | 'Rebate';
export type GeographyWithName = {
  wslrCustGrpSeqId?: number;
  wslrNumber?: string;
  displayName?: string;
};
export type FlatDiscount = {
  discountId?: number;
  wslrCustGrpSeqId?: number | null;
  regionCode?: string | null;
  stateCode?: string | null;
  subwholesalerLevel?: SubWholesalerLevel;
  wslrNumber?: string | null;
  cityState?: string | null;
  brandSeqId?: number | null;
  brandName?: string | null;
  brandDisplayName?: string | null;
  brandStatus?: string | null;
  pkgGrpSeqId?: number | null;
  packageDescription?: string | null;
  packageStatus?: string | null;
  alcoholStrengths?: string | null;
  actionCode?: string | null;
  frontlineId?: number | null;
  alcoholIntent?: string | null;
  statusCode?: string | null;
  rebateAmount?: number | null;
  recommendedFrontlinePtr?: number | null;
  consumptionType?: ConsumptionType;
  civMil?: CivMil;
  beginStrDate?: string | null;
  endStrDate?: string | null;
  beginShipmentDate?: string | null;
  endShipmentDate?: string | null;
  proposedBeginStrDate?: string | null;
  proposedEndStrDate?: string | null;
  proposedBeginShipmentDate?: string | null;
  proposedEndShipmentDate?: string | null;
  quantityDiscountFlag?: boolean;
  qdDelivery?: DealQdTyp;
  qdFrequency?: DlDlvryFreqcCd;
  variableSplitFlag?: boolean;
  freeOnBoardReductionFlag?: boolean;
  alcoholSelectFlag?: boolean;
  mixMatchFlag?: boolean;
  mixMatchGroup?: number | null;
  proposedMixMatchGroup?: number | null;
  description?: string | null;
  discountCreatedOn?: string | null;
  discountModifiedOn?: string | null;
  marketDescription?: string | null;
  packageDisplayOrder?: number | null;
  brandDisplayOrder?: number | null;
  discountDetailId?: number | null;
  qdMinQuantity?: number | null;
  qdMaxQuantity?: number | null;
  currentMaxDiscountAmount?: number | null;
  proposedMaxDiscountAmount?: number | null;
  discountDetailCreatedOn?: string | null;
  discountDetailModifiedOn?: string | null;
  variableSplitId?: number | null;
  currentAbSplitAmount?: number | null;
  proposedAbSplitAmount?: number | null;
  currentWslrSplitAmount?: number | null;
  proposedWslrSplitAmount?: number | null;
  variableSplitFromAmount?: number | null;
  variableSplitToAmount?: number | null;
  variableSplitCreatedOn?: string | null;
  variableSplitModifiedOn?: string | null;
  wslrMargin?: number | null;
  discountType?: DiscountType;
  geoIds?: string;
  geographies?: GeographyWithName[] | null;
};
export type FlatDiscountODataQueryResult = {
  '@odata.context'?: string | null;
  '@odata.count'?: number | null;
  value?: FlatDiscount[];
  '@odata.nextLink'?: number | null;
};
export type ViewGuidelineException = {
  excpSeqId: number;
  planOrPcr: string;
  planOrPcrSeqId: number;
  violation: string;
  isFOB: boolean;
  discOrFOB: string;
  wholesalerAndDescription?: string | null;
  wslrNumber?: string | null;
  discountType?: string | null;
  proposedBeginStrDate?: string | null;
  proposedEndStrDate?: string | null;
  brand?: string | null;
  package?: string | null;
  chkAlchlStrengthCd?: string | null;
  overrideCommentsTxt?: string | null;
  overrideUserNm?: string | null;
};
export type ViewGuidelineExceptionODataQueryResult = {
  '@odata.context'?: string | null;
  '@odata.count'?: number | null;
  value?: ViewGuidelineException[];
  '@odata.nextLink'?: number | null;
};
export type WholesalerGroup = {
  wholesalerGroupId?: number;
  name?: string;
  displayName?: string;
  stateCode?: string;
  memberCount?: number;
  activeMemberCount?: number;
};
export type WholesalerGroupODataQueryResult = {
  '@odata.context'?: string | null;
  '@odata.count'?: number | null;
  value?: WholesalerGroup[];
  '@odata.nextLink'?: number | null;
};
export type User = {
  userSeqId: number;
  userId: string;
  userNm: string;
  begEffDt: string;
  pwdBegDt: string;
  disconnectFlg: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  creationDate: string;
  createdBy: string;
  userTyp?: string | null;
  emailAddrTxt?: string | null;
  faxNbr?: string | null;
  endEffDt?: string | null;
  expLastUpdDt?: string | null;
  sapPersonnelId?: string | null;
  wslrNumber?: string | null;
};
export type Employee = {
  sapPersonnelId: string;
  lastUpdateDate: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  firstNm?: string | null;
  mdlInitialsTxt?: string | null;
  lastNm?: string | null;
  jobObjectCd?: string | null;
  jobDsc?: string | null;
  emplGrpCd?: string | null;
  emplGrpDsc?: string | null;
  companyCd?: string | null;
  companyNm?: string | null;
  persAreaCd?: string | null;
  persAreaDsc?: string | null;
  persSubareaCd?: string | null;
  persSubareaDsc?: string | null;
  orgStructureCd?: string | null;
  workStateCd?: string | null;
  workPhoneNbr?: number | null;
  emailAddrTxt?: string | null;
  logonId?: string | null;
};
export type UserIdentityData = {
  user?: User;
  employee?: Employee;
};
export type State = {
  stateNumCd: string;
  stateCd: string;
  stateName: string;
  oldRegionCd?: string | null;
  regionCd?: string | null;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
};
export type PaginationLinks = {
  first?: string | null;
  last?: string | null;
  next?: string | null;
  prev?: string | null;
};
export type NrpVers = {
  nrpVersSeqId: number;
  volBudgHdrSeqId: number;
  recalcNeededFlg: string;
  dsplyId: number;
  titleTxt: string;
  dscTxt: string;
  firstPrcActnDt: string;
  dealRollupFlg: string;
  purgeFlg: string;
  planYr: string;
  lastUpdateDate: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  refreshFlg?: string | null;
  statCd?: string | null;
  budgFlg?: string | null;
  currFlg?: string | null;
  mstrPlanCandFlg?: string | null;
  absSpendAmt?: number | null;
  newFlg?: string | null;
  nrpVersStat?: string | null;
  stateCd: string;
  netRvnuAmt?: number | null;
  planYrAbSpAmt?: number | null;
  lastYrAbSpAmt?: number | null;
  rvnuImpactAmt?: number | null;
};
export type NrpVersPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: NrpVers[];
};
export type ActivityStatus = 'Unknown' | 'Pending' | 'Running' | 'Completed' | 'Failed' | 'Warning';
export type Activity = {
  activityId?: string;
  userId?: string;
  entityId?: string;
  entityType?: string;
  createdOn?: string;
  updatedOn?: string;
  completedOn?: string | null;
  success?: boolean | null;
  message?: string;
  status?: ActivityStatus;
  steps?: Activity[] | null;
};
export type CreatePlanRequest = {
  state?: string;
  description?: string;
};
export type NrpUser = {
  nrpUserSeqId: number;
  userSeqId: number;
  nrpVersSeqId: number;
  checkOutDt?: string | null;
  checkInDt?: string | null;
  nrpActionCd?: string | null;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
};
export type ViewPlans = {
  nrpVersSeqId: number;
  display: string;
  title: string;
  year: number;
  regionCd: string;
  stateCd: string;
  statusCd?: string | null;
  statusDesc?: string | null;
  approvalCd?: string | null;
  approvalDesc?: string | null;
  discountRollup?: boolean | null;
  purge: boolean;
  refresh?: boolean | null;
  budget?: boolean | null;
  approved?: boolean;
  checkOutBy?: string | null;
  checkOutDate?: string | null;
  prevCheckOutBy?: string | null;
  prevCheckOutDate?: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  description?: string | null;
  note?: string | null;
};
export type ViewPlansPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewPlans[];
};
export type GdlineExcpLog = {
  excpSeqId: number;
  errorCodeId: string;
  planOrPcr: string;
  planOrPcrSeqId: number;
  dealOrFrontlineCd?: string | null;
  violRecHdrSeqId?: number | null;
  chkRecHdrSeqId?: number | null;
  currYrPlanSeqId?: number | null;
  chkBrandSeqId?: number | null;
  chkPkgGrpSeqId?: number | null;
  chkWslrCustGrpSeqId?: number | null;
  chkAlchlStrengthCd?: string | null;
  wslrNumber?: string | null;
  overrideUserId?: string | null;
  overrideCommentsTxt?: string | null;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  processId: number;
  dealDesc?: string | null;
  dealTypCd?: string | null;
};
export type GdlineExcpLogPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: GdlineExcpLog[];
};
export type GuidelinesLastRanDetails = {
  lastRan?: string;
  violationCount?: number;
  openViolationCount?: number;
};
export type ViewPlanGuidelineException = {
  excpSeqId: number;
  planOrPcr: string;
  planOrPcrSeqId: number;
  violation: string;
  isFOB: boolean;
  discOrFOB: string;
  wholesalerAndDescription?: string | null;
  wslrNumber?: string | null;
  discountType?: string | null;
  proposedBeginStrDate?: string | null;
  proposedEndStrDate?: string | null;
  brand?: string | null;
  package?: string | null;
  chkAlchlStrengthCd?: string | null;
  overrideCommentsTxt?: string | null;
  overrideUserNm?: string | null;
  prevOverrideCommentsTxt?: string;
};
export type ViewPlanGuidelineExceptionPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewPlanGuidelineException[];
};
export type Geography = {
  wslrCustGrpSeqId?: number | null;
  wslrNumber: string;
};
export type GeographyStringKeyValuePair = {
  key?: Geography;
  value?: string;
};
export type Int64StringKeyValuePair = {
  key?: number;
  value?: string;
};
export type DraftOpt = 'Draft' | 'Package';
export type Package = {
  pkgGrpSeqId?: number;
  draftOption?: DraftOpt;
};
export type PackageStringKeyValuePair = {
  key?: Package;
  value?: string;
};
export type UniqueDimensions = {
  geographies?: GeographyStringKeyValuePair[];
  brands?: Int64StringKeyValuePair[];
  packages?: PackageStringKeyValuePair[];
  alcoholStrengths?: string[];
  effectiveDates?: string[];
};
export type VarSplts = {
  varSpltsSeqId: number;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  curAbSplitAmt?: number | null;
  dealDtlSeqId: number;
  propAbSplitAmt?: number | null;
  curWslrSplitAmt?: number | null;
  propWslrSplitAmt?: number | null;
  vsFromAmt?: number | null;
  vsToAmt?: number | null;
  cprPtcAmt?: number | null;
  cprPtrAmt?: number | null;
  sprPtcAmt?: number | null;
  pprPtrAmt?: number | null;
};
export type DealDtl = {
  dealDtlSeqId: number;
  dealHdrSeqId: number;
  cpmPromoId?: string | null;
  qdMinQty: number;
  qdMaxQty: number;
  currMaxDiscAmt?: number | null;
  propMaxDiscAmt?: number | null;
  lastUpdateDate: string;
  lastUpdatedBy: string;
  createdBy: string;
  creationDate: string;
  variableSplits?: VarSplts[];
};
export type DealHdr = {
  dealHdrSeqId: number;
  wslrCustGrpSeqId?: number | null;
  brandSeqId: number;
  pkgGrpSeqId: number;
  actionCd?: string | null;
  cuomCd: string;
  pctBus?: number | null;
  masterDeal?: number | null;
  pcrSeqId?: number | null;
  prcChgHdrSeqId?: number | null;
  nrpVersSeqId?: number | null;
  statCd: string;
  begStrDt?: string | null;
  fobrFlg: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  choicePkgDesc?: string | null;
  netRvnuAmt?: number | null;
  endStrDt?: string | null;
  dealSpendingAmt?: number | null;
  milOptCd?: string | null;
  recordTyp?: string | null;
  qdDeliveryCd?: string | null;
  qdFrequencyCd?: string | null;
  prjtdSlsVolQty?: number | null;
  rebateAmt?: number | null;
  statCaseAllowanceAmt?: number | null;
  begShipmentDt?: string | null;
  endShipmentDt?: string | null;
  cmptvBrandSeqId?: number | null;
  wslrNumber: string;
  qtyDiscFlg: string;
  propBegShipmentDt?: string | null;
  propEndShipmentDt?: string | null;
  propBegStrDt?: string | null;
  propEndStrDt?: string | null;
  vflPtrAmt?: number | null;
  vflPtrEffDt?: string | null;
  origVflPtrAmt?: number | null;
  origVflPtrEffDt?: string | null;
  rflPtrAmt?: number | null;
  sflPtcAmt?: number | null;
  choicePkgFlg: string;
  mixMatchFlg: string;
  inOutFlg: string;
  mulliganFlg: string;
  pkgSubstFlg: string;
  pkgExtFlg: string;
  choicePkgGrpNbr?: number | null;
  mixMatchGrpNbr?: number | null;
  alchlSlctFlg: string;
  alchlSpecificityCd?: string | null;
  currSlsVolQty?: number | null;
  cnsmptnTypCd: string;
  uniqValdtnFlg: string;
  wslrCustGrpTypCd: string;
  varSpltFlg: string;
  dealDesc?: string | null;
  currMixMatchGrpNbr?: number | null;
  aprvlDt?: string | null;
  details?: DealDtl[];
};
export type DealHdrPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: DealHdr[];
};
export type DiscountDescriptions =
  | 'None'
  | 'ChoicePackage'
  | 'InOut'
  | 'Mulligan'
  | 'PackageSubstitute'
  | 'PackageExtension'
  | 'NoDescription';
export type DateRange = {
  start: string;
  end: string;
};
export type DbDiscountTypes = 'CaseOne' | 'QDMixAndMatch' | 'QDNonMixAndMatch' | 'Rebate';
export type BuildVariableSplitLevel = {
  allowance?: number;
  discountFrom?: number | null;
  discountTo?: number | null;
};
export type BuildDiscountDetail = {
  discountAmount?: number;
  levels: BuildVariableSplitLevel[];
  qdMinQuantity?: number;
  qdMaxQuantity?: number;
};
export type BuildDiscount = {
  wslrCustGrpSeqId?: number | null;
  wslrNumber?: string;
  discountType?: DbDiscountTypes;
  details: BuildDiscountDetail[];
  description?: string | null;
  qdDelivery?: DealQdTyp;
  qdFrequency?: DlDlvryFreqcCd;
};
export type CreateDiscountRequest = {
  brandSeqIds: number[];
  pkgGrpSeqIds: number[];
  alcoholStrengths: string[];
  subwholesalerLevel?: SubWholesalerLevel;
  consumptionType?: ConsumptionType;
  discountOfferings?: DiscountDescriptions;
  civMil?: CivMil;
  effectiveDates: DateRange[];
  discounts: BuildDiscount[];
};
export type VariableSplitLevel = {
  varSpltsSeqId?: number;
  currentAbSplitAmount?: number | null;
  proposedAbSplitAmount?: number | null;
  currentWslrSplitAmount?: number | null;
  proposedWslrSplitAmount?: number | null;
  variableSplitFromAmount?: number | null;
  variableSplitToAmount?: number | null;
  createdOn?: string;
  modifiedOn?: string;
};
export type DiscountDetail = {
  dealHdrSeqId?: number;
  dealDtlSeqId?: number;
  qdMinQuantity?: number;
  qdMaxQuantity?: number;
  currentMaxDiscountAmount?: number | null;
  proposedMaxDiscountAmount?: number | null;
  variableSplits?: VariableSplitLevel[];
  createdOn?: string;
  modifiedOn?: string;
};
export type Discount = {
  dealHdrSeqId?: number;
  wslrCustGrpSeqId?: number | null;
  regionCode?: string;
  stateCode?: string;
  subwholesalerLevel?: SubWholesalerLevel;
  wslrNumber?: string;
  cityState?: string;
  brandSeqId?: number;
  brandName?: string;
  pkgGrpSeqId?: number;
  packageDescription?: string;
  alcoholStrengths?: string;
  actionCode?: string | null;
  pcrSeqId?: number | null;
  prcChgHdrSeqId?: number | null;
  planSeqId?: number | null;
  alcoholIntent: string;
  statusCode: string;
  rebateAmount?: number | null;
  frontlinePtr?: number;
  recommendedFrontlinePtr?: number;
  laidInCost?: number;
  proposedLaidInCost?: number;
  consumptionType?: ConsumptionType;
  civMil?: CivMil;
  beginStrDate?: string | null;
  endStrDate?: string | null;
  beginShipmentDate?: string | null;
  endShipmentDate?: string | null;
  proposedBeginStrDate?: string | null;
  proposedEndStrDate?: string | null;
  proposedBeginShipmentDate?: string | null;
  proposedEndShipmentDate?: string | null;
  qdDelivery?: DealQdTyp;
  qdFrequency?: DlDlvryFreqcCd;
  freeOnBoardReductionFlag?: boolean;
  alcoholSelectFlag?: boolean;
  mixMatchGroup?: number | null;
  proposedMixMatchGroup?: number | null;
  description?: string | null;
  details?: DiscountDetail[];
  quantityDiscountFlag?: boolean;
  variableSplitFlag?: boolean;
  mixMatchFlag?: boolean;
  discountType?: DiscountType;
  createdOn?: string;
  modifiedOn?: string;
  marketDescription?: string | null;
  packageDisplayOrder?: number | null;
  brandDisplayOrder?: number | null;
};
export type DiscountPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: Discount[];
};
export type ViewPlanDetails = {
  dealHdrSeqId?: number;
  pcrSeqId?: number | null;
  prcChgHdrSeqId?: number | null;
  nrpVersSeqId?: number | null;
  dealDtlSeqId?: number;
  varSpltsSeqId?: number | null;
  wholesalerNumber?: string | null;
  wholesalerName?: string | null;
  wholesalerCity?: string | null;
  wholesalerState?: string | null;
  alcohol?: string | null;
  brandFamily?: string | null;
  brandAcronym?: string | null;
  brand?: string | null;
  civMilOption?: string | null;
  cfFob?: number | null;
  cfRecommendedPtr?: number | null;
  groupCode?: number | null;
  margin?: number | null;
  marketLineCode?: string | null;
  package?: string | null;
  actioncode?: string | null;
  alcoholInterfaceCode?: string | null;
  approvalStatus?: string | null;
  containerDeposit?: number | null;
  costFreightFull?: number | null;
  costRtlrHandlingFee?: number | null;
  costTransfer?: number | null;
  costWslrHandlingFee?: number | null;
  createDate?: string | null;
  createdBy?: string | null;
  cfAbMarginBbl?: number | null;
  cfAbMarginUnit?: number | null;
  cfAbSplitAmount?: number | null;
  cfAbSplitPercent?: number | null;
  cfFobEffectiveDate?: string | null;
  cfFobEndDate?: string | null;
  cfLaidInCost?: number | null;
  cfVerifiedPtr?: number | null;
  cfVerifiedPtrEffectivedate?: string | null;
  cfWholesalerMarginPercent?: number | null;
  cfWholesalerMarkUpAmount?: number | null;
  cfWholesalerSplitAmount?: number | null;
  cfWholesalerSplitPercent?: number | null;
  currentOwner?: string | null;
  cpAbMarginBbl?: number | null;
  cpAbMarginUnit?: number | null;
  cpAbSplitAmount?: number | null;
  cpAbSplitPercent?: number | null;
  cpDiscount?: number | null;
  cpEndDate?: string | null;
  cpPtr?: number | null;
  cpShipmentBeginDate?: string | null;
  cpShipmentEndDate?: string | null;
  cpStartDate?: string | null;
  cpwMarginPercent?: number | null;
  cpwMarkUpAmount?: number | null;
  cpwSplitAmount?: number | null;
  cpwSplitPercent?: number | null;
  discountAlcoholIntent?: string | null;
  discountDescription?: string | null;
  discountFrom?: string | null;
  discountGeographyType?: string | null;
  discountTo?: string | null;
  fmbTax?: number | null;
  fobBrewery?: string | null;
  fobrFlag?: boolean | null;
  modifiedBy?: string | null;
  modifiedCode?: string | null;
  modifiedDate?: string | null;
  newFlag?: boolean | null;
  pcrBeginningEffectiveDate?: string | null;
  pcrEndingEffectiveDate?: string | null;
  pcrRequestType?: string | null;
  planYear?: number | null;
  previousOwner?: string | null;
  pdtCaseOne?: boolean | null;
  pdtCurrentMixMatchGroup?: boolean | null;
  pdtMixandMatch?: boolean | null;
  pdtMixandMatchGroupId?: number | null;
  pdtPackageExtension?: boolean | null;
  pdtPackageSubstitution?: boolean | null;
  pdtQuantityDiscount?: boolean | null;
  pdtRebate?: boolean | null;
  primaryConsumptionType?: string | null;
  pfAbMarginBbl?: number | null;
  pfAbMarginUnit?: number | null;
  pfAbSplitAmount?: number | null;
  pfAbSplitPercent?: number | null;
  pfFob?: number | null;
  pfFobEffectiveDate?: string | null;
  pfFobEndDate?: string | null;
  pfLaidInCost?: number | null;
  pfPtr?: number | null;
  pfPtrIncrement?: number | null;
  pfrMarginPercent?: number | null;
  pfrMarkUpAmount?: number | null;
  pfrMarkUpPercent?: number | null;
  pfSuggestedPtc?: number | null;
  pfwMarginPercent?: number | null;
  pfwMarkUpAmount?: number | null;
  pfWslrSplitAmount?: number | null;
  pfWslrSplitPercent?: number | null;
  ppAbMarginBbl?: number | null;
  ppAbMarginUnit?: number | null;
  ppAbSplitAmount?: number | null;
  ppAbSplitPercent?: number | null;
  ppDiscount?: number | null;
  ppEndDate?: string | null;
  ppPtr?: number | null;
  pprMarginPercent?: number | null;
  pprMarkUpAmount?: number | null;
  pprMarkUpPercent?: number | null;
  ppShipmentBeginDate?: string | null;
  ppShipmentEndDate?: string | null;
  ppStartDate?: string | null;
  ppSuggestedPtc?: number | null;
  ppwMarginPercent?: number | null;
  ppwMarkUpAmount?: number | null;
  ppwSplitAmount?: number | null;
  ppwSplitPercent?: number | null;
  proposedStrVolume?: number | null;
  qdMax?: number | null;
  qdMin?: number | null;
  quantityDiscountDelivery?: number | null;
  quantityDiscountFrequency?: number | null;
  quantityDiscountGroupId?: number | null;
  rebateAmount?: number | null;
  recordStatus?: string | null;
  recordType?: string | null;
  refreshStatus?: string | null;
  shortDescription?: string | null;
  standardUnitCost?: number | null;
  subWholesalers?: string | null;
  taxFederal?: number | null;
  taxLocal?: number | null;
  taxState?: number | null;
  uniqueFlag?: boolean | null;
  stateLiquidType?: string | null;
};
export type ViewPlanDetailsPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewPlanDetails[];
};
export type PrcChgReqst = {
  pcrSeqId: number;
  begEffDt: string;
  statCd: string;
  dsc?: string | null;
  wyrRvnuAmt: number;
  spendingAmt: number;
  prjtdNtProftAmt: number;
  purgeFlg: string;
  yearSubmitted: number;
  sourceTyp?: string | null;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  createdBy: string;
  newFlg: string;
  cmptvDocTyp?: string | null;
  pcrTyp?: string | null;
  endEffDt?: string | null;
  stateCd: string;
  netRvnuAmt?: number | null;
  rvnuImpactAmt?: number | null;
  absSpendAmt?: number | null;
  cascadeFlg: string;
  lastRefreshDt?: string | null;
  npsReqstSeqId?: number | null;
};
export type PrcChgReqstPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: PrcChgReqst[];
};
export type PcrTyp =
  | 'PriceDiscounts'
  | 'NewBrandPackage'
  | 'FOBAdjustment'
  | 'FOBAdjustmentwithPriceDiscounts'
  | 'NewBrandPackagewithPriceDiscounts'
  | 'UpdateShipmentDate';
export type DimensionSelections = {
  geographies?: Geography[] | null;
  brandSeqIds: number[];
  pkgGrpSeqIds: number[];
  alcoholStrengths: string[];
  wslrCustGrpSeqIds?: number[] | null;
  totalSelections?: number;
};
export type WholesalerValues = {
  wslrNumber: string;
  proposedFob?: number | null;
  proposedPtr?: number | null;
};
export type CreatePcrRequest = {
  pcrType?: PcrTyp;
  stateCode: string;
  startDate: string;
  civMil?: CivMil;
  dimensionSelections?: DimensionSelections;
  wholesalerSpecificValues?: WholesalerValues[] | null;
  description?: string | null;
  rationale?: string | null;
  createWithNoDataFound?: boolean;
  createMultiYearPcr?: boolean;
};
export type ViewPcr = {
  pcrSeqId: number;
  pcrNumber: string;
  regionCd: string;
  stateCd: string;
  requestType: string;
  pcrEffectiveDate: string;
  pcrEndEffectiveDate: string;
  npsReqstSeqId?: number | null;
  status: string;
  guidelineStatus?: string;
  cascadeNeeded?: boolean;
  lastRefreshDt?: string | null;
  refreshNeeded?: boolean;
  brand?: string | null;
  package?: string | null;
  alcohol?: string | null;
  geography?: string | null;
  rationale?: string | null;
  note?: string | null;
  description?: string | null;
  civMil?: CivMil;
  isMultiYear?: boolean;
  currentOwner?: string | null;
  previousOwner?: string | null;
  checkOutDt?: string | null;
  modifyDate: string;
};
export type ViewPcrPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewPcr[];
};
export type PcrStatusFilter = 'Pending' | 'ReadyForApproval' | 'Approved' | 'Rejected';
export type ValidationResult = {
  errorMessage?: string | null;
  memberNames?: string[];
};
export type DataSummary = {
  totalRows?: number;
  errorRows?: number;
  successfulRows?: number;
};
export type StagingVariableSplitLevel = {
  varSpltsSeqId?: number | null;
  currentAbSplitAmount?: number | null;
  proposedAbSplitAmount?: number | null;
  currentWslrSplitAmount?: number | null;
  proposedWslrSplitAmount?: number | null;
  variableSplitFromAmount?: number | null;
  variableSplitToAmount?: number | null;
  createdOn?: string;
  modifiedOn?: string;
  hasErrors?: boolean;
  violations?: ValidationResult[];
  id?: number;
};
export type StagingDiscountDetail = {
  dealHdrSeqId?: number | null;
  dealDtlSeqId?: number | null;
  qdMinQuantity?: number;
  qdMaxQuantity?: number;
  currentMaxDiscountAmount?: number | null;
  proposedMaxDiscountAmount?: number | null;
  variableSplits?: StagingVariableSplitLevel[];
  hasErrors?: boolean;
  violations?: ValidationResult[];
  id?: number;
};
export type StagingDiscount = {
  dealHdrSeqId?: number | null;
  wslrCustGrpSeqId?: number | null;
  regionCode?: string;
  stateCode?: string;
  subwholesalerLevel?: SubWholesalerLevel;
  wslrNumber?: string;
  cityState?: string;
  brandSeqId?: number;
  brandName?: string;
  pkgGrpSeqId?: number;
  packageDescription?: string;
  alcoholStrengths?: string;
  actionCode?: string | null;
  pcrSeqId?: number | null;
  prcChgHdrSeqId?: number | null;
  planSeqId?: number | null;
  alcoholIntent: string;
  statusCode: string;
  rebateAmount?: number | null;
  frontlinePtr?: number;
  recommendedFrontlinePtr?: number;
  laidInCost?: number;
  proposedLaidInCost?: number;
  consumptionType?: ConsumptionType;
  civMil?: CivMil;
  beginStrDate?: string | null;
  endStrDate?: string | null;
  beginShipmentDate?: string | null;
  endShipmentDate?: string | null;
  proposedBeginStrDate?: string | null;
  proposedEndStrDate?: string | null;
  proposedBeginShipmentDate?: string | null;
  proposedEndShipmentDate?: string | null;
  qdDelivery?: DealQdTyp;
  qdFrequency?: DlDlvryFreqcCd;
  freeOnBoardReductionFlag?: boolean;
  alcoholSelectFlag?: boolean;
  mixMatchGroup?: number | null;
  proposedMixMatchGroup?: number | null;
  description?: string | null;
  details?: StagingDiscountDetail[];
  mixMatchFlag?: boolean;
  discountType?: DiscountType;
  hasErrors?: boolean;
  violations?: ValidationResult[];
  id?: number;
  cancelExisting?: boolean;
  newMixMatchGroupKey?: string | null;
  quantityDiscountFlag?: boolean;
  variableSplitFlag?: boolean;
};
export type StagingDiscountData = {
  hasErrors?: boolean;
  violations?: ValidationResult[];
  title?: string;
  summary?: DataSummary;
  data?: StagingDiscount[];
  description?: string | null;
  rationale?: string | null;
};
export type StagingFrontline = {
  hasErrors?: boolean;
  violations?: ValidationResult[];
  id?: number;
  importKey?: string;
  wslrNumber?: string;
  wslrCustGrpSeqId?: number;
  regionCode?: string;
  stateCode?: string;
  wslrCityState?: string;
  brandSeqId?: number;
  brandName?: string;
  pkgGrpSeqId?: number;
  packageDescription?: string;
  fobIncrement?: number;
  ptrIncrement?: number;
  alcoholStrengths?: string | null;
  fob?: number | null;
  proposedFob?: number | null;
  recommendedPtr?: number | null;
  proposedRecommendedPtr?: number | null;
};
export type StagingFrontlineData = {
  hasErrors?: boolean;
  violations?: ValidationResult[];
  title?: string;
  summary?: DataSummary;
  data?: StagingFrontline[];
  proposedEffectiveDate?: string;
};
export type ViewGuidelineExceptionPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewGuidelineException[];
};
export type FlatFrontlinePaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: FlatFrontline[];
};
export type ViewConcurrencyViolationStatus = {
  canAdd: boolean;
  reason: string;
};
export type PackageSelection = {
  pkgGrpSeqId: number;
  displaySeqCd?: string | null;
  active?: boolean;
  draftOption?: DraftOpt;
  displayName?: string | null;
};
export type PackageSelectionPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: PackageSelection[];
};
export type LiqTyp = {
  liquidTypCd: string;
  liquidTyp: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
};
export type LiqTypMapping = {
  stateCd: string;
  brandSeqId: number;
  liquidTypCd: string;
  minAbv?: number | null;
  maxAbv: number;
  effBegDt: string;
  effEndDt?: string | null;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
};
export type LiqTypMappingPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: LiqTypMapping[];
};
export type LegendPriceChangeRequestSummary = {
  id?: string;
  refId?: string;
  title?: string;
  region?: string;
  stateCd?: string;
  stateName?: string;
  status?: string;
  requestType?: string;
  discountType?: string;
  discountLevel?: string;
  discountsCount?: number;
  frontlinesCount?: number;
};
export type LegendPriceChangeRequestSummaryPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: LegendPriceChangeRequestSummary[];
};
export type RecordTypes = 'None' | 'Discount' | 'Frontline';
export type WslrCustGrp = {
  wslrCustGrpSeqId: number;
  stateCd?: string | null;
  wslrNumber?: string | null;
  indccFlg?: string | null;
  name: string;
  dsc?: string | null;
  begEffDt: string;
  endEffDt?: string | null;
  statCd?: string | null;
  fipsStCd?: string | null;
  fipsCountyCd?: string | null;
  rpfFieldNbr?: number | null;
  rpfFldNbrDesc?: string | null;
  rpfCd?: string | null;
  chainNbr?: number | null;
  venueNbr?: number | null;
  venueAddrTxt?: string | null;
  venueCityTxt?: string | null;
  venueStCd?: string | null;
  lastUpdateDate: string;
  lastUpdatedBy: string;
  createdBy: string;
  creationDate: string;
  wslrCustGrpTypCd: string;
};
export type WslrCustGrpPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: WslrCustGrp[];
};
export type ViewGeographies = {
  wslrCustGrpSeqId: number;
  wslrLevelCode: string;
  wslrLevelDescription?: string | null;
  name: string;
  description?: string | null;
  statusCode?: string | null;
  statusDesc: string;
  wslrNumber?: string | null;
  stateCd?: string | null;
  fipsStCd?: string | null;
  fipsCountyCd?: string | null;
  rpfFieldNbr?: number | null;
  rpfFldNbrDesc?: string | null;
  rpfCd?: string | null;
  chainNbr?: number | null;
  venueNbr?: number | null;
  venueAddrTxt?: string | null;
  venueCityTxt?: string | null;
  venueStCd?: string | null;
};
export type ViewGeographiesPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewGeographies[];
};
export type WholesalerSelection = {
  wslrCustGrpSeqId?: number;
  wslrNumber?: string | null;
  stateCode?: string | null;
  regionCode?: string | null;
  wslrCityState?: string | null;
  active?: boolean;
  nonEquity?: boolean;
  subWholesalerLevel?: SubWholesalerLevel;
  displayName?: string;
};
export type WholesalerSelectionPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: WholesalerSelection[];
};
export type Frontline = {
  prcChgHdrSeqId: number;
  brandSeqId: number;
  pkgGrpSeqId: number;
  nrpVersSeqId?: number | null;
  pcrSeqId?: number | null;
  curBegEffDt?: string | null;
  statCd: string;
  cuomCd: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  curEndEffDt?: string | null;
  propBegEffDt?: string | null;
  propEndEffDt?: string | null;
  pflFobAmt?: number | null;
  recordTyp?: string | null;
  milOptCd?: string | null;
  fobBrewery?: string | null;
  wslrNumber: string;
  vflPtrAmt?: number | null;
  vflPtrEffDt?: string | null;
  cflFobAmt?: number | null;
  cflPtrAmt?: number | null;
  pflPtrAmt?: number | null;
};
export type FrontlinePaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: Frontline[];
};
export type FeatureFlag = 'Preview' | 'ImportFrontline' | 'PcrFrontline' | 'DiscountDetailsV2' | 'TwoInOneYear';
export type Feature = {
  name?: string;
  enabled?: boolean;
};
export type BrandVers = {
  versNbr: number;
  brandSeqId: number;
  brewerCd: string;
  statCd: string;
  begEffDt: string;
  name: string;
  alphaCd?: string | null;
  typeCd: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
  createdBy: string;
  shortDsc?: string | null;
  endEffDt?: string | null;
  dsplySeqCd?: string | null;
  varPackFlg?: string | null;
  cmptvBrandCd?: string | null;
  registeredNm: string;
  fpcTaxCd?: string | null;
};
export type BrandVersPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: BrandVers[];
};
export type BrandType = 'AB' | 'Competitor' | 'Dummy' | 'ImportBrandAlliance' | 'MicroBrand';
export type BrandSelection = {
  brandSeqId: number;
  displaySeqId?: string | null;
  active?: boolean;
  brandType?: BrandType;
  varietyPack?: boolean;
  displayName?: string;
};
export type BrandSelectionPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: BrandSelection[];
};
export type BrandGrp = {
  brandGrpId: number;
  brandGrpTyp: string;
  brndCatCd?: string | null;
  statCd: string;
  begEffDt: string;
  name: string;
  prcCatCd?: string | null;
  lastUpdateDate: string;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  endEffDt?: string | null;
  dsplySeqCd?: number | null;
  familyCd?: string | null;
};
export type BrandGrpPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: BrandGrp[];
};
export type BrandGroupType = 'Guidelines' | 'PriceCategory' | 'Selection';
export type BrandMbr = {
  brandGrpId: number;
  cpsUseCd?: string | null;
  keyBrandFlg: string;
  dsplySeqCd: string;
  alchlStrengthCd: string;
  brandSeqId: number;
  createdBy: string;
  creationDate: string;
  lastUpdatedBy: string;
  lastUpdateDate: string;
};
export type BrandMbrPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: BrandMbr[];
};
export type BatchLog = {
  batchJobId: number;
  userId: string;
  jobStat?: string | null;
  planPcrCd?: string | null;
  planPcrSeqId?: number | null;
  flexNbr?: number | null;
  creationDate?: string | null;
  lastUpdateDate?: string | null;
  flexDt?: string | null;
  lastUpdatedBy?: string | null;
  createdBy?: string | null;
  jobNm?: string | null;
  flexChar?: string | null;
  excteStringTxt?: string | null;
};
export type BatchLogPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: BatchLog[];
};
export type AlchlStrength = {
  alchlStrengthCd: string;
};
export type TimeZoneInfo = {
  id?: string;
  hasIanaId?: boolean;
  displayName?: string;
  standardName?: string;
  daylightName?: string;
  baseUtcOffset?: string;
  supportsDaylightSavingTime?: boolean;
};
export type ResponseObject = {
  now?: string;
  utcNow?: string;
  offsetNow?: string;
  offsetUtcNow?: string;
  localTimeZone?: TimeZoneInfo;
  utcTimeZone?: TimeZoneInfo;
};
export type ActivityPaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: Activity[];
};
export type EdmSchemaElementKind = 'None' | 'TypeDefinition' | 'Term' | 'Action' | 'EntityContainer' | 'Function';
export type IEdmSchemaElement = {
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
  name?: string | null;
};
export type EdmTypeKind =
  | 'None'
  | 'Primitive'
  | 'Entity'
  | 'Complex'
  | 'Collection'
  | 'EntityReference'
  | 'Enum'
  | 'TypeDefinition'
  | 'Untyped'
  | 'Path';
export type IEdmType = {
  typeKind?: EdmTypeKind;
};
export type IEdmTypeReference = {
  isNullable?: boolean;
  definition?: IEdmType;
};
export type IEdmTerm = {
  type?: IEdmTypeReference;
  appliesTo?: string | null;
  defaultValue?: string | null;
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
  name?: string | null;
};
export type IEdmVocabularyAnnotatable = object;
export type EdmExpressionKind =
  | 'None'
  | 'BinaryConstant'
  | 'BooleanConstant'
  | 'DateTimeOffsetConstant'
  | 'DecimalConstant'
  | 'FloatingConstant'
  | 'GuidConstant'
  | 'IntegerConstant'
  | 'StringConstant'
  | 'DurationConstant'
  | 'Null'
  | 'Record'
  | 'Collection'
  | 'Path'
  | 'If'
  | 'Cast'
  | 'IsType'
  | 'FunctionApplication'
  | 'LabeledExpressionReference'
  | 'Labeled'
  | 'PropertyPath'
  | 'NavigationPropertyPath'
  | 'DateConstant'
  | 'TimeOfDayConstant'
  | 'EnumMember'
  | 'AnnotationPath';
export type IEdmExpression = {
  expressionKind?: EdmExpressionKind;
};
export type IEdmVocabularyAnnotation = {
  qualifier?: string | null;
  term?: IEdmTerm;
  target?: IEdmVocabularyAnnotatable;
  value?: IEdmExpression;
};
export type IEdmDirectValueAnnotationsManager = object;
export type EdmContainerElementKind = 'None' | 'EntitySet' | 'ActionImport' | 'FunctionImport' | 'Singleton';
export type IEdmEntityContainerElement = {
  containerElementKind?: EdmContainerElementKind;
  container?: IEdmEntityContainer;
  name?: string | null;
};
export type IEdmEntityContainer = {
  elements?: IEdmEntityContainerElement[] | null;
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
  name?: string | null;
};
export type IEdmModel = {
  schemaElements?: IEdmSchemaElement[] | null;
  vocabularyAnnotations?: IEdmVocabularyAnnotation[] | null;
  referencedModels?: IEdmModel[] | null;
  declaredNamespaces?: string[] | null;
  directValueAnnotationsManager?: IEdmDirectValueAnnotationsManager;
  entityContainer?: IEdmEntityContainer;
};
export type ODataEntitySetInfo = {
  typeAnnotation?: ODataTypeAnnotation;
  url?: string | null;
  name?: string | null;
  title?: string | null;
};
export type ODataSingletonInfo = {
  typeAnnotation?: ODataTypeAnnotation;
  url?: string | null;
  name?: string | null;
  title?: string | null;
};
export type ODataFunctionImportInfo = {
  typeAnnotation?: ODataTypeAnnotation;
  url?: string | null;
  name?: string | null;
  title?: string | null;
};
export type ODataServiceDocument = {
  typeAnnotation?: ODataTypeAnnotation;
  entitySets?: ODataEntitySetInfo[] | null;
  singletons?: ODataSingletonInfo[] | null;
  functionImports?: ODataFunctionImportInfo[] | null;
};
export type DiscountOfferings =
  | 'None'
  | 'NoOffering'
  | 'InAndOut'
  | 'Mulligan'
  | 'PackageSubstitution'
  | 'PackageExtension'
  | 'All';
export type CivMilSelections = 'CivMil' | 'Military' | 'Civilian';
export type ConsumptionTypeSelection = 'OnPremise' | 'OffPremise' | 'Combined';
export type DateRolloverOption = 'Mirror' | 'Monthly' | 'Quarterly' | 'Yearlong';
export type CalendarRolloverSelections = {
  discountOfferings?: DiscountOfferings;
  discountTypes?: DbDiscountTypes;
  civMilSelections?: CivMilSelections;
  consumptionTypes?: ConsumptionTypeSelection;
  dimensionSelections?: DimensionSelections;
  removeExistingDiscounts?: boolean;
  dateRolloverOption?: DateRolloverOption;
};
export type CalendarRolloverSelectionsDebug = {
  discountOfferings?: DiscountOfferings;
  discountTypes?: DbDiscountTypes;
  civMilSelections?: CivMilSelections;
  consumptionTypes?: ConsumptionTypeSelection;
  dimensionSelections?: DimensionSelections;
  removeExistingDiscounts?: boolean;
  dateRolloverOption?: DateRolloverOption;
};
export type DescriptionEntry = {
  description: string;
};
export type NoteType = 'PcrNote' | 'PcrRationale' | 'PlanNote' | 'FrontlineNote' | 'FrontlineRationale';
export type UpdateNoteRequestBody = {
  noteType?: NoteType;
  description: string;
};
export type OverrideAllGuidelinesRequestBody = {
  comment?: string;
};
export type ChangeEffectiveDateRequestBody = {
  changeDateFrom: string;
  changeDateTo: string;
  dimensionSelections: DimensionSelections;
  entirePcrPostpone?: boolean;
};
export type FrontlineComponents =
  | 'All'
  | 'Fob'
  | 'HoldCurrentPtr'
  | 'PtrIncrement'
  | 'AbSplitDollars'
  | 'AbSplitPercentage'
  | 'ProposedFlPtr';
export type DateIndicator = 'BeginAndEnd' | 'BeginOnly' | 'EndOnly' | 'NewMarket' | 'BeginOrEnd';
export type FrontlineMassCopyRequestBody = {
  frontlineComponents?: FrontlineComponents;
  effectiveDateRange: DateRange;
  dateIndicator?: DateIndicator;
  usePackageDifferential?: boolean;
  sourceSelections: DimensionSelections;
  destinationSelections: DimensionSelections;
};
export type ParityWithExistingRequestBody = {
  geographies: Geography[];
  newBrandSeqIds: number[];
  newPkgGrpSeqIds: number[];
  newAlcohols: string[];
  existingBrandSeqId?: number;
  existingPkgGrpSeqId?: number;
  existingAlcohol: string;
  effectiveDate: string;
};
export type PtrIncrementRequestBody = {
  increment?: number;
  splitPercent?: number;
  usePackageDifferential?: boolean;
  beginDate?: string;
  draftOptCode?: DraftOpt;
  dimensionSelections: DimensionSelections;
};
export type UpdateRequestBody = {
  proposedFob?: number | null;
  proposedRecommendedPtr?: number | null;
  frontlineId: number;
};
export type UpdateBatchRequestBody = {
  proposedFob?: number | null;
  proposedRecommendedPtr?: number | null;
  frontlineIds: number[];
};
export type UpdateByIdRequestBody = {
  proposedFob?: number | null;
  proposedRecommendedPtr?: number | null;
};
export type AlcoholDetail = {
  alcoholStrength?: string;
  abSplitPtrAmount?: number | null;
  wslrSplitPtrAmount?: number | null;
  freightAmount?: number | null;
  stateTaxAmount?: number | null;
  deliverAmount?: number | null;
};
export type ViewFrontline = {
  frontlineId?: number;
  wslrCustGrpSeqId?: number;
  wslrNumber?: string;
  wslrCityState?: string;
  brandSeqId?: number;
  brandCode?: string;
  brandName?: string;
  pkgGrpSeqId?: number;
  packageDescription?: string;
  alcoholStrengths?: string;
  proposedBeginEffectiveDate?: string | null;
  fob?: number | null;
  proposedFob?: number | null;
  recommendedPtr?: number | null;
  proposedRecommendedPtr?: number | null;
  verifiedPtr?: number | null;
  recommendedPtrIncrease?: number | null;
  alcoholDetails?: AlcoholDetail[];
};
export type ViewFrontlinePaginationResultWithLinks = {
  links?: PaginationLinks;
  totalCount?: number;
  results?: ViewFrontline[];
};
export type AlcoholIntent = 'All' | 'Specific';
export type DiscountMassCopyOptions = {
  effectiveDateRange: DateRange;
  civMil?: CivMilSelections;
  alcoholIntent?: AlcoholIntent;
  discountType?: DbDiscountTypes;
  consumptionType?: ConsumptionTypeSelection;
  allLevels?: boolean;
  sourceSelections: DimensionSelections;
  destinationSelections: DimensionSelections;
  enterNewPromotionDates?: boolean;
  newPromotionDates?: DateRange[] | null;
};
export type NewDiscount = {
  wslrNumber: string;
  discountType: DbDiscountTypes;
  discountAmount?: number;
  abSplitAmount?: number;
  quantityMin?: number;
  quantityMax?: number;
  variableSplitFromAmount?: number | null;
  variableSplitToAmount?: number | null;
};
export type RmCreateDiscountRequest = {
  dimensionSelections: DimensionSelections;
  dateRanges: DateRange[];
  discounts: NewDiscount[];
  subWslrLevel?: SubWholesalerLevel;
  consumptionType?: ConsumptionType;
  discountDescription?: DiscountDescriptions;
  civMil?: CivMil;
  description?: string;
};
export type CascadeDiscountsBatchRequest = {
  pcrIds: number[];
};
export type CreatePcrHeaderRequest = {
  state: string;
  dateRange?: DateRange;
};
export type SearchAndAddRequestBody = {
  civMil?: CivMil;
  dimensionSelections: DimensionSelections;
  wholesalerSpecificValues?: WholesalerValues[];
};
export type SearchAndAddOptions = {
  discountType?: DbDiscountTypes;
  discountOffering?: DiscountOfferings;
  civMil?: CivMilSelections;
  dateRange: DateRange;
  consumptionType?: ConsumptionTypeSelection;
  dimensionSelections: DimensionSelections;
  allSubwholesalerLevels?: boolean;
  includeMilitaryRetailerAttribute?: boolean;
};
export type Comment = {
  body: string;
};
export type OverrideGuidelinesRequest = {
  excpSeqIds: number[];
  comment: Comment;
};
export type FobDeleteRequest = {
  brandSeqIds: number[];
  pkgGrpSeqIds: number[];
  alcoholStrengths: string[];
  fobCancellationDate: string;
  description: string;
  pccNumber?: string | null;
  notes?: string | null;
};
export type RemoveFrontlinesRequest = {
  frontlineIds?: number[];
  deleteRelated?: boolean;
  deleteFrontlineDeals?: boolean;
};
export type MasterRecordType = 'Plan' | 'PCR';
export type UpdateBatchRequest = {
  proposedFob?: number | null;
  proposedRecommendedPtr?: number | null;
  frontlineIds: number[];
  masterRecordType: MasterRecordType;
  planOrPcrSeqSeqId?: number;
};
export type UpdateBatchDiscountRequest = {
  discounts?: Discount[];
};
export type ActionCode = 'Change' | 'Remove' | 'Cancel';
export type UpdateDiscountHeadersRequest = {
  discountIds: number[];
  modifiedCode?: ActionCode;
  proposedBeginStrDate?: string | null;
  proposedEndStrDate?: string | null;
  proposedBeginShipDate?: string | null;
  proposedEndShipDate?: string | null;
};
export type DiscountSplitLevel = {
  from?: number;
  to?: number;
  abSplitAmount?: number;
  wslrSplitAmount?: number;
};
export type UpdateDiscountLevelsRequest = {
  discountIds: number[];
  levels: DiscountSplitLevel[];
};
export type DiscountTier = {
  maxDiscountAmount?: number;
  abSplitAmount?: number;
  wslrSplitAmount?: number;
  quantityMin?: number;
  quantityMax?: number;
};
export type UpdateDiscountTiersRequest = {
  discountIds: number[];
  tiers: DiscountTier[];
};
export type UpdateDiscountTierValuesRequest = {
  discountDetailIds: number[];
  maxDiscountAmount?: number;
  abSplitAmount?: number;
  wslrSplitAmount?: number;
};
export type UpdateDiscountValuesRequest = {
  discountIds: number[];
  maxDiscountAmount?: number;
  abSplitAmount?: number;
  wslrSplitAmount?: number;
};
export type AssociateMixMatchGroupRequest = {
  mixMatchGroup?: number | null;
  dealHdrSeqIds: number[];
};
export type ClearMixMatchGroupRequest = {
  dealHdrSeqIds: number[];
};
export const {
  useGetOdataPlansByKeyFlatFrontlinesQuery,
  useGetOdataPlansByKeyFlatFrontlinesCountQuery,
  useGetOdataPlansByKeyFlatDiscountsQuery,
  useGetOdataPlansByKeyFlatDiscountsCountQuery,
  useGetOdataPlansByKeyGuidelineExceptionsQuery,
  useGetOdataPlansByKeyGuidelineExceptionsCountQuery,
  useGetOdataPriceChangeRequestsByKeyFlatFrontlinesQuery,
  useGetOdataPriceChangeRequestsByKeyFlatFrontlinesCountQuery,
  useGetOdataPriceChangeRequestsByKeyFlatDiscountsQuery,
  useGetOdataPriceChangeRequestsByKeyFlatDiscountsCountQuery,
  useGetOdataPriceChangeRequestsByKeyGuidelineExceptionsQuery,
  useGetOdataPriceChangeRequestsByKeyGuidelineExceptionsCountQuery,
  useGetOdataWholesalerGroupsQuery,
  useGetOdataWholesalerGroupsCountQuery,
  useGetApiUserMeQuery,
  useGetApiStatesQuery,
  useGetApiPlansQuery,
  usePostApiPlansMutation,
  useGetApiPlansByPlanSeqIdQuery,
  useGetApiPlansByPlanSeqIdUserQuery,
  useGetApiPlansViewQuery,
  useGetApiPlansByPlanSeqIdGuidelinesQuery,
  useGetApiPlansByPlanSeqIdGuidelinesLastRanQuery,
  useGetApiPlansByPlanSeqIdGuidelinesViewQuery,
  useGetApiPlansByPlanSeqIdFrontlinesUniqueDimensionsQuery,
  useGetApiPlansByPlanSeqIdDiscountsQuery,
  usePostApiPlansByPlanSeqIdDiscountsMutation,
  useGetApiPlansByPlanSeqIdDiscountsUniqueDimensionsQuery,
  useGetApiPlansByPlanSeqIdDiscountsViewQuery,
  useGetApiPlansDetailsViewQuery,
  useGetApiPcrsQuery,
  usePostApiPcrsMutation,
  useGetApiPcrsByPcrSeqIdQuery,
  useGetApiPcrsViewQuery,
  useGetApiPcrsStagingByIdQuery,
  useGetApiPcrsStagingByIdFrontlinesQuery,
  useGetApiPcrsStagingByIdDiscountsQuery,
  useGetApiPcrsByPcrSeqIdGuidelinesQuery,
  useGetApiPcrsByPcrSeqIdGuidelinesLastRanQuery,
  useGetApiPcrsByPcrSeqIdGuidelinesViewQuery,
  useGetApiPcrsByPcrSeqIdFrontlinesFlatNoFilterQuery,
  useGetApiPcrsByPcrSeqIdFrontlinesUniqueDimensionsQuery,
  useGetApiPcrsByPcrSeqIdDiscountsQuery,
  usePostApiPcrsByPcrSeqIdDiscountsMutation,
  useGetApiPcrsByPcrSeqIdDiscountsUniqueDimensionsQuery,
  useGetApiPcrsByPcrSeqIdDiscountsViewQuery,
  useGetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsQuery,
  useGetApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsStatusQuery,
  useGetApiPackagesSelectionsQuery,
  useGetApiLiquidtypesQuery,
  useGetApiLiquidtypesByLiquidTypeMappingsQuery,
  useGetApiLegendPcrsQuery,
  useGetApiGuidelinesByExcpSeqIdSimilarQuery,
  useGetApiGeographiesQuery,
  useGetApiGeographiesViewQuery,
  useGetApiGeographiesWholesalersSelectionsQuery,
  useGetApiGeographiesWholesalersSelectionsGroupsByWholesalerGroupIdMembersQuery,
  useGetApiFrontlinesQuery,
  useGetApiFrontlinesByFrontlineIdQuery,
  useGetApiFeaturesByNameEnabledQuery,
  useGetApiFeaturesQuery,
  useGetApiDiscountsByDealHdrSeqIdAccountDetailsQuery,
  useGetApiDiscountsByDealHdrSeqIdViewQuery,
  useGetApiBrandsQuery,
  useGetApiBrandsByBrandSeqIdQuery,
  useGetApiBrandsSelectionsQuery,
  useGetApiBrandsGroupsQuery,
  useGetApiBrandsGroupsByBrandGrpIdQuery,
  useGetApiBrandsGroupsByBrandGrpIdMembersQuery,
  useGetApiBatchQuery,
  useGetApiBatchByBatchJobIdQuery,
  useGetApiAlcoholsQuery,
  useGetApiHealthQuery,
  useGetApiAdminNowQuery,
  useGetApiAdminServerErrorQuery,
  useGetApiActivitiesQuery,
  useGetApiActivitiesByActivityIdQuery,
  useGetOdataMetadataQuery,
  useGetOdataQuery,
  usePostApiPlansByPlanSeqIdApproveMutation,
  usePostApiPlansByPlanSeqIdCalendarRolloverMutation,
  usePostApiPlansByPlanSeqIdCalendarRolloverDebugMutation,
  usePostApiPlansByPlanSeqIdCheckInMutation,
  usePostApiPlansByPlanSeqIdCheckOutMutation,
  usePostApiPlansByPlanSeqIdDeleteMutation,
  usePostApiPlansByPlanSeqIdRefreshMutation,
  usePostApiPlansByPlanSeqIdSetCurrentMutation,
  usePostApiPlansByPlanSeqIdSetDescriptionMutation,
  usePostApiPlansByPlanSeqIdUpdateNoteMutation,
  usePostApiPlansByPlanSeqIdGuidelinesOverrideMutation,
  usePostApiPlansByPlanSeqIdGuidelinesRunMutation,
  usePostApiPlansByPlanSeqIdFrontlinesChangeEffectiveDateMutation,
  usePostApiPlansByPlanSeqIdFrontlineMassCopyMutation,
  usePostApiPlansByPlanSeqIdFrontlinesParityWithExistingMutation,
  usePostApiPlansByPlanSeqIdFrontlinesPtrIncrementMutation,
  usePostApiPlansByPlanSeqIdFrontlinesUpdateMutation,
  usePostApiPlansByPlanSeqIdFrontlinesUpdateBatchMutation,
  usePostApiPlansByPlanSeqIdFrontlinesAndFrontlineIdUpdateMutation,
  usePostApiPlansByPlanSeqIdFrontlinesViewMutation,
  usePostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdCancelMutation,
  usePostApiPlansByPlanSeqIdDiscountsMassCopyMutation,
  usePostApiPlansByPlanSeqIdDiscountsAndDealHdrSeqIdRemoveMutation,
  usePostApiPlansByPlanSeqIdRmDiscountsMutation,
  usePostApiPcrsByPcrSeqIdApproveMutation,
  usePostApiPcrsByPcrSeqIdCascadeDiscountsMutation,
  usePostApiPcrsCascadeDiscountsMutation,
  usePostApiPcrsByPcrSeqIdCheckInMutation,
  usePostApiPcrsByPcrSeqIdCheckOutMutation,
  usePostApiPcrsCreateHeaderMutation,
  usePostApiPcrsByPcrSeqIdRefreshMutation,
  usePostApiPcrsByPcrSeqIdRejectMutation,
  usePostApiPcrsByPcrSeqIdSetDescriptionMutation,
  usePostApiPcrsByPcrSeqIdUpdateNoteMutation,
  usePostApiPcrsStagingLegendByLegendPcrIdMutation,
  usePostApiPcrsStagingPricingFormMutation,
  usePostApiPcrsStagingByIdSubmitMutation,
  usePostApiPcrsByPcrSeqIdGuidelinesOverrideMutation,
  usePostApiPcrsByPcrSeqIdGuidelinesRunMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesChangeEffectiveDateMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesFlatFilterMutation,
  usePostApiPcrsByPcrSeqIdFrontlineMassCopyMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesParityWithExistingMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesPtrIncrementMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdRemoveMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesSearchAndAddMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesUpdateMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesUpdateBatchMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesAndFrontlineIdUpdateMutation,
  usePostApiPcrsByPcrSeqIdFrontlinesViewMutation,
  usePostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdCancelMutation,
  usePostApiPcrsByPcrSeqIdDiscountsMassCopyMutation,
  usePostApiPcrsByPcrSeqIdDiscountsAndDealHdrSeqIdRemoveMutation,
  usePostApiPcrsByPcrSeqIdRmDiscountsMutation,
  usePostApiPcrsByPcrSeqIdDiscountsSearchAndAddMutation,
  usePostApiPcrsByPcrSeqIdDiscountsConcurrencyViolationsAddMutation,
  usePostApiGuidelinesByExcpSeqIdOverrideMutation,
  usePostApiGuidelinesOverrideMutation,
  usePostApiFrontlinesFobDeleteMutation,
  usePostApiFrontlinesRemoveMutation,
  usePostApiFrontlinesUpdateBatchMutation,
  usePostApiDiscountsByDealHdrSeqIdUpdateMutation,
  usePostApiDiscountsUpdateMutation,
  usePostApiDiscountsUpdateHeadersMutation,
  usePostApiDiscountsUpdateLevelsMutation,
  usePostApiDiscountsUpdateTiersMutation,
  usePostApiDiscountsUpdateTierValuesMutation,
  usePostApiDiscountsUpdateValuesMutation,
  usePostApiDiscountsMixMatchAssociateMutation,
  usePostApiDiscountsMixMatchByDealHdrSeqIdClearMutation,
  usePostApiDiscountsMixMatchClearMutation,
  usePostApiDiscountsMixMatchCreateMutation,
  usePostApiDiscountsMixMatchByDealHdrSeqIdSetMutation,
  usePostApiAdminBodySizeDebugMutation,
  usePostOdataPlansByKeyFlatFrontlinesQueryMutation,
  usePostOdataPlansByKeyFlatDiscountsQueryMutation,
  usePostOdataPlansByKeyGuidelineExceptionsQueryMutation,
  usePostOdataPriceChangeRequestsByKeyFlatFrontlinesQueryMutation,
  usePostOdataPriceChangeRequestsByKeyFlatDiscountsQueryMutation,
  usePostOdataPriceChangeRequestsByKeyGuidelineExceptionsQueryMutation,
  usePostOdataWholesalerGroupsQueryMutation
} = injectedRtkApi;
