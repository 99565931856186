//React-RTK
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const drawerClosedWidth = 80;
const drawerOpenWidth = 250;

const initialState = {
  //the drawer initially starts out closed
  open: false,
  width: drawerClosedWidth
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggle: (state) => {
      state.open = !state.open;
      state.width = state.open ? drawerOpenWidth : drawerClosedWidth;
    }
  }
});

export const { toggle } = drawerSlice.actions;

export const selectDrawerStatus = (state: RootState) => state.drawer.open;
export const selectDrawerWidth = (state: RootState) => state.drawer.width;

export default drawerSlice.reducer;
