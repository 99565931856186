//React
import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useDebounce<T>(value: T, delay = 150) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useContainerWidth(debounce = 100) {
  const [size, setSize] = useState<number | undefined>();
  const ref = (element: HTMLDivElement) => element && setSize(element.getBoundingClientRect().width);
  const debounceResize = useDebounce(size, debounce);
  return { width: debounceResize, ref };
}

//this hook returns the current windows width and height as a tuple
export function useWindowSize(debounce = 150) {
  const [size, setSize] = useState([0, 0]);
  const debounceSize = useDebounce(size, debounce);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return debounceSize;
}

//this is an effect hook that is not called on initial render
export const useEffectAfterFirstRender = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
