//RTK
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DraftOpt, UniqueDimensions } from '../../../api/priceApi/priceApi';
import { RootState } from '../../../app/store';
import { callApiPayloadType } from '../../../components/ODataGrids/useODataAgGrids';
import { oDataProviderQueryString } from '../../../types/oDataProviderQueryString';

export type SingleGeographySelection = {
  wslrCustGrpSeqId: number;
  wslrNumber: number;
  displayName: string;
};
export type SingleBrandSelection = { brandSeqId: number; displayName: string };
export type SinglePackageSelection = { pkgGrpSeqId: number; displayName: string; draftOption: DraftOpt };
export type SingleAlcoholSelection = { alchlStrengthCd: string };
export type SingleDateSelection = { effectiveDate: string };
export type AllUniqueDimensions = {
  geographies: SingleGeographySelection[];
  brands: SingleBrandSelection[];
  packages: SinglePackageSelection[];
  alcohols: SingleAlcoholSelection[];
};
export type DropdownItem = { label: string; id: number };

export type ParityBuilderState = {
  brandsList: DropdownItem[];
  selectedBrand: DropdownItem | null;

  packagesList: DropdownItem[];
  selectedPackage: DropdownItem | null;

  alcoholsList: DropdownItem[];
  selectedAlcohol: DropdownItem | null;

  selectedDate: string | null;

  parityModalOpen: boolean;
};

type IFrontlineDetailState = {
  allUniqueGeographies: SingleGeographySelection[];
  allUniqueBrands: SingleBrandSelection[];
  allUniquePackages: SinglePackageSelection[];
  allUniqueAlcohols: SingleAlcoholSelection[];
  allUniqueEffectiveDates: SingleDateSelection[];
  selectedUniqueGeographies: SingleGeographySelection[];
  selectedUniqueBrands: SingleBrandSelection[];
  selectedUniquePackages: SinglePackageSelection[];
  selectedUniqueAlcohols: SingleAlcoholSelection[];
  selectedUniqueEffectiveDates: SingleDateSelection[];
  dimensionCriteriaEffectiveDate: string;
  frontlineDetails: any[];
  activeTab: number;
  //PTR Increment
  packageIncrement?: number | string;
  fobPackageIncrement?: number | string;
  currentPackageAPSplitPercent?: number;
  draftIncrement?: number;
  fobDraftIncrement?: number | string;
  currentDraftIncrementABSplitPercent?: number;
  ptrIncrementEffectiveDate: string;
  ptrApplyButtonDisplayed: boolean;

  //Change Effective
  changeStartEffectiveDate: string;
  changeToEffectiveDate: string;

  //Parity W/ Existing
  parityBrands: any[];
  parityPackages: any[];
  parityAlcohols: any[];

  //Predominate Values
  currentFOB: number;
  currentRecFLPTR: number;
  currentPredomEffectiveDate: string;
  isPredomValuesApplyButtonDisabled: boolean;

  filteredUniqueDimensionsApiArg: UniqueDimensions;
  totalRecords?: number;
  totalRecordsFiltered?: number;
  parityModalOpen: boolean;
  parityBuilderState: ParityBuilderState;
  oDataQuery: {
    string: oDataProviderQueryString | null;
    payloadModel: callApiPayloadType | null;
  };
  refreshGrid: { refresh: boolean };
};

const initialState: IFrontlineDetailState = {
  allUniqueGeographies: [],
  allUniqueBrands: [],
  allUniquePackages: [],
  allUniqueAlcohols: [],
  allUniqueEffectiveDates: [],
  selectedUniqueGeographies: [],
  selectedUniqueBrands: [],
  selectedUniquePackages: [],
  selectedUniqueAlcohols: [],
  selectedUniqueEffectiveDates: [],
  dimensionCriteriaEffectiveDate: 'string',
  frontlineDetails: [],
  activeTab: 0,
  //PTR Increment
  packageIncrement: undefined,
  fobPackageIncrement: undefined,
  currentPackageAPSplitPercent: 70,
  draftIncrement: undefined,
  fobDraftIncrement: undefined,
  currentDraftIncrementABSplitPercent: 50,
  ptrIncrementEffectiveDate: '',
  ptrApplyButtonDisplayed: false,

  //Change Effective Date
  changeStartEffectiveDate: 'string',
  changeToEffectiveDate: 'string',

  //Parity W/ Existing
  parityBrands: [],
  parityPackages: [],
  parityAlcohols: [],

  //Predominate Values
  currentFOB: 0,
  currentRecFLPTR: 0,
  currentPredomEffectiveDate: '',
  isPredomValuesApplyButtonDisabled: false,

  filteredUniqueDimensionsApiArg: {
    geographies: [],
    brands: [],
    packages: [],
    alcoholStrengths: [],
    effectiveDates: []
  },
  totalRecords: 0,
  totalRecordsFiltered: 0,
  parityModalOpen: false,
  parityBuilderState: {
    brandsList: [],
    selectedBrand: null,
    packagesList: [],
    selectedPackage: null,
    alcoholsList: [],
    selectedAlcohol: null,
    selectedDate: null,
    parityModalOpen: false
  },
  oDataQuery: {
    string: null,
    payloadModel: null
  },
  refreshGrid: { refresh: false }
};

export const frontlineDetailSlice = createSlice({
  name: 'frontlineDetail',
  initialState,
  reducers: {
    setAllUniqueGeographies: (state, action) => {
      state.allUniqueGeographies = action.payload;
    },
    setAllUniqueBrands: (state, action) => {
      state.allUniqueBrands = action.payload;
    },
    setAllUniquePackages: (state, action) => {
      state.allUniquePackages = action.payload;
    },
    setAllUniqueAlcohols: (state, action) => {
      state.allUniqueAlcohols = action.payload;
    },
    setAllUniqueDates: (state, action) => {
      state.allUniqueEffectiveDates = action.payload;
    },
    setAllUniqueSelections: (state, action) => {
      const { uniqueGeographies, uniqueBrands, uniquePackages, uniqueAlcohols, uniqueEffectiveDates } = action.payload;
      state.allUniqueGeographies = uniqueGeographies;
      state.allUniqueBrands = uniqueBrands;
      state.allUniquePackages = uniquePackages;
      state.allUniqueAlcohols = uniqueAlcohols;
      state.allUniqueEffectiveDates = uniqueEffectiveDates;
    },
    setSelectedUniqueGeographies: (state, action) => {
      state.selectedUniqueGeographies = action.payload;
    },
    setSelectedUniqueBrands: (state, action) => {
      state.selectedUniqueBrands = action.payload;
    },
    setSelectedUniquePackages: (state, action) => {
      state.selectedUniquePackages = action.payload;
    },
    setSelectedUniqueAlcohols: (state, action) => {
      state.selectedUniqueAlcohols = action.payload;
    },
    setSelectedUniqueDates: (state, action) => {
      state.allUniqueEffectiveDates = action.payload;
    },
    setSelectedUniqueDimensions: (state, action) => {
      const { header, selections } = action.payload;
      switch (header) {
        case 'geographies':
          state.selectedUniqueGeographies = selections;
          break;
        case 'brands':
          state.selectedUniqueBrands = selections;
          break;
        case 'packages':
          state.selectedUniquePackages = selections;
          break;
        case 'alcohols':
          state.selectedUniqueAlcohols = selections;
          break;
        //May not need this
        case 'dates':
          state.allUniqueEffectiveDates = selections;
          break;
      }
    },
    setDimensionCriteriaEffectiveDate: (state, action) => {
      state.dimensionCriteriaEffectiveDate = action.payload;
    },
    setPtrIncrementEffectiveDate: (state, action) => {
      state.ptrIncrementEffectiveDate = action.payload;
    },
    //Change Date Builder
    setChangeDateStartEffectiveDate: (state, action) => {
      state.changeStartEffectiveDate = action.payload;
    },
    setChangeDateToEffectiveDate: (state, action) => {
      state.changeToEffectiveDate = action.payload;
    },

    setFrontlineDetails: (state, action) => {
      state.frontlineDetails = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    //PTR Increment
    setPackageIncrement: (state, action) => {
      state.packageIncrement = action.payload;
    },
    setCurrentPackageAPSplitPercent: (state, action) => {
      state.currentPackageAPSplitPercent = action.payload;
    },
    setDraftIncrement: (state, action) => {
      state.draftIncrement = action.payload;
    },
    setCurrentDraftIncrementABSplitPercent: (state, action) => {
      state.currentDraftIncrementABSplitPercent = action.payload;
    },
    setFobDraftIncrement: (state, action) => {
      state.fobDraftIncrement = action.payload;
    },
    setFobPackageIncrement: (state, action) => {
      state.fobPackageIncrement = action.payload;
    },

    //Parity W Existing
    setParityBrands: (state, action) => {
      state.parityBrands = action.payload;
    },
    setParityPackages: (state, action) => {
      state.parityPackages = action.payload;
    },
    setParityAlcohols: (state, action) => {
      state.parityAlcohols = action.payload;
    },
    //Predominate Values
    setCurrentFOB: (state, action) => {
      state.currentFOB = action.payload;
    },
    setCurrentRecFLPTR: (state, action) => {
      state.currentRecFLPTR = action.payload;
    },
    setFilteredUniqueDimensionsApiArg: (state) => {
      //AlchlStrength
      //remap alcs [{alchlStrengthCd:"0"},{alchlStrengthCd:"1"}] --> ["0","1"]
      const alc = state.selectedUniqueAlcohols.length > 0 ? state.selectedUniqueAlcohols : state.allUniqueAlcohols;
      const remappedAlcs = alc.map((element: any) => {
        return element.alchlStrengthCd;
      });
      state.filteredUniqueDimensionsApiArg.alcoholStrengths = remappedAlcs;

      //Brands
      //remap brand [{ brandSeqId: 78, displayName: 'NATURAL LIGHT' }] --> [{ "key": 6, "value": "BUDWEISER" }]
      const brands = state.selectedUniqueBrands.length > 0 ? state.selectedUniqueBrands : state.allUniqueBrands;
      const remappedBrands = brands.map((element) => {
        return { key: element.brandSeqId, value: element.displayName };
      });
      state.filteredUniqueDimensionsApiArg.brands = remappedBrands;

      //Pacakge
      //remap
      // package[{ pkgGrpSeqId: 185, displayName: '24/12 NRLN 2/12' }];
      // --> [{ "key": 154, "value": "24/7 NRLN 4/6" },]
      const pkgs = state.selectedUniquePackages.length > 0 ? state.selectedUniquePackages : state.allUniquePackages;
      const remappedPackages = pkgs.map(({ pkgGrpSeqId, draftOption, displayName }) => {
        return {
          key: { pkgGrpSeqId, draftOption },
          value: displayName
        };
      });
      state.filteredUniqueDimensionsApiArg.packages = remappedPackages;

      //Geo
      // remap
      // geo[{ wslrCustGrpSeqId: 39, wslrNumber: 39, displayName: '15112-G-RENTON WA-General Market' }];
      // --> [{ "key": { "wslrCustGrpSeqId": 1478, "wslrNumber": "71741" }, "value": "71741-G-HOUMA LA-General Market" }]
      const geos =
        state.selectedUniqueGeographies.length > 0 ? state.selectedUniqueGeographies : state.allUniqueGeographies;
      const remappedGeographies = geos.map((element) => {
        return {
          key: { wslrCustGrpSeqId: element.wslrCustGrpSeqId, wslrNumber: element.wslrNumber.toString() },
          value: element.displayName
        };
      });
      state.filteredUniqueDimensionsApiArg.geographies = remappedGeographies;

      //Date
      //remap
      // date[{ effectiveDate: '2020-01-01' }];
      // --> [{ "key": "2020-01-01", "value": "2020-01-01" }]
      const remappedDates = state.selectedUniqueEffectiveDates.map((element) => {
        return element.effectiveDate;
      });
      state.filteredUniqueDimensionsApiArg.effectiveDates = remappedDates;
    },
    resetFilteredDimensions: (state) => {
      state.filteredUniqueDimensionsApiArg = {
        alcoholStrengths: state.allUniqueAlcohols.map((alc) => alc.alchlStrengthCd),
        brands: state.allUniqueBrands.map((element) => {
          return { key: element.brandSeqId, value: element.displayName };
        }),
        packages: state.allUniquePackages.map(({ pkgGrpSeqId, draftOption, displayName }) => {
          return {
            key: { pkgGrpSeqId, draftOption },
            value: displayName
          };
        }),
        geographies: state.allUniqueGeographies.map((element) => {
          return {
            key: { wslrCustGrpSeqId: element.wslrCustGrpSeqId, wslrNumber: element.wslrNumber.toString() },
            value: element.displayName
          };
        }),
        effectiveDates: state.allUniqueEffectiveDates.map((element) => {
          return element.effectiveDate;
        })
      };
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setTotalRecordsFiltered: (state, action) => {
      state.totalRecordsFiltered = action.payload;
    },
    setPtrApplyButtonDisplayed: (state, action) => {
      state.ptrApplyButtonDisplayed = action.payload;
    },
    setParityModalOpen: (state, action) => {
      state.parityModalOpen = action.payload;
    },
    setParityBuilderState: (state, action) => {
      const {
        selectedBrand,
        brandsList,
        packagesList,
        selectedPackage,
        alcoholsList,
        selectedAlcohol,
        parityModalOpen,
        selectedDate
      } = action.payload;

      if (selectedBrand !== undefined) {
        state.parityBuilderState.selectedBrand = selectedBrand;
      }

      if (brandsList !== undefined) {
        state.parityBuilderState.brandsList = brandsList;
      }

      if (packagesList !== undefined) {
        state.parityBuilderState.packagesList = packagesList;
      }

      if (selectedPackage !== undefined) {
        state.parityBuilderState.selectedPackage = selectedPackage;
      }

      if (alcoholsList !== undefined) {
        state.parityBuilderState.alcoholsList = alcoholsList;
      }

      if (selectedAlcohol !== undefined) {
        state.parityBuilderState.selectedAlcohol = selectedAlcohol;
      }

      if (parityModalOpen !== undefined) {
        state.parityBuilderState.parityModalOpen = parityModalOpen;
      }

      if (selectedDate !== undefined) {
        state.parityBuilderState.selectedDate = selectedDate;
      }
    },
    setODataQueryString: (state, action: PayloadAction<oDataProviderQueryString | null>) => {
      state.oDataQuery.string = action.payload;
    },
    setRefreshGrid: (state, action: PayloadAction<boolean>) => {
      state.refreshGrid = { refresh: action.payload };
    },
    handlePtrIncrement: (state, action) => {
      const { draftOption, changedValue } = action.payload;

      //If draft option is package
      if (draftOption === 'Package') {
        if (changedValue === '') state.packageIncrement = undefined;
        else {
          state.packageIncrement = changedValue;

          //Set fob package increment
          if (!state.currentPackageAPSplitPercent) return;
          const newFobIncrement = Number(state.packageIncrement) * (state.currentPackageAPSplitPercent / 100);
          state.fobPackageIncrement = newFobIncrement.toFixed(3);
        }
      }
      //If draft option is draft
      else if (draftOption === 'Draft') {
        if (changedValue === '') state.draftIncrement = undefined;
        else {
          //set draft increment
          state.draftIncrement = changedValue;

          //set fob draft increment
          if (!state.currentDraftIncrementABSplitPercent) return;
          const newFobIncrement = Number(state.draftIncrement) * (state.currentDraftIncrementABSplitPercent / 100);
          state.fobDraftIncrement = newFobIncrement.toFixed(3);
        }
      }
    },
    handleABSplit: (state, action) => {
      const { draftOption, changedValue } = action.payload;

      //If draft option is package
      if (draftOption === 'Package') {
        if (changedValue === '') state.currentPackageAPSplitPercent = undefined;
        else {
          //update percent
          state.currentPackageAPSplitPercent = Number(changedValue);

          //set increment
          if (!state.packageIncrement) return;
          const newFobIncrement = Number(state.packageIncrement) * (state.currentPackageAPSplitPercent / 100);
          state.fobPackageIncrement = newFobIncrement.toFixed(3);
        }
      }
      //If draft option is draft
      else if (draftOption === 'Draft') {
        if (changedValue === '') state.currentDraftIncrementABSplitPercent = undefined;
        else {
          //update percent
          state.currentDraftIncrementABSplitPercent = Number(changedValue);

          //set increment
          if (!state.draftIncrement) return;
          const newFobIncrement = state.draftIncrement * (state.currentDraftIncrementABSplitPercent / 100);
          state.fobDraftIncrement = newFobIncrement.toFixed(3);
        }
      }
    },
    handleFobIncrement: (state, action) => {
      const { draftOption, changedValue } = action.payload;

      //if draft option is package
      if (draftOption === 'Package') {
        if (changedValue === '') state.fobPackageIncrement = undefined;
        else {
          //set fob
          state.fobPackageIncrement = changedValue;

          //set percent
          if (!state.packageIncrement) return;
          const newPercent = (Number(state.fobPackageIncrement) / Number(state.packageIncrement)) * 100;
          state.currentPackageAPSplitPercent = Number(newPercent.toFixed(3));
        }
      }
      //if draft option is draft
      else if (draftOption === 'Draft') {
        if (changedValue === '') state.fobDraftIncrement = undefined;
        else {
          //set fob
          state.fobDraftIncrement = changedValue;

          //set percent
          if (!state.draftIncrement) return;
          const newPercent = (Number(state.fobDraftIncrement) / state.draftIncrement) * 100;
          state.currentDraftIncrementABSplitPercent = Number(newPercent.toFixed(3));
        }
      }
    },
    handlePtrIncrementEffectiveDate: (state, action) => {
      state.ptrIncrementEffectiveDate = action.payload;
    },
    setCurrentPredomEffectiveDate: (state, action: PayloadAction<string>) => {
      state.currentPredomEffectiveDate = action.payload;
    },
    resetPtrIncrementBuilder: (state) => {
      state.packageIncrement = undefined;
      state.fobPackageIncrement = undefined;
      state.currentPackageAPSplitPercent = 70;
      state.draftIncrement = undefined;
      state.fobDraftIncrement = undefined;
      state.currentDraftIncrementABSplitPercent = 50;

      frontlineDetailSlice.caseReducers.handlePtrIncrementEffectiveDate(state, {
        payload: '',
        type: 'resetPtrIncrementBuilder'
      });
    },
    setSelectedUniqueEffectiveDates: (state, action: PayloadAction<SingleDateSelection[]>) => {
      state.selectedUniqueEffectiveDates = action.payload;
    },
    setODataPayloadModel: (state, action: PayloadAction<callApiPayloadType>) => {
      state.oDataQuery.payloadModel = action.payload;
    },
    setODataQuery: (
      state,
      action: PayloadAction<{ string: oDataProviderQueryString | null; payloadModel: callApiPayloadType }>
    ) => {
      const { string, payloadModel } = action.payload;
      frontlineDetailSlice.caseReducers.setODataQueryString(
        state,
        frontlineDetailSlice.actions.setODataQueryString(string)
      );
      frontlineDetailSlice.caseReducers.setODataPayloadModel(
        state,
        frontlineDetailSlice.actions.setODataPayloadModel(payloadModel)
      );
    },
    setIsPredomValuesApplyButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isPredomValuesApplyButtonDisabled = action.payload;
    }
  }
});

export const {
  setAllUniqueGeographies,
  setAllUniqueBrands,
  setAllUniquePackages,
  setAllUniqueAlcohols,
  setAllUniqueSelections,
  setSelectedUniqueGeographies,
  setSelectedUniqueBrands,
  setSelectedUniquePackages,
  setSelectedUniqueAlcohols,
  setDimensionCriteriaEffectiveDate,
  setFrontlineDetails,
  setActiveTab,
  setPackageIncrement,
  setFobPackageIncrement,
  setFobDraftIncrement,
  setCurrentPackageAPSplitPercent,
  setDraftIncrement,
  setCurrentDraftIncrementABSplitPercent,
  setPtrIncrementEffectiveDate,
  setChangeDateStartEffectiveDate,
  setChangeDateToEffectiveDate,
  setParityBrands,
  setParityPackages,
  setParityAlcohols,
  setCurrentFOB,
  setCurrentRecFLPTR,
  setSelectedUniqueDimensions,
  setFilteredUniqueDimensionsApiArg,
  resetFilteredDimensions,
  setTotalRecords,
  setTotalRecordsFiltered,
  setPtrApplyButtonDisplayed,
  setParityModalOpen,
  setParityBuilderState,
  setODataQueryString,
  setSelectedUniqueDates,
  setRefreshGrid,

  //Ptr Increment
  handlePtrIncrement,
  handleABSplit,
  handleFobIncrement,
  handlePtrIncrementEffectiveDate,
  setCurrentPredomEffectiveDate,
  resetPtrIncrementBuilder,
  setSelectedUniqueEffectiveDates,
  setODataPayloadModel,
  setODataQuery,

  setIsPredomValuesApplyButtonDisabled
} = frontlineDetailSlice.actions;

//Unique Dimensions
export const selectFrontlineDetailState = (state: RootState) => state.frontlineDetail;
export const selectUniqueGeographies = (state: RootState) => {
  return state.frontlineDetail.selectedUniqueGeographies.length > 0
    ? state.frontlineDetail.selectedUniqueGeographies
    : state.frontlineDetail.allUniqueGeographies;
};

export const selectUniqueBrands = (state: RootState) => {
  return state.frontlineDetail.selectedUniqueBrands.length > 0
    ? state.frontlineDetail.selectedUniqueBrands
    : state.frontlineDetail.allUniqueBrands;
};

export const selectUniquePackages = (state: RootState) => {
  return state.frontlineDetail.selectedUniquePackages.length > 0
    ? state.frontlineDetail.selectedUniquePackages
    : state.frontlineDetail.allUniquePackages;
};

export const selectUniqueAlcohols = (state: RootState) => {
  return state.frontlineDetail.selectedUniqueAlcohols.length > 0
    ? state.frontlineDetail.selectedUniqueAlcohols
    : state.frontlineDetail.allUniqueAlcohols;
};

export const selectAllUniqueGeographies = (state: RootState) => state.frontlineDetail.allUniqueGeographies;
export const selectAllUniqueBrands = (state: RootState) => state.frontlineDetail.allUniqueBrands;
export const selectAllUniquePackages = (state: RootState) => state.frontlineDetail.allUniquePackages;
export const selectAllUniqueAlcohols = (state: RootState) => state.frontlineDetail.allUniqueAlcohols;
export const allUniqueEffectiveDates = (state: RootState) => state.frontlineDetail.allUniqueEffectiveDates;

export const selectFrontlineDetails = (state: RootState) => state.frontlineDetail.frontlineDetails;
export const selectActiveTab = (state: RootState) => state.frontlineDetail.activeTab;
export const selectPackageIncrement = (state: RootState) => state.frontlineDetail.packageIncrement;
export const selectCurrentPackageAPSplitPercent = (state: RootState) =>
  state.frontlineDetail.currentPackageAPSplitPercent;
export const selectDraftIncrement = (state: RootState) => state.frontlineDetail.draftIncrement;
export const selectCurrentDraftIncrementABSplitPercent = (state: RootState) =>
  state.frontlineDetail.currentDraftIncrementABSplitPercent;
export const selectDimensionCriteriaEffectiveDate = (state: RootState) =>
  state.frontlineDetail.dimensionCriteriaEffectiveDate;
export const selectPtrIncrementEffectiveDate = (state: RootState) => state.frontlineDetail.ptrIncrementEffectiveDate;
export const selectChangeDateStartEffectiveDate = (state: RootState) => state.frontlineDetail.changeStartEffectiveDate;
export const selectChangeDateToEffectiveDate = (state: RootState) => state.frontlineDetail.changeToEffectiveDate;
export const selectParityBrands = (state: RootState) => state.frontlineDetail.parityBrands;
export const selectParityPackages = (state: RootState) => state.frontlineDetail.parityPackages;
export const selectParityAlcohols = (state: RootState) => state.frontlineDetail.parityAlcohols;
export const selectCurrentFOB = (state: RootState) => state.frontlineDetail.currentFOB;
export const selectCurrentRecFLPTR = (state: RootState) => state.frontlineDetail.currentRecFLPTR;
export const selectFobPackageIncrement = (state: RootState) => state.frontlineDetail.fobPackageIncrement;
export const selectFobDraftIncrement = (state: RootState) => state.frontlineDetail.fobDraftIncrement;

export const selectParityModalOpen = (state: RootState) => state.frontlineDetail.parityModalOpen;

export const selectFilteredUniqueDimensionsApiArg = (state: RootState) =>
  state.frontlineDetail.filteredUniqueDimensionsApiArg;

export const selectAllUniqueDimensions = (state: RootState): AllUniqueDimensions => {
  //Unique Dimensions
  const allUniqueDimensions: AllUniqueDimensions = {
    geographies: state.frontlineDetail.allUniqueGeographies,
    brands: state.frontlineDetail.allUniqueBrands,
    packages: state.frontlineDetail.allUniquePackages,
    alcohols: state.frontlineDetail.allUniqueAlcohols
  };

  return allUniqueDimensions;
};

export const selectPtrApplyButtonDisplayed = (state: RootState) => state.frontlineDetail.ptrApplyButtonDisplayed;
export const selectParityBuilderState = (state: RootState) => state.frontlineDetail.parityBuilderState;

export const selectSelectedEffectiveDates = (state: RootState) => state.frontlineDetail.selectedUniqueEffectiveDates;

export const currentPredomEffectiveDate = (state: RootState) => state.frontlineDetail.currentPredomEffectiveDate;
export const selectODataQueryString = (state: RootState) => state.frontlineDetail.oDataQuery.string;
export const selectODataPayloadModel = (state: RootState) => state.frontlineDetail.oDataQuery.payloadModel;

export const selectRefreshGrid = (state: RootState) => state.frontlineDetail.refreshGrid;

export const selectIsPredomValuesApplyButtonDisabled = (state: RootState) =>
  state.frontlineDetail.isPredomValuesApplyButtonDisabled;

export default frontlineDetailSlice.reducer;
