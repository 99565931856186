import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FlatDiscount,
  GeographyStringKeyValuePair,
  Int64StringKeyValuePair,
  PackageStringKeyValuePair,
  UniqueDimensions
} from '../../../api/priceApi/priceApi';
import { RootState } from '../../../app/store';
import { KeyValue } from '../../../components/DimensionList/types';
import {
  DiscountDetailRow,
  FixedSplitSubTableRow,
  QDSubTableRow,
  VarSplitSubTableRow
} from './types/DiscountDetailRow';
import { IModifiedCode } from './components/modifiedCodes/ModifiedCodeConfigs';
import {
  SplitPercentFromAllowanceAndDiscount,
  SplitPercentFromAllowanceAndDiscountPerLevel
} from './util/DiscountCalculations';

export type PredomValuesType = {
  discount: number;
  allowance: number;
  splitPercent: number;
};
import { sortBy } from 'lodash';
import { ODataPostPayload } from '../../../types/ODataPayload';

export type IDiscountDetailsFilters = {
  filterStartDate: string;
  filterEndDate: string;
  filterDiscountType: string[];
  filterModifiedCode: string[];
  filterConsumptionType: string[];
  filterGeos: GeographyStringKeyValuePair[];
  filterBrands: Int64StringKeyValuePair[];
  filterPkgGrps: PackageStringKeyValuePair[];
  filterAlcoholStrengths: KeyValue[];
  filterWslr: GeographyStringKeyValuePair[];
  filterDiscountStatus: string[];
};

export type BuilderLiteDataType = {
  fixedSplitData?: BuildlerData<FixedSplitSubTableRow> | null;
  varSplitData?: BuildlerData<VarSplitSubTableRow> | null;
  qdData?: BuildlerData<QDSubTableRow> | null;

  predomValuesData?: PredomValuesType | null;
};

export type BuildlerData<T> = {
  discountStructureId: number;
  data: T[];
};

export type DiscountExternalFilterValues = {
  actionCodes: string[];
  discountTypes: string[];
  consumptionTypes: string[];
};

export type IDiscountDetailsStateV2 = {
  pcrSeqId: number;

  mixMatchGroupIDs: number[];

  filterStartDate: string;
  filterEndDate: string;
  filterDiscountType: string[];
  filterModifiedCode: string[];
  filterConsumptionType: string[];

  filterGeos: GeographyStringKeyValuePair[];
  filterBrands: Int64StringKeyValuePair[];
  filterPkgGrps: PackageStringKeyValuePair[];
  filterAlcoholStrengths: KeyValue[];
  filterWslr: GeographyStringKeyValuePair[];
  filterDiscountStatus: string[];

  dimensionData: UniqueDimensions | null;
  externalFilterValues: DiscountExternalFilterValues;

  selectedDiscounts: DiscountDetailRow[];

  // distinct from all selected discounts
  // because you may not have every level of a discount selected
  selectedDiscountLevels: FlatDiscount[];

  builderData: BuilderLiteDataType;

  updateDimensionCriteriaFilters: { update: boolean }; //TODO: What does this do?

  builderDiscountStructure: QDSubTableRow[] | FixedSplitSubTableRow[] | VarSplitSubTableRow[] | null; //TODO: May Need New Types? Can we reuse these typenames?
  dateBuilderValues: DateBuilderValues;
  dataView: 'nested' | 'flat';

  oDataPayload: ODataPostPayload | null;

  sortModel: string;
  builderRunning: boolean;
  dataTableQueryRunning: boolean;
};

export type DateBuilderValues = {
  startDate: string | null;
  endDate: string | null;
  dateType: 'STR' | 'Ship';
};

//InitialState should be the fields for the data that the store is set to receive
const initialState: IDiscountDetailsStateV2 = {
  pcrSeqId: 0,
  mixMatchGroupIDs: [],

  filterStartDate: '',
  filterEndDate: '',
  filterDiscountType: [],
  filterModifiedCode: [],
  filterConsumptionType: [],
  filterGeos: [],
  filterBrands: [],
  filterPkgGrps: [],
  filterAlcoholStrengths: [],
  filterWslr: [],
  filterDiscountStatus: ['A'],

  dimensionData: null,
  externalFilterValues: {
    actionCodes: [],
    discountTypes: [],
    consumptionTypes: []
  },

  selectedDiscounts: [],
  selectedDiscountLevels: [],

  oDataPayload: null,

  builderData: {
    fixedSplitData: null,
    varSplitData: null,
    qdData: null
  },

  updateDimensionCriteriaFilters: { update: false }, // data grid applies dimension criteria filters when eobject updated

  //TODO: Remove
  builderDiscountStructure: null,

  dateBuilderValues: { startDate: null, endDate: null, dateType: 'STR' },
  dataView: 'flat',
  sortModel: 'Date',
  builderRunning: false,
  dataTableQueryRunning: false
};

export const discountDetailsSliceV2 = createSlice({
  name: 'discountDetailsV2',
  initialState,
  reducers: {
    setpcrSeqId: (state, action) => {
      const pcrSeqId = action.payload;

      //If the pcrSeqId is different than the current state, reset the state
      if (pcrSeqId !== 0 && pcrSeqId !== state.pcrSeqId) {
        state.pcrSeqId = pcrSeqId;

        //TODO: Andrew Cudworth suggests refactoring this. I agree.

        //cant reset the whole state it seems, so need to set all the props I want to reset

        state.filterStartDate = initialState.filterStartDate;
        state.filterEndDate = initialState.filterEndDate;
        state.filterDiscountType = initialState.filterDiscountType;
        state.filterModifiedCode = initialState.filterModifiedCode;
        state.filterConsumptionType = initialState.filterConsumptionType;

        state.filterGeos = initialState.filterGeos;
        state.filterBrands = initialState.filterBrands;
        state.filterPkgGrps = initialState.filterPkgGrps;
        state.filterAlcoholStrengths = initialState.filterAlcoholStrengths;
        state.filterWslr = initialState.filterWslr;
        state.filterDiscountStatus = initialState.filterDiscountStatus;

        state.selectedDiscounts = initialState.selectedDiscounts;

        state.updateDimensionCriteriaFilters = initialState.updateDimensionCriteriaFilters;
        state.builderDiscountStructure = initialState.builderDiscountStructure;
        state.dateBuilderValues = initialState.dateBuilderValues;
      }
    },

    setDateBuilderValues: (state, action: PayloadAction<DateBuilderValues>) => {
      state.dateBuilderValues = action.payload;
    },

    updateDimensionCriteriaFilters: (state, action: PayloadAction<IDiscountDetailsFilters>) => {
      //TODO: Andrew Cudworth suggests refactoring this. I agree.
      //Ideal solution: Instead of setting each property, set the whole object to the payload || only set the properties that are in the payload?
      const {
        filterStartDate,
        filterEndDate,
        filterDiscountType,
        filterModifiedCode,
        filterConsumptionType,
        filterGeos,
        filterBrands,
        filterPkgGrps,
        filterAlcoholStrengths,
        filterWslr,
        filterDiscountStatus
      } = action.payload;

      state.filterStartDate = filterStartDate;
      state.filterEndDate = filterEndDate;
      state.filterDiscountType = filterDiscountType;
      state.filterModifiedCode = filterModifiedCode;
      state.filterConsumptionType = filterConsumptionType;
      state.filterGeos = filterGeos;
      state.filterBrands = filterBrands;
      state.filterPkgGrps = filterPkgGrps;
      state.filterAlcoholStrengths = filterAlcoholStrengths;
      state.filterWslr = filterWslr;
      state.filterDiscountStatus = filterDiscountStatus;

      state.updateDimensionCriteriaFilters = { update: true };
    },

    setSelectedDiscounts: (state, action: PayloadAction<FlatDiscount[]>) => {
      state.selectedDiscounts = action.payload;
    },
    setBuilderData: (state, action: PayloadAction<BuilderLiteDataType | null>) => {
      if (action.payload === null) {
        state.builderData.fixedSplitData = null;
        state.builderData.varSplitData = null;
        state.builderData.qdData = null;
        state.builderData.predomValuesData = null;
      } else {
        state.builderData = action.payload;
      }
    },
    compareQDDiscounts: (state, action: PayloadAction<FlatDiscount[]>) => {
      const selectedDiscountLevels = action.payload;

      // group the discountLevels by discountId
      const groupedDiscounts = selectedDiscountLevels.reduce((acc, discount) => {
        const discountId = discount.discountId ?? 0;
        if (!acc[discountId]) {
          acc[discountId] = [];
        }
        acc[discountId].push(discount);
        return acc;
      }, {} as { [key: number]: FlatDiscount[] });

      // sort the grouped discounts by qdMaxQuantity
      Object.values(groupedDiscounts).forEach((groupedDiscount) => {
        groupedDiscount.sort((a, b) => (a.qdMaxQuantity ?? 0) - (b.qdMaxQuantity ?? 0));
      });

      // get first groupedDiscount
      const firstGroupedDiscount = groupedDiscounts[Object.keys(groupedDiscounts)[0] as any]; //Not sure how to get rid of this any

      // compare the grouped discounts
      const allQDDiscountsMatch = Object.values(groupedDiscounts).every((groupedDiscount) => {
        // for each groupedDiscount, compare the values of all of the discounts to firstGroupedDiscount at the same index
        return groupedDiscount.every((discount, index) => {
          const discountsame =
            discount.proposedMaxDiscountAmount === firstGroupedDiscount[index].proposedMaxDiscountAmount;
          const abSplitAmountSame =
            discount.proposedAbSplitAmount === firstGroupedDiscount[index].proposedAbSplitAmount;
          const wslrSplitAmountSame =
            discount.proposedWslrSplitAmount === firstGroupedDiscount[index].proposedWslrSplitAmount;
          const qdMaxQuantitySame = discount.qdMaxQuantity === firstGroupedDiscount[index].qdMaxQuantity;
          const qdMinQuantitySame = discount.qdMinQuantity === firstGroupedDiscount[index].qdMinQuantity;

          return discountsame && abSplitAmountSame && wslrSplitAmountSame && qdMaxQuantitySame && qdMinQuantitySame;
        });
      });

      if (!allQDDiscountsMatch) {
        //reset builderData
        state.builderData.fixedSplitData = null;
        state.builderData.varSplitData = null;
        state.builderData.qdData = null;
        state.builderData.predomValuesData = { discount: 0, allowance: 0, splitPercent: 0 };
      } else {
        // create qdsstructure
        // map to qdsObject
        const qdDiscount: QDSubTableRow[] = firstGroupedDiscount.map((discount) => {
          return {
            proposedDiscount: discount.proposedMaxDiscountAmount ?? 0,
            proposedAbAllowance: discount.proposedAbSplitAmount ?? 0,
            proposedAbSplitPercent: SplitPercentFromAllowanceAndDiscount(
              discount.proposedMaxDiscountAmount ?? 0,
              discount.proposedAbSplitAmount ?? 0
            ), //calculate this
            proposedWslrSplitAmount: discount.proposedWslrSplitAmount ?? 0,
            proposedWslrSplitPercent: SplitPercentFromAllowanceAndDiscount(
              discount.proposedMaxDiscountAmount ?? 0,
              discount.proposedWslrSplitAmount ?? 0
            ), //calculate this

            qDMaxQuantity: discount.qdMaxQuantity ?? 0,
            qDMinQuantity: discount.qdMinQuantity ?? 0,
            dealDtlSeqId: discount.discountDetailId ?? 0,
            varSpltsSeqId: discount.variableSplitId ?? 0
          };
        });

        state.builderData = {
          fixedSplitData: null,
          varSplitData: null,
          qdData: {
            discountStructureId: firstGroupedDiscount[0].discountId ?? 0,
            data: sortBy(qdDiscount, [
              'qDMaxQuantity',
              'qDMinQuantity',
              'proposedDiscount',
              'proposedAbAllowance',
              'proposedAbSplitPercent'
            ])
          }
        };
      }
    },

    compareVarSplitDiscounts: (state, action: PayloadAction<FlatDiscount[]>) => {
      const selectedDiscountLevels = action.payload;
      // group the discounts by discountId
      const groupedDiscounts = selectedDiscountLevels.reduce((acc, discount) => {
        const discountId = discount.discountId ?? 0;
        if (!acc[discountId]) {
          acc[discountId] = [];
        }
        acc[discountId].push(discount);
        return acc;
      }, {} as { [key: number]: FlatDiscount[] });

      // sort the grouped discounts by variableSplitToAmount
      Object.values(groupedDiscounts).forEach((groupedDiscount) => {
        groupedDiscount.sort((a, b) => (a.variableSplitToAmount ?? 0) - (b.variableSplitToAmount ?? 0));
      });

      //get first groupedDiscount
      const firstGroupedDiscount = groupedDiscounts[Object.keys(groupedDiscounts)[0] as any]; //not sure how to get rid of this any

      //compare the grouped discounts
      const allVarSplitDicountsMatch = Object.values(groupedDiscounts).every((groupedDiscount) => {
        // for each groupedDiscount, compare the values of all of the discounts to firstGroupedDiscount at the same index
        return groupedDiscount.every((discount, index) => {
          return (
            discount.proposedMaxDiscountAmount === firstGroupedDiscount[index].proposedMaxDiscountAmount &&
            discount.proposedAbSplitAmount === firstGroupedDiscount[index].proposedAbSplitAmount &&
            discount.proposedWslrSplitAmount === firstGroupedDiscount[index].proposedWslrSplitAmount &&
            discount.variableSplitFromAmount === firstGroupedDiscount[index].variableSplitFromAmount &&
            discount.variableSplitToAmount === firstGroupedDiscount[index].variableSplitToAmount
          );
        });
      });

      if (!allVarSplitDicountsMatch) {
        //reset builderData
        state.builderData.fixedSplitData = null;
        state.builderData.varSplitData = null;
        state.builderData.qdData = null;
        state.builderData.predomValuesData = null;
      } else {
        //map to VarSplitSubTableRow array
        const variableSplitDiscount: VarSplitSubTableRow[] = firstGroupedDiscount.map((discount) => {
          return {
            proposedDiscount: discount.proposedMaxDiscountAmount ?? 0,
            proposedAbAllowance: discount.proposedAbSplitAmount ?? 0,
            proposedAbSplitPercent: SplitPercentFromAllowanceAndDiscountPerLevel(
              discount.variableSplitFromAmount ?? 0,
              discount.variableSplitToAmount ?? 0,
              discount.proposedAbSplitAmount ?? 0
            ), //calculate this
            proposedWslrSplitAmount: discount.proposedWslrSplitAmount ?? 0,
            proposedWslrSplitPercent: SplitPercentFromAllowanceAndDiscountPerLevel(
              discount.variableSplitFromAmount ?? 0,
              discount.variableSplitToAmount ?? 0,
              discount.proposedWslrSplitAmount ?? 0
            ), //calculate this
            varSpltsSeqId: discount.variableSplitId ?? 0,
            dealDtlSeqId: discount.discountDetailId ?? 0,
            variableSplitFromAmount: discount.variableSplitFromAmount ?? 0,
            variableSplitToAmount: discount.variableSplitToAmount ?? 0
          };
        });

        state.builderData = {
          fixedSplitData: null,
          varSplitData: {
            discountStructureId: firstGroupedDiscount[0].discountId ?? 0,
            data: sortBy(variableSplitDiscount, 'variableSplitFromAmount')
          },
          qdData: null
        };
        state.builderData.qdData = null;
      }
    },
    compareFixedSplitDiscounts: (state, action: PayloadAction<FlatDiscount[]>) => {
      const selectedDiscountLevels = action.payload;

      //get first selectedDiscount
      const firstSelectedDiscount = selectedDiscountLevels[0];

      //compare firstSelectedDiscount to all other selectedDiscounts
      const allDiscountsMatch = selectedDiscountLevels.every((discount) => {
        return (
          discount.proposedMaxDiscountAmount === firstSelectedDiscount.proposedMaxDiscountAmount &&
          discount.proposedAbSplitAmount === firstSelectedDiscount.proposedAbSplitAmount &&
          discount.proposedWslrSplitAmount === firstSelectedDiscount.proposedWslrSplitAmount
        );
      });

      if (!allDiscountsMatch) {
        state.builderData.fixedSplitData = null;
        state.builderData.varSplitData = null;
        state.builderData.qdData = null;

        // If the discounts don't match we can show the predominvate values builder
        state.builderData.predomValuesData = { discount: 0, allowance: 0, splitPercent: 0 };
      } else {
        // FIXME:
        //create fixedSplit object
        const fixedSplitDiscount: FixedSplitSubTableRow = {
          proposedDiscount: firstSelectedDiscount.proposedMaxDiscountAmount ?? 0,
          proposedAbAllowance: firstSelectedDiscount.proposedAbSplitAmount ?? 0,
          proposedAbSplitPercent: SplitPercentFromAllowanceAndDiscount(
            firstSelectedDiscount.proposedMaxDiscountAmount ?? 0,
            firstSelectedDiscount.proposedAbSplitAmount ?? 0
          ), //calculate this
          proposedWslrSplitAmount: firstSelectedDiscount.proposedWslrSplitAmount ?? 0,
          proposedWslrSplitPercent: SplitPercentFromAllowanceAndDiscount(
            firstSelectedDiscount.proposedMaxDiscountAmount ?? 0,
            firstSelectedDiscount.proposedWslrSplitAmount ?? 0
          ), //calculate this, //calculate this
          varSpltsSeqId: firstSelectedDiscount.variableSplitId ?? 0,
          dealDtlSeqId: firstSelectedDiscount.discountDetailId ?? 0
        };

        const discountStructure = [fixedSplitDiscount];

        const fixedSplitData = {
          discountStructureId: firstSelectedDiscount.discountId ?? 0,
          data: discountStructure
        };

        state.builderData.fixedSplitData = fixedSplitData;
        state.builderData.varSplitData = null;
        state.builderData.qdData = null;
      }
    },
    setSortModel: (state, action: PayloadAction<string>) => {
      state.sortModel = action.payload;
    },
    setFilterStore: (state, action: PayloadAction<{ field: string; values: any[] }>) => {
      //This gets used to control external filterings when ag-grid filters are changed
      const { field, values } = action.payload;

      if (state.dimensionData === null) return;

      if (field === 'discountType') {
        state.filterDiscountType = values;
      } else if (field === 'actionCode') {
        state.filterModifiedCode = values;
      } else if (field === 'consumptionType') {
        state.filterConsumptionType = values;
      } else if (field === 'geographies') {
        //set filterGeos to only the geos from dimensionData that have the values from the filter
        state.filterGeos =
          state.dimensionData.geographies?.filter((geo) => values.includes(geo.key?.wslrCustGrpSeqId?.toString())) ||
          [];
      } else if (field === 'wslrNumber') {
        //set filterGeos to only the geos from dimensionData that have the values from the filter
        state.filterWslr =
          state.dimensionData.geographies?.filter((geo) => values.includes(geo.key?.wslrNumber?.toString())) || [];
      } else if (field === 'brandName') {
        //set filterBrands to only the brands from dimensionData that have the values from the filter
        state.filterBrands = state.dimensionData.brands?.filter((brand) => values.includes(brand.value)) || [];
      } else if (field === 'packageDescription') {
        //set filterPkgGrps to only the pkgGrps from dimensionData that have the values from the filter
        state.filterPkgGrps = state.dimensionData.packages?.filter((pkgGrp) => values.includes(pkgGrp.value)) || [];
      } else if (field === 'alcoholStrengths') {
        //set filterAlcoholStrengths to only the alcoholStrengths from dimensionData that have the values from the filter. Convert from string to KeyValue
        state.filterAlcoholStrengths =
          state.dimensionData.alcoholStrengths
            ?.filter((alcoholStrength) => values.includes(alcoholStrength))
            ?.map((alcoholStrength) => {
              return { key: alcoholStrength, value: alcoholStrength } as KeyValue;
            }) || [];
      } else if (field === 'statusCode') {
        state.filterDiscountStatus = values;
      }
    },
    setDimensionData: (state, action: PayloadAction<UniqueDimensions>) => {
      state.dimensionData = action.payload;
    },
    setExternalFilterValues: (state, action: PayloadAction<DiscountExternalFilterValues>) => {
      state.externalFilterValues = action.payload;
    },

    setODataPayload: (state, action: PayloadAction<ODataPostPayload>) => {
      state.oDataPayload = action.payload;
    },
    setBuilderRunning: (state, action: PayloadAction<boolean>) => {
      state.builderRunning = action.payload;
    },
    setSelectedLevels: (state, action: PayloadAction<FlatDiscount[]>) => {
      state.selectedDiscountLevels = action.payload;
    },
    setDataTableQueryRunning: (state, action: PayloadAction<boolean>) => {
      state.dataTableQueryRunning = action.payload;
    },
    setFixedSplitData: (state, action: PayloadAction<BuildlerData<FixedSplitSubTableRow>>) => {
      state.builderData.fixedSplitData = action.payload;
      state.builderData.varSplitData = null;
      state.builderData.qdData = null;
    },
    setVariableSplitData: (state, action: PayloadAction<BuildlerData<VarSplitSubTableRow>>) => {
      state.builderData.fixedSplitData = null;
      state.builderData.varSplitData = action.payload;
      state.builderData.qdData = null;
    },
    setQDData: (state, action: PayloadAction<BuildlerData<QDSubTableRow>>) => {
      state.builderData.fixedSplitData = null;
      state.builderData.varSplitData = null;
      state.builderData.qdData = action.payload;
    },
    setPredomValuesData: (state, action: PayloadAction<PredomValuesType>) => {
      const { discount, allowance, splitPercent } = action.payload;
      state.builderData.fixedSplitData = null;
      state.builderData.varSplitData = null;
      state.builderData.qdData = null;
      state.builderData.predomValuesData = { discount, allowance, splitPercent };
    },
    setDefaultBuilder: (state) => {
      state.builderData.fixedSplitData = null;
      state.builderData.varSplitData = null;
      state.builderData.qdData = null;
      state.builderData.predomValuesData = null;
    },
    setFilterStartDate: (state, action: PayloadAction<string>) => {
      state.filterStartDate = action.payload;
    },
    setFilterEndDate: (state, action: PayloadAction<string>) => {
      state.filterEndDate = action.payload;
    },
    setDefaultDates: (state) => {
      state.filterStartDate = initialState.filterStartDate;
      state.filterEndDate = initialState.filterEndDate;
    },
    setStartAndEndDates: (
      state,
      action: PayloadAction<{ filterStartDate: string | null | undefined; filterEndDate: string | null | undefined }>
    ) => {
      const { filterStartDate, filterEndDate } = action.payload;

      state.filterStartDate = filterStartDate ?? '';
      state.filterEndDate = filterEndDate ?? '';
    }
  }
});

export const {
  setpcrSeqId,
  updateDimensionCriteriaFilters,
  setSelectedDiscounts,
  setBuilderData,
  compareQDDiscounts,
  compareVarSplitDiscounts,
  compareFixedSplitDiscounts,
  setDateBuilderValues,
  setSortModel,
  setDimensionData,
  setFilterStore,
  setExternalFilterValues,
  setODataPayload,
  setBuilderRunning,
  setSelectedLevels,
  setDataTableQueryRunning,

  //set builder
  setFixedSplitData,
  setVariableSplitData,
  setQDData,
  setPredomValuesData,
  setDefaultBuilder,

  //set dates
  setFilterStartDate,
  setFilterEndDate,
  setDefaultDates,
  setStartAndEndDates
} = discountDetailsSliceV2.actions;
//selectors allow us to read state I.E being able to load data to the grid via rowData
//TODO replace with destructuring?
export const selectAllDiscountDetailsState = (state: RootState) => state.discountDetailsV2;

export const selectFilterDiscountType = (state: RootState) => state.discountDetailsV2.filterDiscountType;
export const selectFilterModifiedCode = (state: RootState) => state.discountDetailsV2.filterModifiedCode;
export const selectFilterConsumptionType = (state: RootState) => state.discountDetailsV2.filterConsumptionType;
export const selectFilterStartDate = (state: RootState) => state.discountDetailsV2.filterStartDate;
export const selectFilterEndDate = (state: RootState) => state.discountDetailsV2.filterEndDate;
export const selectUpdateDimensionCriteriaFilters = (state: RootState) =>
  state.discountDetailsV2.updateDimensionCriteriaFilters;

export const selectDateBuilderValues = (state: RootState) => state.discountDetailsV2.dateBuilderValues;

export const selectBuilderData = (state: RootState) => state.discountDetailsV2.builderData;

export const selectSelectedDiscounts = (state: RootState) => state.discountDetailsV2.selectedDiscounts;
export const selectSelectedLevels = (state: RootState) => state.discountDetailsV2.selectedDiscountLevels;

export const selectDataView = (state: RootState) => state.discountDetailsV2.dataView;

export const selectExternalFilterValues = (state: RootState) => state.discountDetailsV2.externalFilterValues;

export const selectODataPayload = (state: RootState) => state.discountDetailsV2.oDataPayload;

export const selectBuilderRunning = (state: RootState) => state.discountDetailsV2.builderRunning;
export const selectDataTableQueryRunning = (state: RootState) => state.discountDetailsV2.dataTableQueryRunning;

export const selectDiscountDetailFilterArgs = (state: RootState): IDiscountDetailsFilters => {
  const {
    filterStartDate,
    filterEndDate,
    filterDiscountType,
    filterModifiedCode,
    filterConsumptionType,
    filterGeos,
    filterBrands,
    filterPkgGrps,
    filterAlcoholStrengths,
    filterWslr,
    filterDiscountStatus
  } = state.discountDetailsV2;

  return {
    filterStartDate,
    filterEndDate,
    filterDiscountType,
    filterModifiedCode,
    filterConsumptionType,
    filterGeos,
    filterBrands,
    filterPkgGrps,
    filterAlcoholStrengths,
    filterWslr,
    filterDiscountStatus
  };
};

export default discountDetailsSliceV2.reducer;

export const autoUpdateModifiedCode = (discount: DiscountDetailRow): DiscountDetailRow => {
  const changedModifiedCodeList = ['U', 'NC'];
  //if modified code is U or NC, then we need to change the code to C

  if (discount.actionCode && changedModifiedCodeList.includes(discount.actionCode)) {
    discount.actionCode = 'C';
  }

  return discount;
};

export const convertToNotChanged = (record: FlatDiscount): IModifiedCode => {
  // Define a list of comparator functions
  const comparators = [
    () => record.proposedBeginShipmentDate === record.beginShipmentDate,
    () => record.proposedEndShipmentDate === record.endShipmentDate,
    () => record.proposedMaxDiscountAmount === record.currentMaxDiscountAmount,
    () => record.proposedAbSplitAmount === record.currentAbSplitAmount,
    () => record.proposedWslrSplitAmount === record.currentWslrSplitAmount,
    () => record.proposedBeginStrDate === record.beginStrDate,
    () => record.proposedEndStrDate === record.endStrDate
  ];

  if (record.actionCode === 'C') {
    // If any comparator returns false, return the action code
    for (const comparator of comparators) {
      if (!comparator()) {
        return record.actionCode;
      }
    }

    // All comparators returned true, so return 'NC'
    return 'NC' as IModifiedCode;
  } else {
    return record.actionCode as IModifiedCode;
  }
};
