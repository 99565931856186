//Purpose: Handle logging in the user with MSAL when app is loaded initially

// Auth
import { MsalAuthenticationTemplate } from '@FAuth/MsalAuthenticationTemplate';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../configs/authConfig';

// Components
import LoginFail from '@FLogin/LoginFail';
import LoginLoading from '@FLogin/LoginLoading';
import UserData from '@FAuth/UserData';

const AuthLogin = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={LoginFail}
        loadingComponent={LoginLoading}
      >
        <UserData>{children}</UserData>
      </MsalAuthenticationTemplate>
    </>
  );
};

export default AuthLogin;
