//React+Redux
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

//MUI

//APP
import { RootState } from '../../../app/store';

export interface ICreateDiscountBuildersInitialState {
  selectedWslrSpecificAgNodeIds: number[];
}

const initialState: ICreateDiscountBuildersInitialState = {
  selectedWslrSpecificAgNodeIds: []
};

export const CreateDiscountBuildersSlice = createSlice({
  name: 'CreateDiscountBuilders',
  initialState,
  reducers: {
    setSelectedWslrSpecificAgNodeIds: (state, action: PayloadAction<number[]>) => {
      state.selectedWslrSpecificAgNodeIds = action.payload;
    }
  }
});

export const { setSelectedWslrSpecificAgNodeIds } = CreateDiscountBuildersSlice.actions;

export const selectCreateDiscountBuildersState = (state: RootState) => state.CreateDiscountBuilders;

export default CreateDiscountBuildersSlice.reducer;
