import { Box, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import styles from '../../../AdvancedSelector.module.scss';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { selectRecordTypeFilters, setRecordTypeFilters, AdvancedSelectorFeature } from '../../../AdvancedSelectorSlice';

export const discountTypeArray = ['Case One', 'QD NON Mix Match', 'QD Mix Match', 'Rebate'];

export const RecordTypeFilter = ({ featureName }: { featureName: AdvancedSelectorFeature }) => {
  const { discountType } = useAppSelector(selectRecordTypeFilters(featureName));

  const dispatch = useAppDispatch();

  return (
    <Box className={styles.discountTypeFilter}>
      <FormControl sx={{ m: 1 }} className={styles.discountTypeFilter}>
        <InputLabel id="record-type-as-filter-discount-input">Discount Type</InputLabel>
        <Select
          labelId="record-type-as-filter-discount-select-label"
          id="record-type-as-filter-discount-select-id"
          multiple
          value={discountType}
          onChange={(e) => dispatch(setRecordTypeFilters({ type: 'discountType', featureName, value: e.target.value }))}
          input={<OutlinedInput label="Discount Type" />}
        >
          {discountTypeArray.map((discount) => (
            <MenuItem key={`${discount}-record-type-menu-item-key`} value={discount}>
              {discount}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
