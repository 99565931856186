import {
  WholesalerSelection,
  BrandSelection,
  PackageSelection,
  AlchlStrength,
  Geography
} from '../api/priceApi/priceApi';

export const getGeographyDimensions = (selections: WholesalerSelection[]) => {
  return selections.map((geography) => {
    return {
      wslrCustGrpSeqId: Number(geography.wslrCustGrpSeqId),
      wslrNumber: geography.wslrNumber
    } as Geography;
  });
};

export const getBrandDimensions = (selections: BrandSelection[]) => {
  return selections.map((brand) => brand.brandSeqId);
};

export const getPackageDimensions = (selections: PackageSelection[]) => {
  return selections.map((packagePRC) => packagePRC.pkgGrpSeqId);
};

export const getAlcoholDimensions = (selections: AlchlStrength[]) => {
  return selections.map((alcohol) => alcohol.alchlStrengthCd);
};
