//RTK
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  WholesalerSelection,
  BrandSelection,
  PackageSelection,
  AlchlStrength,
  BrandGrp,
  BrandType,
  DraftOpt,
  ConsumptionType,
  CivMil,
  WholesalerGroup,
  LiqTyp
} from '@priceApi/priceApi';
import { RootState } from '@app/store';
import {
  getAlcoholDimensions,
  getBrandDimensions,
  getGeographyDimensions,
  getPackageDimensions
} from '@utils/dimensionFormatters';
import { discountTypeArray } from '@FAdvSelV3/components/filters/RecordTypeFilter/RecordTypeFilter';
import { featureNames } from '@enums/featureNames';
import { orderBy } from 'lodash';

type ISelectorFeatureState = {
  //Dimension that have been made, only used in AdvancedSelector
  currentGeographies: WholesalerSelection[];
  currentBrands: BrandSelection[];
  currentPackages: PackageSelection[];
  currentAlcohols: AlchlStrength[];
  currentSubwholesalers: WholesalerSelection[];

  //Dimension options listed in left transfer list after filters have been applied
  filteredGeographies: WholesalerSelection[];
  filteredBrands: BrandSelection[];
  filteredPackages: PackageSelection[];
  filteredAlcohols: AlchlStrength[];
  filteredSubwholesalers: WholesalerSelection[];

  //Dimension that can be used in the feature that calls the advanced selector
  exposedGeographies: WholesalerSelection[];
  exposedBrands: BrandSelection[];
  exposedPackages: PackageSelection[];
  exposedAlcohols: AlchlStrength[];
  exposedSubwholesalers: WholesalerSelection[];

  //Determines if AS should be rendered
  displayAS: boolean;

  //Determines which filter is being displayed
  activeFilterTab: string;

  //geography filter data
  stateCd: string;
  regionCd?: string;
  marketSpecificityWholesaler: WholesalerSelection[];
  includeInactiveGeographies: boolean;
  includeNonEquity: boolean;
  excludeSpeciality: boolean;
  marketLevel: string;
  allLevels: boolean;
  currentSavedSelection?: WholesalerGroup;
  refetchGeographies: { refetch: boolean };
  initialCurrentGeographiesSet: boolean;

  //brand filter data
  brandFamilyOptions: BrandGrp[];
  brandFamiliesSeqIds: number[];
  brandRights: string[];
  liquidType: string;
  liquidTypeSeqIds: number[];
  onlyVarietyPacks: boolean;
  includeInactiveBrands: boolean;
  brandType?: BrandType;

  //package filter data
  draftOption?: DraftOpt;
  includeInactivePackages: boolean;

  //record type filter data
  discountType: string[];
  recordType: string[];

  //append filters: ConsumptionType, Military
  consumptionType: ConsumptionType[];
  civMil: CivMil[];
  includeMilitaryRetailerAttribute: boolean;

  //object to track if selections were applied
  selectionsApplied: { selectionsApplied: boolean };

  //object to track if no discounts were found
  noDiscountsFoundOpen: boolean;

  //object to track if discounts should be refetched
  refetchDiscounts: { refetch: boolean };

  //polling interval for getActivityById
  getActivityByIdPollingInterval?: number;

  //feature specific data
  featureName: string;

  //date range state
  startFilterText: string;
  endFilterText: string;
};

const initialSelectorState: ISelectorFeatureState = {
  //Dimension that have been made, only used in AdvancedSelector
  currentGeographies: [],
  currentBrands: [],
  currentPackages: [],
  currentAlcohols: [],
  currentSubwholesalers: [],

  //Dimension options listed in left transfer list after filters have been applied
  filteredGeographies: [],
  filteredBrands: [],
  filteredPackages: [],
  filteredAlcohols: [],
  filteredSubwholesalers: [],

  //Dimension that can be used in the feature that calls the advanced selector
  exposedGeographies: [],
  exposedBrands: [],
  exposedPackages: [],
  exposedAlcohols: [],
  exposedSubwholesalers: [],

  //Determines if AS should be rendered
  displayAS: false,

  //Determines which filter is being displayed
  activeFilterTab: 'geography',

  //geography filter data
  stateCd: 'AK',
  regionCd: '',
  marketSpecificityWholesaler: [],
  includeInactiveGeographies: false,
  includeNonEquity: true,
  excludeSpeciality: true,
  marketLevel: 'GM',
  allLevels: false,
  currentSavedSelection: undefined,
  refetchGeographies: { refetch: true },
  initialCurrentGeographiesSet: false,

  //brand filter data
  brandFamilyOptions: [],
  brandFamiliesSeqIds: [],
  brandRights: [],
  liquidType: 'No Liquid Type',
  liquidTypeSeqIds: [],
  onlyVarietyPacks: false,
  includeInactiveBrands: false,
  brandType: undefined,

  //package filter data
  draftOption: undefined,
  includeInactivePackages: true,

  //record type filter data
  discountType: discountTypeArray.filter((discountType) => discountType !== 'Rebate'),
  recordType: [],

  //append filters: ConsumptioNType, Civ/Military
  consumptionType: ['Combined', 'OnPremise', 'OffPremise'],
  civMil: ['Civilian', 'CivMil'],
  includeMilitaryRetailerAttribute: false,

  //object to track if selections were applied
  selectionsApplied: { selectionsApplied: false },

  //object to track if no discounts were found
  noDiscountsFoundOpen: false,

  //object to track if discounts should be refetched
  refetchDiscounts: { refetch: false },

  //polling interval for getActivityById
  getActivityByIdPollingInterval: undefined,

  //feature specific data
  featureName: '',

  //date range state
  startFilterText: '',
  endFilterText: ''
};

export type AdvancedSelectorFeature =
  | 'calendarRollover'
  | 'createDiscount'
  | 'discountDetails'
  | 'fobAdjustment'
  | 'frontlineDetails'
  | 'nationalFOBDelete'
  | 'massCopyFrom'
  | 'massCopyTo'
  | 'newBrandPackage'
  | 'planDetails';

type ASInitialState = {
  calendarRollover: ISelectorFeatureState;
  createDiscount: ISelectorFeatureState;
  discountDetails: ISelectorFeatureState;
  fobAdjustment: ISelectorFeatureState;
  frontlineDetails: ISelectorFeatureState;
  nationalFOBDelete: ISelectorFeatureState;
  massCopyFrom: ISelectorFeatureState;
  massCopyTo: ISelectorFeatureState;
  newBrandPackage: ISelectorFeatureState;
  planDetails: ISelectorFeatureState;

  allBrands: BrandSelection[];
  allPackages: PackageSelection[];
  allActivePackages: PackageSelection[];
  allAlcohols: AlchlStrength[];
  allGeoStates: [];
  allLiquidTypes: LiqTyp[];
  allBrandFamilies: BrandGrp[];
  allSavedSelections: WholesalerGroup[];

  //pcr information
  planOrPcrSeqId?: number;
};

const initialState: ASInitialState = {
  //feature state
  calendarRollover: initialSelectorState,
  createDiscount: initialSelectorState,
  discountDetails: initialSelectorState,
  fobAdjustment: initialSelectorState,
  frontlineDetails: initialSelectorState,
  nationalFOBDelete: initialSelectorState,
  massCopyFrom: initialSelectorState,
  massCopyTo: initialSelectorState,
  newBrandPackage: initialSelectorState,
  planDetails: initialSelectorState,

  //all data
  allBrands: [],
  allPackages: [],
  allActivePackages: [],
  allAlcohols: [],
  allGeoStates: [],
  allLiquidTypes: [],
  allBrandFamilies: [],
  allSavedSelections: [],

  //pcr information
  planOrPcrSeqId: undefined
};

export const advancedSelectorSlice = createSlice({
  name: 'advancedSelector',
  initialState: initialState,
  reducers: {
    toggleDisplayAS: (state, action) => {
      const featureName: AdvancedSelectorFeature = action.payload;
      state[featureName].displayAS = !state[featureName].displayAS;
    },
    resetExposedPackages: (state, action) => {
      const featureName: AdvancedSelectorFeature = action.payload;
      state[featureName].exposedPackages = initialState[featureName].exposedPackages;
    },
    resetExposed: (state, action) => {
      const featureName: AdvancedSelectorFeature = action.payload;
      state[featureName].exposedGeographies = initialState[featureName].exposedGeographies;
      state[featureName].exposedBrands = initialState[featureName].exposedBrands;
      advancedSelectorSlice.caseReducers.resetExposedPackages(state, action);
      state[featureName].exposedAlcohols = initialState[featureName].exposedAlcohols;
      state[featureName].exposedSubwholesalers = initialState[featureName].exposedSubwholesalers;
    },
    resetCurrent: (state, action) => {
      const featureName: AdvancedSelectorFeature = action.payload;
      state[featureName].currentGeographies = initialState[featureName].currentGeographies;
      state[featureName].currentBrands = initialState[featureName].currentBrands;
      state[featureName].currentPackages = initialState[featureName].currentPackages;
      state[featureName].currentAlcohols = initialState[featureName].currentAlcohols;
      state[featureName].currentSubwholesalers = initialState[featureName].currentSubwholesalers;
    },
    resetCurrentAndExposed: (state, action) => {
      advancedSelectorSlice.caseReducers.resetCurrent(state, action);
      advancedSelectorSlice.caseReducers.resetExposed(state, action);
    },
    handleSaveAndClose: (state, action) => {
      const featureName: AdvancedSelectorFeature = action.payload;

      const { currentGeographies, currentBrands, currentPackages, currentAlcohols, currentSubwholesalers } =
        state[featureName];

      //set exposed dimensions to current dimensions
      state[featureName].exposedGeographies = currentGeographies;
      state[featureName].exposedBrands = currentBrands;
      state[featureName].exposedPackages = currentPackages;
      state[featureName].exposedAlcohols = currentAlcohols;
      state[featureName].exposedSubwholesalers = currentSubwholesalers;

      if (featureName === ('massCopyFrom' as AdvancedSelectorFeature)) {
        const { massCopyTo } = state;

        //Persist the MarketLevel
        massCopyTo.marketLevel = state[featureName].marketLevel;
        //Set initial current geography to true
        massCopyTo.initialCurrentGeographiesSet = true;

        if (currentGeographies.length >= 1) {
          massCopyTo.exposedGeographies = currentGeographies;
          massCopyTo.currentGeographies = currentGeographies;
        }

        if (currentBrands.length >= 1) {
          massCopyTo.exposedBrands = currentBrands;
          massCopyTo.currentBrands = currentBrands;
        }
        if (currentPackages.length >= 1) {
          massCopyTo.exposedPackages = currentPackages;
          massCopyTo.currentPackages = currentPackages;
        }
        if (currentAlcohols.length === 1) {
          massCopyTo.exposedAlcohols = state.allAlcohols;
          massCopyTo.currentAlcohols = state.allAlcohols;
        }
        if (currentAlcohols.length > 1) {
          massCopyTo.exposedAlcohols = currentAlcohols;
          massCopyTo.currentAlcohols = currentAlcohols;
        }
        if (currentSubwholesalers.length >= 1) {
          massCopyTo.exposedSubwholesalers = currentSubwholesalers;
          massCopyTo.currentSubwholesalers = currentSubwholesalers;
        }
      }
      //close advanced selector
      state[featureName].displayAS = false;
    },
    handleStartDateChange: (state, action) => {
      const { date, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].startFilterText = date;
    },
    handleEndDateChange: (state, action) => {
      const { date, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].endFilterText = date;
    },
    resetStartDate: (state, action: PayloadAction<AdvancedSelectorFeature>) => {
      state[action.payload as AdvancedSelectorFeature].startFilterText =
        initialState[action.payload as AdvancedSelectorFeature].startFilterText;
    },
    resetEndDate: (state, action: PayloadAction<AdvancedSelectorFeature>) => {
      state[action.payload as AdvancedSelectorFeature].endFilterText =
        initialState[action.payload as AdvancedSelectorFeature].endFilterText;
    },

    resetDates: (state, action: PayloadAction<AdvancedSelectorFeature>) => {
      //Calling the actions with the payload being the feature name.
      const resetStart = advancedSelectorSlice.actions.resetEndDate(action.payload);
      const resetEnd = advancedSelectorSlice.actions.resetStartDate(action.payload);

      //Calling the reducers
      advancedSelectorSlice.caseReducers.resetEndDate(state, resetStart);
      advancedSelectorSlice.caseReducers.resetStartDate(state, resetEnd);
    },
    setMarketLevel: (state, action) => {
      const { newMarketLevel, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].marketLevel = newMarketLevel;
    },
    toggleAllLevelsCheckbox: (state, action) => {
      const { featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].allLevels =
        !state[featureName as AdvancedSelectorFeature].allLevels;
    },
    clearMarketSpecificityWholesaler: (state, action) => {
      const { featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].marketSpecificityWholesaler = [];
    },
    setBrandType: (state, action) => {
      const { brandType, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].brandType = brandType;
    },
    setFilteredSelections: (state, action) => {
      const { selections, header, featureName } = action.payload;
      switch (header) {
        case 'geographies':
          state[featureName as AdvancedSelectorFeature].filteredGeographies = selections;
          break;
        case 'brands':
          state[featureName as AdvancedSelectorFeature].filteredBrands = selections;
          break;
        case 'alcohols':
          state[featureName as AdvancedSelectorFeature].filteredAlcohols = selections;
          break;
        case 'subwholesalers':
          state[featureName as AdvancedSelectorFeature].filteredSubwholesalers = selections;

          break;
      }
    },
    setBrandFamilySeqIds: (state, action) => {
      const { brandFamilySeqIds, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].brandFamiliesSeqIds = brandFamilySeqIds;
    },
    setBrandFamilyOptions: (state, action) => {
      const { brandFamilyOptions, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].brandFamilyOptions = brandFamilyOptions;
    },
    setBrandFilterCheckbox: (state, action) => {
      const { checkbox, featureName } = action.payload;
      if (checkbox === 'varietyPack')
        state[featureName as AdvancedSelectorFeature].onlyVarietyPacks =
          !state[featureName as AdvancedSelectorFeature].onlyVarietyPacks;
      else if (checkbox === 'inactiveBrands')
        state[featureName as AdvancedSelectorFeature].includeInactiveBrands =
          !state[featureName as AdvancedSelectorFeature].includeInactiveBrands;
    },
    setLiquidType: (state, action) => {
      const { liquidType, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].liquidType = liquidType;
    },
    setLiquidTypeSeqIds: (state, action) => {
      const { liquidTypeSeqIds, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].liquidTypeSeqIds = liquidTypeSeqIds;
    },
    setIncludeInactivePackages: (state, action) => {
      const { includeInactivePackages, featureName } = action.payload;
      const { draftOption } = state[featureName as AdvancedSelectorFeature];
      const packagesToUse = includeInactivePackages ? state.allPackages : state.allActivePackages;

      /*
          Toggle include inactive packages
          */
      state[featureName as AdvancedSelectorFeature].includeInactivePackages = includeInactivePackages;

      /* 
          Update filtered packages
          */

      //if draft option was package or draft
      if (draftOption) {
        state[featureName as AdvancedSelectorFeature].filteredPackages = packagesToUse.filter((container) => {
          //filter by draft option and include inactive
          return container.draftOption === draftOption;
        });
      }
      // if draft option is undefined ('Both' in the UI)
      else if (!draftOption) {
        state[featureName as AdvancedSelectorFeature].filteredPackages = packagesToUse;
      }
    },
    setDraftOption: (
      state,
      action: PayloadAction<{ draftOption?: DraftOpt; featureName: AdvancedSelectorFeature }>
    ) => {
      const { draftOption, featureName } = action.payload;
      const { includeInactivePackages } = state[featureName as AdvancedSelectorFeature];
      const packagesToUse = includeInactivePackages ? state.allPackages : state.allActivePackages;

      /*
          Set new draft option
          */
      state[featureName as AdvancedSelectorFeature].draftOption = draftOption;

      /* 
          Update filtered packages
          */
      //if draft option was package or draft
      if (draftOption) {
        state[featureName as AdvancedSelectorFeature].filteredPackages = packagesToUse.filter((container) => {
          //filter by draft option and include inactive
          return container.draftOption === draftOption;
        });
      }
      // if draft option is undefined ('Both' in the UI)
      else if (draftOption === undefined) {
        state[featureName as AdvancedSelectorFeature].filteredPackages = packagesToUse;
      }
    },
    setRecordTypeFilters: (state, action) => {
      const { type, value, featureName } = action.payload;

      //update discount type
      if (type === 'discountType') {
        state[featureName as AdvancedSelectorFeature].discountType =
          typeof value === 'string' ? value.split(',') : value;
      }
      //or update record type
      else if (type === 'recordType') {
        state[featureName as AdvancedSelectorFeature].recordType = value;
      }
    },
    setConsumptionTypeCheckbox: (state, action) => {
      //Determine which checkbox was clicked.
      //Add or filter the value from the array.
      const { checkbox, featureName } = action.payload;

      //if the checkbox value is already in the array, remove it.
      if (state[featureName as AdvancedSelectorFeature].consumptionType.includes(checkbox)) {
        state[featureName as AdvancedSelectorFeature].consumptionType = state[
          featureName as AdvancedSelectorFeature
        ].consumptionType.filter((type) => type !== checkbox);
      }
      //else add it.
      else {
        state[featureName as AdvancedSelectorFeature].consumptionType = [
          ...state[featureName as AdvancedSelectorFeature].consumptionType,
          checkbox
        ];
      }
    },
    setCivMilFiltersCheckbox: (state, action) => {
      const featureName = action.payload as AdvancedSelectorFeature;
      // If civMil does not have "Military" then add it. Also remove other values.
      if (!state[featureName].civMil.includes('Military')) {
        state[featureName].civMil = ['Military'];
        state[featureName].includeMilitaryRetailerAttribute = true;
      }
      //If civMil includes "Military" then remove it. Add "Civilian" and "CivMil" to the array.
      else {
        state[featureName].civMil = ['Civilian', 'CivMil'];
        state[featureName].includeMilitaryRetailerAttribute = false;
      }
    },
    setCurrentSelections: (state, action) => {
      const { selections, header, featureName } = action.payload;
      switch (header) {
        case 'geographies':
          state[featureName as AdvancedSelectorFeature].currentGeographies = selections;
          state[featureName as AdvancedSelectorFeature].initialCurrentGeographiesSet = true;
          break;
        case 'brands':
          state[featureName as AdvancedSelectorFeature].currentBrands = selections;
          break;
        case 'packages':
          state[featureName as AdvancedSelectorFeature].currentPackages = selections;
          break;
        case 'alcohols':
          state[featureName as AdvancedSelectorFeature].currentAlcohols = selections;
          break;
        case 'subwholesalers':
          state[featureName as AdvancedSelectorFeature].currentSubwholesalers = selections;

          break;
      }
    },
    handleBrandFilters: (state, action) => {
      // const {filter, value} = action.payload;
    },
    setActiveFilterTab: (state, action) => {
      const { newTab, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].activeFilterTab = newTab;
    },
    setFetchASData: (state, action) => {
      const { type, data } = action.payload;
      switch (type) {
        case 'brands':
          state.allBrands = data;
          break;
        case 'packages':
          state.allPackages = data;
          state.allActivePackages = data.filter((container: any) => {
            //only filter by include inactive
            return container.active === true;
          });
          break;
        case 'alcohols':
          state.allAlcohols = data;
          break;
        case 'geoStates':
          state.allGeoStates = data;
          break;
        case 'liquidTypes':
          state.allLiquidTypes = [{ liquidTyp: 'No Liquid Type' }, ...data];
          break;
        case 'brandFamilies':
          state.allBrandFamilies = orderBy(data, ['name']);
          break;
        case 'geoSavedSelections':
          state.allSavedSelections = data;
          break;
      }
    },
    applyFetchASData: (state, action) => {
      const { type, featureName } = action.payload;
      switch (type) {
        case 'brands':
          state[featureName as AdvancedSelectorFeature].currentBrands = state.allBrands;
          break;
        case 'packages':
          state[featureName as AdvancedSelectorFeature].currentPackages = state.allPackages;
          break;
        case 'alcohols':
          state[featureName as AdvancedSelectorFeature].currentAlcohols = state.allAlcohols;
          break;
      }
    },
    setStateCd: (state, action) => {
      const { stateCd, featureName } = action.payload;
      state[featureName as AdvancedSelectorFeature].stateCd = stateCd;
    },
    setPlanOrPcrSeqId: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      state.planOrPcrSeqId = id;
    },
    setRefetchDiscounts: (state, action: PayloadAction<{ refetch: boolean; featureName: featureNames }>) => {
      const { featureName, refetch } = action.payload;
      state[featureName as AdvancedSelectorFeature].refetchDiscounts = { refetch };
    },
    setNoDiscountsFoundModalOpen: (state, action) => {
      const { featureName, open } = action.payload;

      state[featureName as AdvancedSelectorFeature].noDiscountsFoundOpen = open;

      //Persisting MassCopy From
      state.massCopyFrom.exposedGeographies = state.discountDetails.currentGeographies;
      state.massCopyFrom.exposedBrands = state.discountDetails.currentBrands;
      state.massCopyFrom.exposedPackages = state.discountDetails.currentPackages;
      state.massCopyFrom.exposedAlcohols = state.discountDetails.currentAlcohols;
      state.massCopyFrom.exposedSubwholesalers = state.discountDetails.currentSubwholesalers;
      state.massCopyFrom.marketLevel = state.discountDetails.marketLevel;
      state.massCopyFrom.exposedGeographies = state.discountDetails.currentGeographies;

      state.massCopyFrom.currentBrands = state.discountDetails.currentBrands;
      state.massCopyFrom.currentPackages = state.discountDetails.currentPackages;
      state.massCopyFrom.currentAlcohols = state.discountDetails.currentAlcohols;
      state.massCopyFrom.currentSubwholesalers = state.discountDetails.currentSubwholesalers;

      //Persisting MassCopy To
      state.massCopyTo.exposedGeographies = state.discountDetails.currentGeographies;
      state.massCopyTo.exposedBrands = state.discountDetails.currentBrands;
      state.massCopyTo.exposedPackages = state.discountDetails.currentPackages;
      state.massCopyTo.exposedAlcohols = state.discountDetails.currentAlcohols;
      state.massCopyTo.exposedSubwholesalers = state.discountDetails.currentSubwholesalers;
      state.massCopyTo.marketLevel = state.discountDetails.marketLevel;

      state.massCopyTo.currentGeographies = state.discountDetails.currentGeographies;
      state.massCopyTo.currentBrands = state.discountDetails.currentBrands;
      state.massCopyTo.currentPackages = state.discountDetails.currentPackages;
      state.massCopyTo.currentAlcohols = state.discountDetails.currentAlcohols;
      state.massCopyTo.currentSubwholesalers = state.discountDetails.currentSubwholesalers;
    },
    setCreateDiscountExposed: (state, action) => {
      const { searchAndAddSelections } = action.payload;
      state.createDiscount.exposedGeographies = searchAndAddSelections.geographies;
      state.createDiscount.exposedBrands = searchAndAddSelections.brands;
      state.createDiscount.exposedPackages = searchAndAddSelections.packages;
      state.createDiscount.exposedAlcohols = searchAndAddSelections.alcohols;
      state.createDiscount.exposedSubwholesalers = searchAndAddSelections.subwholesalers;
    },
    setCurrentSavedSelection: (state, action) => {
      const { featureName, geo } = action.payload;
      state[featureName as AdvancedSelectorFeature].currentSavedSelection = geo;
      state[featureName as AdvancedSelectorFeature].refetchGeographies = geo ? { refetch: false } : { refetch: true };
    },
    setGeographyFilter: (state, action) => {
      const { featureName, filter, value } = action.payload;
      const feature: AdvancedSelectorFeature = featureName;
      switch (filter) {
        case 'MARKET_LEVEL':
          state[feature].marketLevel = value;
          break;
        case 'WHOLESALER':
          if (value.displayName === 'ALL WHOLESALERS') {
            state[feature].marketSpecificityWholesaler = [{ displayName: 'ALL WHOLESALERS' }];
          } else state[feature].marketSpecificityWholesaler = [value];
          break;
        case 'STATE':
          state[feature].stateCd = value;
          break;
        case 'INCLUDE_INACTIVE':
          state[feature].includeInactiveGeographies = value;
          break;
        case 'EXCLUDE_SPECIALTY':
          state[feature].excludeSpeciality = value;
          break;
        case 'INCLUDE_NON_EQUITY':
          state[feature].includeNonEquity = value;
          break;
      }
      state[feature].refetchGeographies = { refetch: true };
    },
    clearFetchedData: (state, action) => {
      state.allBrands = [];
      state.allPackages = [];
      state.allAlcohols = [];
      state.allGeoStates = [];
      state.allLiquidTypes = [];
      state.allBrandFamilies = [];
      state.allSavedSelections = [];
    },
    resetSandboxFeatureState: (state, action) => {
      state.createDiscount = initialSelectorState;
      state.discountDetails = initialSelectorState;
      state.fobAdjustment = initialSelectorState;
      state.frontlineDetails = initialSelectorState;
      state.massCopyFrom = initialSelectorState;
      state.massCopyTo = initialSelectorState;
      state.planDetails = initialSelectorState;
    },
    resetExposedGeographies: (state, action) => {
      const featureName = action.payload as AdvancedSelectorFeature;
      state[featureName].exposedGeographies = [];
    }
  }
});

export const {
  // setDimensionSelections,
  toggleDisplayAS,
  resetExposedPackages,
  resetExposed,
  resetCurrentAndExposed,
  resetCurrent,
  handleSaveAndClose,
  handleStartDateChange,
  handleEndDateChange,
  resetDates,
  setMarketLevel,
  toggleAllLevelsCheckbox,
  clearMarketSpecificityWholesaler,
  setFilteredSelections,
  setBrandFamilySeqIds,
  setBrandFamilyOptions,
  setLiquidTypeSeqIds,
  setDraftOption,
  setIncludeInactivePackages,
  setRecordTypeFilters,
  setCurrentSelections,
  setBrandFilterCheckbox,
  setActiveFilterTab,
  setBrandType,
  setFetchASData,
  applyFetchASData,
  setStateCd,
  setPlanOrPcrSeqId,
  setCivMilFiltersCheckbox,
  setConsumptionTypeCheckbox,
  setRefetchDiscounts,
  setNoDiscountsFoundModalOpen,
  setCreateDiscountExposed,
  setCurrentSavedSelection,
  setGeographyFilter,
  setLiquidType,
  clearFetchedData,
  resetSandboxFeatureState,
  resetExposedGeographies
} = advancedSelectorSlice.actions;

export const selectActiveFilterTab = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].activeFilterTab;
};

export const selectDateRange = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return {
    startFilterText: state.advancedSelector[featureName].startFilterText,
    endFilterText: state.advancedSelector[featureName].endFilterText
  };
};

//market Level
export const selectMarketLevel = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].marketLevel;
};

export const selectAllLevelsCheckbox = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].allLevels;
};

export const selectMarketSpecificityWholesaler = (featureName: AdvancedSelectorFeature) => (state: RootState) =>
  state.advancedSelector[featureName].marketSpecificityWholesaler;

//current dimension selections
export const selectCurrentSelections =
  (dimension: string, featureName: AdvancedSelectorFeature) => (state: RootState) => {
    if (dimension === 'geographies')
      return state.advancedSelector[featureName].currentGeographies as WholesalerSelection[];
    if (dimension === 'brands') return state.advancedSelector[featureName].currentBrands as BrandSelection[];
    if (dimension === 'packages') return state.advancedSelector[featureName].currentPackages as PackageSelection[];
    if (dimension === 'alcohols') return state.advancedSelector[featureName].currentAlcohols as AlchlStrength[];
    if (dimension === 'subwholesalers')
      return state.advancedSelector[featureName].currentSubwholesalers as WholesalerSelection[];
  };

export const selectDimensionSelections = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  const exposedASSubwholesalers = state.advancedSelector[featureName].exposedSubwholesalers;
  const exposedASBrands = state.advancedSelector[featureName].exposedBrands;
  const exposedASPackages = state.advancedSelector[featureName].exposedPackages;
  const exposedASAlcohols = state.advancedSelector[featureName].exposedAlcohols;
  const exposedASGeographies = state.advancedSelector[featureName].exposedGeographies;

  const brands = getBrandDimensions(exposedASBrands);
  const packages = getPackageDimensions(exposedASPackages);
  const alcohols = getAlcoholDimensions(exposedASAlcohols);

  const geoIdsToSend =
    state.advancedSelector[featureName].marketLevel !== 'GM'
      ? getGeographyDimensions(exposedASSubwholesalers)
      : getGeographyDimensions(exposedASGeographies);

  return {
    geographies: geoIdsToSend,
    brands,
    packages,
    alcohols
  };
};

//filtered dimension selections
export const selectFilteredSelections =
  (dimension: string, featureName: AdvancedSelectorFeature) => (state: RootState) => {
    if (dimension === 'geographies') return state.advancedSelector[featureName].filteredGeographies;
    if (dimension === 'brands') return state.advancedSelector[featureName].filteredBrands;
    if (dimension === 'packages') {
      const filteredPackages = state.advancedSelector[featureName].filteredPackages;
      //if no filters have been set, filtered packages will be empty. In that case, use all packages.
      return filteredPackages.length ? filteredPackages : state.advancedSelector.allPackages;
    }
    if (dimension === 'alcohols') return state.advancedSelector[featureName].filteredAlcohols;
    if (dimension === 'subwholesalers') return state.advancedSelector[featureName].filteredSubwholesalers;
  };

//geography filters
export const selectIncludeNonEquity = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].includeNonEquity;
};
export const selectExcludeSpeciality = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].excludeSpeciality;
};
export const selectIncludeInactiveGeographies = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].includeInactiveGeographies;
};

export const selectStateCd = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].stateCd;
};
export const selectRegionCd = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].regionCd;
};

export const selectInitialCurrentGeographiesSet = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].initialCurrentGeographiesSet;
};

//brand filters
export const selectIncludeInactiveBrands = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].includeInactiveBrands;
};
export const selectOnlyVarietyPacks = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].onlyVarietyPacks;
};
export const selectBrandType = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].brandType;
};
export const selectLiquidTypeSeqIds = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].liquidTypeSeqIds;
};
export const selectBrandFamiliesSeqIds = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].brandFamiliesSeqIds;
};
export const selectBrandFamilyOptions = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].brandFamilyOptions;
};

//package filters
export const selectDraftOption = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].draftOption;
};
export const selectIncludeInactivePackages = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].includeInactivePackages;
};

//record type filters
export const selectRecordTypeFilters = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return {
    discountType: state.advancedSelector[featureName].discountType,
    recordType: state.advancedSelector[featureName].recordType
  };
};

//append filters: ConsumptionType, CivMilitary
export const selectConsumptionType = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].consumptionType;
};

export const selectCivMilDiscounts = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].civMil;
};

export const selectIncludeMilitaryRetailerAttribute = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].includeMilitaryRetailerAttribute;
};

//seqId
export const selectPlanOrPcrSeqId = (state: RootState) => {
  return state.advancedSelector.planOrPcrSeqId;
};
export const selectRefetchDiscounts = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].refetchDiscounts;
};

export const selectDisplayAS = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].displayAS;
};

export const selectExposed = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return {
    geographies: state.advancedSelector[featureName].exposedGeographies,
    brands: state.advancedSelector[featureName].exposedBrands,
    packages: state.advancedSelector[featureName].exposedPackages,
    alcohols: state.advancedSelector[featureName].exposedAlcohols,
    subwholesalers: state.advancedSelector[featureName].exposedSubwholesalers
  };
};

export const selectCurrent = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return {
    geographies: state.advancedSelector[featureName].currentGeographies,
    brands: state.advancedSelector[featureName].currentBrands,
    packages: state.advancedSelector[featureName].currentPackages,
    alcohols: state.advancedSelector[featureName].currentAlcohols,
    subwholesalers: state.advancedSelector[featureName].currentSubwholesalers
  };
};

export const selectNoDiscountsFoundModalOpen = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].noDiscountsFoundOpen;
};

export const selectCurrentSavedSelection = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].currentSavedSelection;
};

export const selectRefetchGeographies = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].refetchGeographies;
};

export const selectLiquidType = (featureName: AdvancedSelectorFeature) => (state: RootState) => {
  return state.advancedSelector[featureName].liquidType;
};

export const selectAllBrands = (state: RootState) => state.advancedSelector.allBrands;
export const selectAllPackages = (state: RootState) => state.advancedSelector.allPackages;
export const selectAllAlcohols = (state: RootState) => state.advancedSelector.allAlcohols;
export const selectAllGeoStates = (state: RootState) => state.advancedSelector.allGeoStates;
export const selectAllLiquidTypes = (state: RootState) => state.advancedSelector.allLiquidTypes;
export const selectAllBrandFamilies = (state: RootState) => state.advancedSelector.allBrandFamilies;
export const selectSavedSelections = (state: RootState) => state.advancedSelector.allSavedSelections;

export default advancedSelectorSlice.reducer;
