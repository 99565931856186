import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectToastsPack } from './SnackbarAlertSlice';
import { addToast as addToastStore } from './SnackbarAlertSlice';
import { ToastProps } from './SnackbarToast';

export const useSnackbarAlert = () => {
  const toastsPack = useAppSelector(selectToastsPack);
  const dispatch = useAppDispatch();

  const addToast = (toast: ToastProps) => {
    dispatch(addToastStore(toast));
  };

  return {
    addToast,
    toastsPack
  };
};
