//RTK
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type IWholesalerRowData = {
  wslrCustGrpSeqId: number | undefined;
  displayName: string | undefined;
  wslrNumber: string | null | undefined;
  fob: string;
  recFLPTR: string;
};

type INewBrandPackageState = {
  geographies: number[];
  brands: number[];
  packages: number[];
  alcohols: string[];
  pccTaskNumber: string;
  descriptionRationale: string;
  fobEffectiveDate: string;
  affiliation: string;
  fob: string;
  recFLPTR: string;
  wholesalerData: IWholesalerRowData[];
};

const initialState: INewBrandPackageState = {
  geographies: [],
  brands: [],
  packages: [],
  alcohols: [],
  pccTaskNumber: '',
  descriptionRationale: '',
  fobEffectiveDate: '',
  affiliation: 'CivMil',
  fob: '',
  recFLPTR: '',
  wholesalerData: []
};

export const newBrandPackageSlice = createSlice({
  name: 'newBrandPackage',
  initialState,
  reducers: {
    setGeographies: (state, action) => {
      state.geographies = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setAlcohols: (state, action) => {
      state.alcohols = action.payload;
    },
    setPccTaskNumber: (state, action) => {
      state.pccTaskNumber = action.payload;
    },
    setDescriptionRationale: (state, action) => {
      state.descriptionRationale = action.payload;
    },
    setFobEffectiveDate: (state, action) => {
      state.fobEffectiveDate = action.payload;
    },
    setAffiliation: (state, action) => {
      state.affiliation = action.payload;
    },
    setFOB: (state, action) => {
      state.fob = action.payload;
    },
    setRecFLPTR: (state, action) => {
      state.recFLPTR = action.payload;
    },
    setWholesalerData: (state, action) => {
      state.wholesalerData = action.payload;
    },
    clearScreen: (state) => {
      state.fobEffectiveDate = '';
      state.pccTaskNumber = '';
      state.descriptionRationale = '';
      state.affiliation = 'CivMil';
      state.fob = '';
      state.recFLPTR = '';
      state.wholesalerData = [];
    }
  }
});

export const {
  setGeographies,
  setBrands,
  setPackages,
  setAlcohols,
  setPccTaskNumber,
  setDescriptionRationale,
  setFobEffectiveDate,
  setAffiliation,
  setFOB,
  setRecFLPTR,
  setWholesalerData,
  clearScreen
} = newBrandPackageSlice.actions;

export const selectGeographies = (state: RootState) => state.newBrandPackage.geographies;
export const selectBrands = (state: RootState) => state.newBrandPackage.brands;
export const selectPackages = (state: RootState) => state.newBrandPackage.packages;
export const selectAlcohols = (state: RootState) => state.newBrandPackage.alcohols;

export const selectPccTaskNumber = (state: RootState) => state.newBrandPackage.pccTaskNumber;
export const selectDescriptionRationale = (state: RootState) => state.newBrandPackage.descriptionRationale;
export const selectFobEffectiveDate = (state: RootState) => state.newBrandPackage.fobEffectiveDate;
export const selectAffiliation = (state: RootState) => state.newBrandPackage.affiliation;
export const selectFob = (state: RootState) => state.newBrandPackage.fob;
export const selectRecFLPTR = (state: RootState) => state.newBrandPackage.recFLPTR;

export const selectWholesalerData = (state: RootState) => state.newBrandPackage.wholesalerData;

export default newBrandPackageSlice.reducer;
