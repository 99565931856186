//React-RTK
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { store } from './app/store';
import { Provider } from 'react-redux';

//Styling
import './index.css';

//Components
import App from './App';

//MSAL
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './configs/authConfig';

//Service Worker
import * as serviceWorker from './serviceWorker';

//DataDog RUM
import { datadogRum } from '@datadog/browser-rum';

//Redux Persist
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './app/store';
import packageJson from '../package.json';

// AG grid License
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(`${import.meta.env['VITE_AG_LICENSE_KEY']}`);

// This if check returns true when running from build files.
// When running from source files DEV is true.
if (import.meta.env.PROD) {
  datadogRum.init({
    applicationId: import.meta.env['VITE_APPLICATION_ID'],
    clientToken: import.meta.env['VITE_CLIENT_TOKEN'],
    site: import.meta.env['VITE_SITE'],
    service: import.meta.env['VITE_SERVICE'],
    env: import.meta.env['VITE_ENV'],
    version: import.meta.env['VITE_VERSION'],
    // FIXME: Datadog throws an error if this is enabled tracked in 31217 'Datadog RUM errors'
    // sampleRate: import.meta.env["VITE_SAMPLE_RATE"],
    trackInteractions: Boolean(import.meta.env['VITE_TRACK_INTERACTIONS']),
    defaultPrivacyLevel: import.meta.env['VITE_DEFAULT_PRIVACY_LEVEL'],
    allowedTracingUrls: [{ match: String(import.meta.env['VITE_API_BASE_URL']), propagatorTypes: ['tracecontext'] }]
  });

  datadogRum.startSessionReplayRecording();
}

export const msalInstance = new PublicClientApplication(msalConfig);

//Switched to use new createRoot for React18 https://reactjs.org/link/switch-to-createroot
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
