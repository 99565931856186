import BigNumber from 'bignumber.js';
BigNumber.set({ DECIMAL_PLACES: 5 }); // https://mikemcl.github.io/bignumber.js/#decimal-places
export const SplitPercentFromAllowanceAndDiscount = (discountAmount: number, allowance: number) => {
  const bigAllowance = BigNumber(allowance);
  const bigDiscountAmount = BigNumber(discountAmount);
  let abSplitPercent = BigNumber(bigAllowance.div(bigDiscountAmount).times(100));

  //ABSplit should not exceed 100%
  if (abSplitPercent.toNumber() > 100) {
    abSplitPercent = BigNumber(100);
  }
  return Number(abSplitPercent.toFixed(2));
};
export const SplitPercentFromAllowanceAndDiscountPerLevel = (
  discountFrom: number,
  discountTo: number,
  allowance: number
) => {
  const bigDiscountFrom = BigNumber(discountFrom);
  const bigDiscountTo = BigNumber(discountTo);
  const discountPerLevel = bigDiscountTo.minus(bigDiscountFrom).plus(0.01);
  const bigAllowance = BigNumber(allowance);

  let abSplitPercent = BigNumber(bigAllowance.div(discountPerLevel).times(100));

  if (abSplitPercent.toNumber() > 100) {
    abSplitPercent = BigNumber(100);
  }
  return Number(abSplitPercent.toFixed(2));
};

export const AllowanceFromSplitAndDiscount = (discountAmount: number, abSplitPercent: number) => {
  let allowance = BigNumber(0);
  const bigDiscountAmount = BigNumber(discountAmount);
  const bigAbSplitPercent = BigNumber(abSplitPercent);

  if (discountAmount === 1 && abSplitPercent === 0) {
    // TODO: Is this for initialization or something?
    allowance = BigNumber(0.5);
  } else {
    allowance = BigNumber(bigDiscountAmount.times(bigAbSplitPercent.div(100)));
  }
  return Number(allowance.toFixed(3));
};

export const PromotedPTRCalculation = (discountAmount: number, recommendedFrontlinePTR: number) => {
  const bigDiscountAmount = BigNumber(discountAmount);
  const bigRecommendedFrontlinePTR = BigNumber(recommendedFrontlinePTR);
  const promotedPTR = bigRecommendedFrontlinePTR.minus(bigDiscountAmount);
  return Number(promotedPTR.toFixed(2));
};

export const WSLRSAllowanceFromDiscount = (discountAmount: number, allowance: number) => {
  const bigDiscountAmount = BigNumber(discountAmount);
  const bigABAllowance = BigNumber(allowance);
  const wslrAllowance = bigDiscountAmount.minus(bigABAllowance);
  return Number(wslrAllowance.toFixed(3));
};

//Variable Split Calculations
export const calculateAllowanceFromSplitVarSplit = (discountFrom: number, discountTo: number, abSplit: number) => {
  const bigDiscountFrom = BigNumber(discountFrom);
  const bigDiscountTo = BigNumber(discountTo);
  const bigAbSplit = BigNumber(abSplit);

  const discountPerLevel = bigDiscountTo.minus(bigDiscountFrom).plus(0.01);

  const allowance = BigNumber(discountPerLevel.times(bigAbSplit.div(100)));

  return Number(allowance.toFixed(3));
};
export const calculateSplitFromAllowanceVarSplit = (discountFrom: number, discountTo: number, allowance: number) => {
  const bigDiscountFrom = BigNumber(discountFrom);
  const bigDiscountTo = BigNumber(discountTo);
  const bigAllowance = BigNumber(allowance);

  const discountPerLevel = bigDiscountTo.minus(bigDiscountFrom).plus(0.01);

  let abSplit = BigNumber(bigAllowance.div(discountPerLevel).times(100));
  //ABSplit should not exceed 100%
  if (abSplit.toNumber() > 100) {
    abSplit = BigNumber(100);
  }

  return Number(abSplit.toFixed(2));
};
