import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface IGuidelineNotificationData {
  notificationReferenceId: string;
  updateIsPending: boolean;
  refetchTrigger: boolean;
}

const initialState: { guidelineNotificationData: IGuidelineNotificationData } = {
  guidelineNotificationData: {
    notificationReferenceId: 'none',
    updateIsPending: false,
    refetchTrigger: false //this is watched by a useEffect in the Guidelines Component
  }
};

export const GuidelinesSlice = createSlice({
  name: 'Guidelines',
  initialState,
  reducers: {
    setUpdateIsPending(state, action: PayloadAction<boolean>) {
      state.guidelineNotificationData.updateIsPending = action.payload;
    },
    setNotificationReferenceId(state, action: PayloadAction<string>) {
      state.guidelineNotificationData.notificationReferenceId = action.payload;
    },
    callRefetchAndRepaint(state) {
      state.guidelineNotificationData.refetchTrigger = !state.guidelineNotificationData.refetchTrigger;
    }
  }
});

export const { setUpdateIsPending, setNotificationReferenceId, callRefetchAndRepaint } = GuidelinesSlice.actions;

export const selectGuidelinesState = (state: RootState) => state.Guidelines;

export default GuidelinesSlice.reducer;
