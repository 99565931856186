//React-RTK
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

//Redux Persist
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// feature slices
import { drawerSlice } from '../layout/Drawer/DrawerSlice';
import { authLoginSlice } from '../features/Auth/AuthLoginSlice';
import { createPlansSlice } from '../features/Plans/CreatePlans/CreatePlansSlice';
import { notificationsSlice } from '../layout/Notifications/NotificationsSlice';
import { activitiesSlice } from '../features/Activities/ActivitiesSlice';
import { createDiscountSlice } from '../features/Discount/CreateDiscount/CreateDiscountSlice';
import { CreateDiscountBuildersSlice } from '../features/Discount/CreateDiscount/BuildersSlice';

//TODO:Remove persist slice in future
import { advancedSelectorSlice } from '../features/AdvancedSelectorV3/AdvancedSelectorSlice';

import { ImportWizardSlice } from '../features/ImportWizard/ImportWizardSlice';

import { discountDetailsSliceV2 } from '../features/Discount/DiscountDetailsV2/DiscountDetailsSlice';

import { GuidelinesSlice } from '../features/GuideLines/GuidelinesSlice';
import { frontlineDetailSlice } from '../features/Frontline/FrontlineDetails/FrontlineDetailSlice';
import { fobAdjustmentSlice } from '../features/Frontline/FobAdjustment/FobAdjustmentSlice';
import { newBrandPackageSlice } from '../features/NewBrandPackage/NewBrandPackageSlice';
import { snackbarAlertSlice } from '../layout/SnackbarAlerts/SnackbarAlertSlice';
import { AgGridStateSlice } from '../components/AgGrid/AgGridState/AgGridStateSlice';

// api slices
import { priceApi } from '../api/priceApi/priceApi';

import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { fobDeleteSlice } from '../features/Frontline/NationalFobDelete/NationalFobDeleteSlice';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.error('Error during request:', action);
  }
  return next(action);
};

const rootReducer = combineReducers({
  [drawerSlice.name]: drawerSlice.reducer,
  [authLoginSlice.name]: authLoginSlice.reducer,
  [createPlansSlice.name]: createPlansSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [priceApi.reducerPath]: priceApi.reducer,
  [activitiesSlice.name]: activitiesSlice.reducer,
  [createDiscountSlice.name]: createDiscountSlice.reducer,
  [CreateDiscountBuildersSlice.name]: CreateDiscountBuildersSlice.reducer,
  [ImportWizardSlice.name]: ImportWizardSlice.reducer,
  [GuidelinesSlice.name]: GuidelinesSlice.reducer,
  [discountDetailsSliceV2.name]: discountDetailsSliceV2.reducer,
  [frontlineDetailSlice.name]: frontlineDetailSlice.reducer,
  [fobAdjustmentSlice.name]: fobAdjustmentSlice.reducer,
  [newBrandPackageSlice.name]: newBrandPackageSlice.reducer,
  [fobDeleteSlice.name]: fobDeleteSlice.reducer,
  [snackbarAlertSlice.name]: snackbarAlertSlice.reducer,
  [advancedSelectorSlice.name]: advancedSelectorSlice.reducer,
  [AgGridStateSlice.name]: AgGridStateSlice.reducer
});

//Redux Persist code

const persistConfig = {
  key: 'root', // the key for the persist
  debug: false, // set to true for verbose logs
  storage, // the storage adapter
  whitelist: ['discount', 'discounts'] // add the reducers you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .concat(priceApi.middleware)
      .concat(rtkQueryErrorLogger)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
