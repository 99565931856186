//Purpose: After a user is logged in at the MSAL layer, do a request for user data

// React and RTK
import { ReactNode, useEffect, useState } from 'react';
import { useGetApiUserMeQuery } from '@priceApi/priceApi';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { setUserIdentity, selectUserIdentity } from '@FAuth/AuthLoginSlice';
import { datadogRum } from '@datadog/browser-rum';

// Components

const UserData = ({ children }: { children?: ReactNode }) => {
  const dispatch = useAppDispatch();

  const [skipToken, setSkipToken] = useState(true);
  const userIdentityQuery = useGetApiUserMeQuery(undefined, { skip: skipToken });
  const userIdentityFromStore = useAppSelector(selectUserIdentity);

  useEffect(() => {
    //If we already have the user identity in store, we don't need to fetch it again.
    if (userIdentityFromStore) {
      setSkipToken(true);
    } else {
      setSkipToken(false);
    }
  }, []);

  useEffect(() => {
    if (userIdentityQuery && userIdentityQuery.isSuccess) {
      const userIdentity = userIdentityQuery.data;

      if (userIdentity.user && userIdentity.employee) {
        //set the data dog user information
        datadogRum.setUser({
          id: userIdentity.user?.userId,
          email: userIdentity.employee.emailAddrTxt ? userIdentity.employee.emailAddrTxt : undefined, //EmailAddressTxt is nullable.
          name: userIdentity.user?.userNm
        });
      }

      //Fill the Slice for UserData.
      dispatch(
        setUserIdentity({
          userIdentity
        })
      );
      setSkipToken(true);
    }
  }, [userIdentityQuery.isSuccess]);

  return <>{children}</>;
};

export default UserData;
