//RTK
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

type IFobDeleteState = {
  brands: number[];
  packages: number[];
  alcohols: string[];
  fobCancellationDate: string;
  fobNotes: string;
  fobDescription: string;
  pccTaskNumber: string;
};

const initialState: IFobDeleteState = {
  brands: [],
  packages: [],
  alcohols: [],
  fobCancellationDate: '',
  fobNotes: '',
  fobDescription: '',
  pccTaskNumber: ''
};

export const fobDeleteSlice = createSlice({
  name: 'fobDelete',
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setAlcohols: (state, action) => {
      state.alcohols = action.payload;
    },
    setFobCancellationDate: (state, action) => {
      state.fobCancellationDate = action.payload;
    },
    setFobNotes: (state, action) => {
      state.fobNotes = action.payload;
    },
    setFobDescription: (state, action) => {
      state.fobDescription = action.payload;
    },
    setPCCTaskNumber: (state, action) => {
      state.pccTaskNumber = action.payload;
    },
    clearScreen: (state) => {
      state.brands = [];
      state.packages = [];
      state.alcohols = [];
      state.fobCancellationDate = '';
      state.fobNotes = '';
      state.fobDescription = '';
      state.pccTaskNumber = '';
    },
    clearNotes: (state) => {
      state.fobNotes = '';
    },
    clearDescription: (state) => {
      state.fobDescription = '';
    },
    clearPCCTask: (state) => {
      state.pccTaskNumber = '';
    }
  }
});

export const {
  setBrands,
  setPackages,
  setAlcohols,
  setFobCancellationDate,
  setFobNotes,
  setFobDescription,
  setPCCTaskNumber,
  clearNotes,
  clearDescription,
  clearPCCTask,
  clearScreen
} = fobDeleteSlice.actions;

export const selectBrands = (state: RootState) => state.fobDelete.brands;
export const selectPackages = (state: RootState) => state.fobDelete.packages;
export const selectAlcohols = (state: RootState) => state.fobDelete.alcohols;
export const selectFobCancellationDate = (state: RootState) => state.fobDelete.fobCancellationDate;
export const selectFobNotes = (state: RootState) => state.fobDelete.fobNotes;
export const selectFobDescription = (state: RootState) => state.fobDelete.fobDescription;
export const selectPCCTaskNumber = (state: RootState) => state.fobDelete.pccTaskNumber;

export default fobDeleteSlice.reducer;
