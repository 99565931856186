export type IPrcUserTypes = 'Administrator' | 'User' | 'Read';

export enum PrcUserTypesEnum {
  Administrator = 'Administrator',
  User = 'User',
  Read = 'Read'
}

export type INpsUserTypes = 'PricingManager' | 'Legal' | 'PriceCoordinator' | 'NpsAdministrator' | 'NpsReadOnly';

export enum NpsUserTypesEnum {
  PricingManager = 'PricingManager',
  Legal = 'Legal',
  PriceCoordinator = 'PriceCoordinator',
  NpsAdministrator = 'NpsAdministrator',
  NpsReadOnly = 'NpsReadOnly'
}

export type IAllUserTypes = IPrcUserTypes | INpsUserTypes;
