//RTK
import { createSlice } from '@reduxjs/toolkit';

//Types
import { IAllUserTypes } from '@FAuth/types';
import { UserIdentityData } from '@priceApi/priceApi';

type IAuthState = {
  userRoles: IAllUserTypes[];
  authenticated: boolean;
  msalAuthResult: any;
  userIdentity: UserIdentityData | undefined;
  userName: string;
};

const initialState: IAuthState = {
  userRoles: [],
  authenticated: false,
  msalAuthResult: undefined,
  userIdentity: undefined,
  userName: ''
};

export const authLoginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { userRoles, userName } = action.payload;
      state.userRoles = userRoles;
      state.authenticated = true;
      state.userName = userName;
    },
    setUserIdentity: (state, action) => {
      const { userIdentity } = action.payload;
      state.userIdentity = userIdentity;
    }
  }
});

export const { setUserData, setUserIdentity } = authLoginSlice.actions;
export default authLoginSlice.reducer;

// selectors
export const selectAccessToken = (state: any) => state.auth.accessToken;
export const selectUserRoles = (state: any) => state.auth.userRoles;
export const selectMsalAuthResult = (state: any) => state.auth.msalAuthResult;
export const selectUserIdentity = (state: any) => state.auth.userIdentity;
export const selectUserName = (state: any) => state.auth.userName;
export const selectUserNm = (state: any) => state.auth.userIdentity.user.userNm;
