//This is a redux slice that stores and manages the state of the filters for the ag-grid
//It is used for PricingExecutionCenterPCRTable.tsx and PlansTable.tsx

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { AGGridState, FilterObject, InitialState } from './AgGridStateTypes';
import { ColumnState } from 'ag-grid-community';

const initialState: InitialState = {
  grids: []
};

export interface ISetFilterStatePayload {
  key: string;
  filterState: FilterObject;
}

export interface ISetColumnStatePayload {
  key: string;
  columnState: ColumnState[];
}

export const AgGridStateSlice = createSlice({
  name: 'AgGridStateSlice',
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<ISetFilterStatePayload>) => {
      //find the grid in the array of grids using its key
      const grid = state.grids.find((grid) => grid.key === action.payload.key);
      if (grid) {
        //if the grid exists, update its state
        grid.filterState = action.payload.filterState;
      } else {
        const newGrid: AGGridState = {
          key: action.payload.key,
          filterState: action.payload.filterState,
          columnState: null
        };
        state.grids.push(newGrid);
      }
    },
    setColumnState: (state, action: PayloadAction<ISetColumnStatePayload>) => {
      //find the grid in the array of grids using its key
      const grid = state.grids.find((grid) => grid.key === action.payload.key);
      if (grid) {
        //if the grid exists, update its state
        grid.columnState = action.payload.columnState;
      } else {
        const newGrid: AGGridState = {
          key: action.payload.key,
          filterState: null,
          columnState: action.payload.columnState
        };
        state.grids.push(newGrid);
      }
    }
  }
});

export const { setFilterState, setColumnState } = AgGridStateSlice.actions;

export const selectAGGridState = (state: RootState, key: string) =>
  state.AgGridStateSlice.grids.find((grid) => grid.key === key);

export default AgGridStateSlice.reducer;
