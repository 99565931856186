//React+Redux
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

//MUI

//APP
import { RootState } from '../../app/store';
import {
  ExcelUploadStatusAction,
  ImportStageDataAction,
  InitialState,
  PreviewDataAction,
  UploadExcelDataAction,
  UploadLegendDataAction,
  UploadTypeDataAction
} from './types/ImportWizardTypes';

const initialState: InitialState = {
  //##Stepper properties
  //used in conjunction with active step to control stepper (on top of page)
  //not used for navigation buttons
  steps: ['Select Import Type', 'Upload Data', 'Preview', 'Finish Import'],
  activeStep: 0, //note

  //##navigation state properties
  //true means button is disabled
  //false means button is enabled
  navButtonDisabledStatuses: { Cancel: false, Previous: true, Next: true },
  next: false,

  //##Component specific properties
  //Select Import type
  uploadTypeData: {
    Excel: false,
    Legend: false,
    isFrontlineTask: false,
    isDiscountTask: true,
    hasValidEffectiveDate: false,
    proposedEffectiveDate: '',
    isActionBarImport: false,
    pcrSeqId: 0
  },

  //Controls Rendered Page
  excelUploadStatus: { isUploaded: false, uploadErrors: false, uploadProcessing: false },

  //use exclusively for Hook;
  uploadExcelData: {
    taskId: 'defaultValue',
    fileName: 'no upload yet',
    notificationReferenceId: 'none'
  },

  uploadLegendData: {
    //this is the task ID coming from legend
    legendSrcId: 'defaultValue',
    //this is the staging/preview Id generate when a legend task is submitted to the PRC API
    stagingTaskId: 'defaultValue',
    uploadErrors: false,
    notificationReferenceId: 'none',
    uploadProcessing: false
  },

  //preview Stage
  previewData: {
    notificationReferenceId: 'none',
    StagingIdCreated: false,
    StagingIdisLoading: false,
    stagingId: -1
  },

  //Import stage
  importStageData: {
    statusMessage: 'no submission',
    discountCreated: false,
    discountProcessing: false,
    pcrId: -1,
    multiplePcrs: false
  }
};
export const ImportWizardSlice = createSlice({
  name: 'ImportWizard',
  initialState,
  reducers: {
    //only increment activeStep on next and previous onClick handlers; do put inside hooks or useEffect
    incrementActiveStep: (state) => {
      if (state.activeStep < state.steps.length - 1) state.activeStep += 1;
    },
    decrementActiveStep: (state) => {
      if (state.activeStep > 0) state.activeStep -= 1;
    },

    // this is called throughout the feature to reset the navigation buttons
    // For any given component state this function should return the status of the navigation buttons.
    // The if statements are organized by active step then component, mirroring the feature structure.
    //To extend the functionality, work within the existing conditional structure
    setNavButtonsStatuses: (state) => {
      const { activeStep, uploadTypeData, excelUploadStatus, uploadLegendData, importStageData } = state;

      //import type stage
      if (activeStep === 0) {
        // import type stage
        // enable after upload type is selected
        if (!uploadTypeData.Excel && !uploadTypeData.Legend) {
          //
          //true means button is disabled; false means button is enabled
          state.navButtonDisabledStatuses = { Cancel: false, Previous: true, Next: true };
        } else if (uploadTypeData.Excel || uploadTypeData.Legend) {
          //
          //true means button is disabled; false means button is enabled
          state.navButtonDisabledStatuses = { Cancel: false, Previous: true, Next: false };
        }
        //
      }
      // Upload stage
      else if (activeStep === 1) {
        ///Excel
        //enable next after a successful upload; else disable next
        //might be worth nesting these to stay organized
        if (uploadTypeData.Excel && !excelUploadStatus.isUploaded) {
          state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };
        }
        //isLoading
        else if (uploadTypeData.Excel && excelUploadStatus.uploadProcessing) {
          state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };
        }
        //Errors
        else if (uploadTypeData.Excel && excelUploadStatus.uploadErrors) {
          state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };
        }
        //successful upload
        //enable next
        else if (uploadTypeData.Excel && excelUploadStatus.isUploaded) {
          state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: false };
        }
        ///
        ///Legend
        /// Disable next if no task is selected
        /// Disable next while stagingId is being generated (after submitted selected task)
        else if (uploadTypeData.Legend) {
          //default setting; next is disabled
          // state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };

          //task not selected; cannot proceed
          if (uploadLegendData.legendSrcId === 'defaultValue') {
            state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };
          }
          //task is selected; task is not processing next is enabled
          else if (uploadLegendData.legendSrcId !== 'defaultValue' && !uploadLegendData.uploadProcessing) {
            state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: false };
          }

          //task processing; disable next button
          else if (uploadLegendData.uploadProcessing) {
            state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };
          }
        }
      }
      //Preview
      //NO conditionals here
      else if (activeStep === 2) {
        //Note that a discount will be created for non error records
        state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: false };
      }
      //discount processing (import stage)
      //disable next and previous while processing
      else if (activeStep === 3) {
        if (importStageData.discountProcessing) {
          //loading
          state.navButtonDisabledStatuses = { Cancel: false, Previous: true, Next: true };
        } else if (!importStageData.discountProcessing && importStageData.discountCreated) {
          //success
          state.navButtonDisabledStatuses = { Cancel: false, Previous: true, Next: false };
        } else if (!importStageData.discountProcessing && !importStageData.discountCreated) {
          //failure
          state.navButtonDisabledStatuses = { Cancel: false, Previous: false, Next: true };
        }
      }
    },

    //UI state management
    resetImportWizard: () => {
      return initialState;
    },
    //UI state management
    //resets to upload page
    destroyPreviewAndSubmission: (state) => {
      state.previewData = initialState.previewData;
      state.importStageData = initialState.importStageData;
      state.uploadExcelData = initialState.uploadExcelData;
      state.excelUploadStatus = initialState.excelUploadStatus;
      state.uploadLegendData = initialState.uploadLegendData;

      state.activeStep = 1;
    },

    // NOTE must keep payload and uploadTypeData flat
    setUploadTypeData(state, action: PayloadAction<UploadTypeDataAction>) {
      Object.assign(state.uploadTypeData, action.payload);
    },
    //TODO remove usage of these replace with setUploadLegendData
    setUploadLegendStagingTaskId(state, action: PayloadAction<string>) {
      state.uploadLegendData.stagingTaskId = action.payload;
    },
    setUploadLegendlegendSrcId(state, action) {
      state.uploadLegendData.legendSrcId = action.payload;
    },
    setLegendTaskSubmissionNotificationId(state, action) {
      state.uploadLegendData.notificationReferenceId = action.payload;
    },
    setUploadExcelData(state, action: PayloadAction<UploadExcelDataAction>) {
      Object.assign(state.uploadExcelData, action.payload);
    },

    setUploadLegendData(state, action: PayloadAction<UploadLegendDataAction>) {
      Object.assign(state.uploadLegendData, action.payload);
    },
    // Assumes calling convention with valid keys passed; example
    // dispatch(setExcelUploadStatus({ isUploaded: false, uploadErrors: false, uploadProcessing: false }));
    setExcelUploadStatus(state, action: PayloadAction<ExcelUploadStatusAction>) {
      Object.assign(state.excelUploadStatus, action.payload);
    },

    // Assumes calling convention with valid keys passed; example
    // setPreviewData({notificationReferenceId: 'none', StagingIdCreated: false, StagingIdisLoading: false, stagingId: -1})
    setPreviewData(state, action: PayloadAction<PreviewDataAction>) {
      Object.assign(state.previewData, action.payload);
    },

    // Assumes calling convention with valid keys passed; example
    //setImportStageData({ statusMessage: 'no submission', discountCreated: false, discountProcessing: false, pcrId: -1 })
    setImportStageData(state, action: PayloadAction<ImportStageDataAction>) {
      Object.assign(state.importStageData, action.payload);
    },

    toggleTaskType: (state) => {
      state.uploadTypeData.isDiscountTask = !state.uploadTypeData.isDiscountTask;
      state.uploadTypeData.isFrontlineTask = !state.uploadTypeData.isFrontlineTask;
    }
  }
});

export const {
  incrementActiveStep,
  decrementActiveStep,

  // separator
  resetImportWizard,
  destroyPreviewAndSubmission,
  setUploadTypeData,
  toggleTaskType,
  setLegendTaskSubmissionNotificationId,
  setUploadLegendData,
  setUploadExcelData,
  setExcelUploadStatus,

  setPreviewData,
  setUploadLegendStagingTaskId,
  setUploadLegendlegendSrcId,
  setImportStageData,
  setNavButtonsStatuses
} = ImportWizardSlice.actions;

export const selectImportWizardState = (state: RootState) => state.ImportWizard;
export const selectSteps = (state: RootState) => state.ImportWizard.steps;
export const selectActiveStep = (state: RootState) => state.ImportWizard.activeStep;
//TODO replace this
export const selectUploadType = (state: RootState) => state.ImportWizard.uploadTypeData;
export const selectUploadExcelData = (state: RootState) => state.ImportWizard.uploadExcelData;

export default ImportWizardSlice.reducer;
