//Using the routesConfig object and the buildRoutes function, exports the AllRoutes component utilizing the useRoutes hook

//React-RTK
import React, { FC, Fragment, lazy, LazyExoticComponent, Suspense } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';

// configs
import PATH_NAMES from './pathNames';

// components
const MainLayout = lazy(() => import('../layout/MainLayout/MainLayout'));
const Home = lazy(() => import('../features/Home/Home'));
const Profile = lazy(() => import('../features/Profile/Profile'));
const Error404 = lazy(() => import('../features/Error404/404Error'));
const Activities = lazy(() => import('../features/Activities/Activities'));

const Plans = lazy(() => import('../features/Plans/PlansHomePage/Plans'));
const CreatePlans = lazy(() => import('../features/Plans/CreatePlans/CreatePlans'));
const PlanDashboard = lazy(() => import('../features/Plans/PlanDetails/PlanDetails'));
const CalendarRollover = lazy(() => import('../features/CalendarRollover/CalendarRollover'));
const UnderConstruction = lazy(() => import('../features/Error404/404UnderConstruction'));
const PricingExecutionCenter = lazy(() => import('../features/PCR/PricingExecutionCenter/PricingExecutionCenter'));
const CreateDiscount = lazy(() => import('../features/Discount/CreateDiscount/CreateDiscount'));

const GuidelineLog = lazy(() => import('../features/Discount/DiscountGuidelineLog/DiscountGuidelineLog'));
const ImportWizard = lazy(() => import('../features/ImportWizard/ImportWizard'));
const FobDelete = lazy(() => import('../features/Frontline/NationalFobDelete/NationalFobDelete'));
const UnderConstruction404 = lazy(() => import('../features/Error404/404UnderConstruction'));
const NewBrandPackage = lazy(() => import('../features/NewBrandPackage/NewBrandPackage'));

const PcrFrontline = lazy(() => import('../features/PCR/Frontline/PcrFrontline'));
const PcrFobAdjustment = lazy(() => import('../features/PCR/Frontline/PcrFobAdjustment'));
const PcrDiscountDetails = lazy(() => import('../features/PCR/Discounts/PcrDiscountDetails'));
const PcrCreateDiscount = lazy(() => import('../features/PCR/Discounts/PcrCreateDiscount'));
const PcrMassCopy = lazy(() => import('../features/PCR/MassCopy/PcrMassCopy'));
const PcrDiscountCalendar = lazy(() => import('../features/PCR/Discounts/PcrDiscountCalendar'));
const PcrInformation = lazy(() => import('../features/PCR/Information/PcrInformation'));

const PlanCreateDiscount = lazy(() => import('../features/Plans/Discounts/PlanCreateDiscount'));
const PlanInformation = lazy(() => import('../features/Plans/Information/PlanInformation'));
const PlanMassCopy = lazy(() => import('../features/Plans/MassCopy/PlanMassCopy'));
const PlanCalendarRollover = lazy(() => import('../features/Plans/CalendarRollover/PlanCalendarRollover'));
const PlanDiscountCalendar = lazy(() => import('../features/Plans/Discounts/PlanDiscountCalendar'));
const PlanFrontlineDetails = lazy(() => import('../features/Plans/Frontline/PlanFrontlineDetails'));
const PlanGuidelineSandbox = lazy(() => import('../features/Plans/Guidelines/PlanGuidelineSandbox'));
const PlanDiscountDetails = lazy(() => import('../features/Plans/Discounts/PlanDiscountDetails'));

//NPS
const NpsHome = lazy(() => import('../features/NPS/Home/NpsHome'));
const NpsLegalReview = lazy(() => import('../features/NPS/Review/ReviewView'));
const NpsRMReview = lazy(() =>
  import('../features/NPS/Review/ReviewView').then((module) => ({ default: module.RMReviewView }))
);
const NpsFinalReview = lazy(() =>
  import('../features/NPS/CompletedRequests/CompletedRequests').then((module) => ({ default: module.default }))
);
const NpsCompletedRequests = lazy(() =>
  import('../features/NPS/CompletedRequests/CompletedRequests').then((module) => ({
    default: module.default
  }))
);
const NpsEditRequestDetails = lazy(() => import('../features/NPS/NpsEditRequestDetails/NpsEditRequestDetails'));

import { IAllUserTypes, NpsUserTypesEnum, PrcUserTypesEnum } from '@FAuth/types';
import RoleRoute from './RoleRoute';

const {
  PROFILE,
  PLANS,
  CREATE_PLANS,
  PLANS_ID,
  PLAN_DASHBOARD,
  PLAN_DASHBOARD_BASE,
  NOTFOUND,
  CALENDAR_ROLLOVER,
  ACTIVITIES,
  UNDER_CONSTRUCTION,
  ROOT,
  CREATE_DISCOUNTS,
  NPS_HOME,
  NPS_LEGAL_REVIEW,
  NPS_RM_REVIEW,
  NPS_FINAL_REVIEW,
  NPS_COMPLETED_REQUESTS,
  NPS_EDIT_REQUEST_DETAILS
} = PATH_NAMES;

export type RoutesType = {
  guard?: any;
  layout?: LazyExoticComponent<FC> | null;
  requiredRole?: IAllUserTypes[] | null;
  path: string;
  routes?: RoutesType[];
  component?: LazyExoticComponent<FC> | null;
};

export type UseRoutesConfigType = {
  element: JSX.Element;
  path: string;
  children: RoutesType[] | undefined;
}[];

const PrcCommonRoles = [PrcUserTypesEnum.Administrator, PrcUserTypesEnum.User, PrcUserTypesEnum.Read];
const NpsCommonRoles = [
  NpsUserTypesEnum.Legal,
  NpsUserTypesEnum.NpsAdministrator,
  NpsUserTypesEnum.PriceCoordinator,
  NpsUserTypesEnum.PricingManager,
  NpsUserTypesEnum.NpsReadOnly
];
export const routesConfig = [
  {
    guard: null,
    layout: MainLayout,
    path: '/',
    routes: [
      {
        path: PROFILE,
        component: Profile,
        requiredRole: null
      },
      // NPS paths
      {
        path: NPS_HOME,
        component: NpsHome,
        requiredRole: NpsCommonRoles
      },
      {
        path: NPS_LEGAL_REVIEW,
        component: NpsLegalReview,
        requiredRole: NpsCommonRoles
      },
      {
        path: NPS_RM_REVIEW,
        component: NpsRMReview,
        requiredRole: NpsCommonRoles
      },
      {
        path: NPS_FINAL_REVIEW,
        component: NpsFinalReview,
        requiredRole: NpsCommonRoles
      },
      {
        path: NPS_COMPLETED_REQUESTS,
        component: NpsCompletedRequests,
        requiredRole: NpsCommonRoles
      },
      {
        path: NPS_EDIT_REQUEST_DETAILS,
        component: NpsEditRequestDetails,
        requiredRole: NpsCommonRoles
      },
      // PRC paths
      {
        path: PLANS,
        component: Plans,
        requiredRole: PrcCommonRoles
      },
      {
        path: CREATE_PLANS,
        component: CreatePlans,
        requiredRole: PrcCommonRoles
      },
      {
        path: PLAN_DASHBOARD,
        component: PlanDashboard,
        requiredRole: PrcCommonRoles
      },
      {
        path: PLAN_DASHBOARD_BASE,
        component: PlanDashboard,
        requiredRole: PrcCommonRoles
      },
      {
        path: PLANS_ID,
        component: PlanDashboard,
        requiredRole: PrcCommonRoles
      },
      {
        path: NOTFOUND,
        component: Error404,
        requiredRole: null
      },
      {
        path: PLANS + '/:id/' + CALENDAR_ROLLOVER,
        component: CalendarRollover,
        requiredRole: PrcCommonRoles
      },
      {
        path: ACTIVITIES,
        component: Activities,
        requiredRole: PrcCommonRoles
      },
      {
        path: UNDER_CONSTRUCTION,
        component: UnderConstruction,
        requiredRole: null
      },
      {
        path: ROOT,
        component: Home,
        requiredRole: null
      },
      {
        path: CREATE_DISCOUNTS,
        component: CreateDiscount,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCRS,
        component: PricingExecutionCenter,
        requiredRole: PrcCommonRoles
      },
      //PCR Sandbox Paths
      {
        path: PATH_NAMES.PCR_SANDBOX,
        component: PcrInformation,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_FRONTLINE_DETAILS,
        component: PcrFrontline,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_FRONTLINE_FOB_ADJUSTMENT,
        component: PcrFobAdjustment,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_DISCOUNT_DETAILS,
        component: PcrDiscountDetails,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_CREATE_DISCOUNT,
        component: PcrCreateDiscount,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_DISCOUNT_CALENDAR,
        component: PcrDiscountCalendar,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_MASS_COPY_HOME,
        component: PcrMassCopy,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_MASS_COPY,
        component: PcrMassCopy,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_GUIDELINES_SANDBOX,
        component: GuidelineLog,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PCR_INFORMATION,
        component: PcrInformation,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.IMPORT_WIZARD,
        component: ImportWizard,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.NATIONAL_FOB_DELETE,
        component: FobDelete,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.NEW_BRAND_PACKAGE,
        component: NewBrandPackage,
        requiredRole: PrcCommonRoles
      },
      // new sandbox components
      {
        path: PATH_NAMES.PCR_FRONTLINE_DETAILS,
        component: PcrFrontline,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_FRONTLINE_FOB_ADJUSTMENT,
        component: UnderConstruction404,
        requiredRole: null
      },
      {
        path: PATH_NAMES.PLAN_DISCOUNT_DETAILS,
        component: PlanDiscountDetails,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_CREATE_DISCOUNT,
        component: PlanCreateDiscount,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_DISCOUNT_CALENDAR,
        component: PlanDiscountCalendar,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_MASS_COPY,
        component: PlanMassCopy,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_GUIDELINES_SANDBOX,
        component: PlanGuidelineSandbox,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_INFORMATION,
        component: PlanInformation,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_CALENDAR_ROLLOVER,
        component: PlanCalendarRollover,
        requiredRole: PrcCommonRoles
      },
      {
        path: PATH_NAMES.PLAN_FRONTLINE_DETAILS,
        component: PlanFrontlineDetails,
        requiredRole: PrcCommonRoles
      }
      // end new  sandbox components
    ]
  }
];

// eslint-disable-next-line prettier/prettier
const buildRoutes = (routesConfig: RoutesType[], parentPath = ''): UseRoutesConfigType => {
  const routesArray = [];

  for (let i = 0; i < routesConfig.length; i++) {
    const route: RoutesType = routesConfig[i];

    const Guard = route.guard || RoleRoute;
    const Layout = route.layout || Fragment;
    const Component = route.component ? route.component : Fragment;
    const path: string = parentPath + route.path;
    // eslint-disable-next-line prettier/prettier
    const children: RoutesType[] | undefined = route.routes ? buildRoutes(route.routes, path) : undefined;

    routesArray.push({
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <Guard requiredRoles={route.requiredRole}>
            <Layout>
              <Component />
              <Outlet />
            </Layout>
          </Guard>
        </Suspense>
      ),
      path: path,
      children: children
    });
  }
  return routesArray;
};

export default function AllRoutes() {
  const element = useRoutes(buildRoutes(routesConfig as RoutesType[]));
  return element;
}

export function getHomeRoute() {
  return PATH_NAMES.ROOT;
}

export function buildGuidlinesRoute(planId: string) {
  return `/plans/${planId}/guideline-log`;
}
