//RTK
import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ToastProps } from './SnackbarToast';

type SnackbarAlertState = {
  toastsPack: ToastProps[];
};

export const snackbarAlertInitialState: SnackbarAlertState = {
  toastsPack: []
};

export const snackbarAlertSlice = createSlice({
  name: 'snackbarAlert',
  initialState: snackbarAlertInitialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastProps>) => {
      const { toastsPack } = state;
      const key = nanoid();

      // Prevent duplicated toasts
      if (toastsPack.find((toast) => toast.key === key)) {
        return;
      }

      if (toastsPack.length > 2) {
        toastsPack.pop();
      }

      toastsPack.unshift({ ...action.payload, key });
    },
    removeToast: (state, action: PayloadAction<string>) => {
      const updatedToastPack = state.toastsPack.filter((toast) => toast.key !== action.payload);
      state.toastsPack = updatedToastPack;
    }
  }
});

export const { addToast, removeToast } = snackbarAlertSlice.actions;

export const selectToastsPack = (state: RootState) => state.snackbarAlert.toastsPack;

export default snackbarAlertSlice.reducer;
