//RTK
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

type ICreatePlansState = {
  allStates: any[]; //all state data, with additional properties
  createPlansStates: any[]; //list of states for plans to submit
  activeStep: number;
  steps: ['Select States', 'Select Plan Names', 'Submit'];
  showMessageBox: boolean;
  filterBySelectedStates: boolean; // determines if selected states are shown
  plansSubmitted: boolean;
};

const initialState: ICreatePlansState = {
  allStates: [],
  createPlansStates: [],
  activeStep: 0,
  steps: ['Select States', 'Select Plan Names', 'Submit'],
  showMessageBox: false,
  filterBySelectedStates: false,
  plansSubmitted: false
};

export const createPlansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setAllStates: (state, action) => {
      state.allStates = action.payload;
    },
    setCreatePlansStates: (state, action) => {
      state.createPlansStates = action.payload;
    },
    incrementStep: (state) => {
      if (state.activeStep < state.steps.length) state.activeStep += 1;
      if (state.activeStep > 1) state.showMessageBox = true;
      if (state.activeStep > 0) {
        state.filterBySelectedStates = true;
      }
    },
    decrementStep: (state) => {
      if (state.activeStep > 0) state.activeStep -= 1;
      if (state.activeStep <= 1) state.showMessageBox = false;
      if (state.activeStep < 1) {
        state.filterBySelectedStates = false;
      }
    },
    resetSteps: (state) => {
      state.activeStep = 0;
      state.showMessageBox = false;
    },
    resetPlansState: (state) => {
      return initialState;
    },
    handleCreateMorePlans: (state) => {
      return {
        ...initialState,
        allStates: state.allStates
      };
    },
    updatePlanName: (state, action) => {
      const { stateCd, newPlanName } = action.payload;
      const index = state.allStates.findIndex((x) => x.stateCd === stateCd);
      state.allStates[index].planName = newPlanName;
    },
    togglePlansSubmitted: (state) => {
      state.plansSubmitted = !state.plansSubmitted;
    }
  }
});

export const {
  setAllStates,
  setCreatePlansStates,
  incrementStep,
  decrementStep,
  resetSteps,
  resetPlansState,
  updatePlanName,

  togglePlansSubmitted,
  handleCreateMorePlans
} = createPlansSlice.actions;

export const selectActiveStep = (state: RootState) => state.plans.activeStep;
export const selectSteps = (state: RootState) => state.plans.steps;
export const selectShowMessageBox = (state: RootState) => state.plans.showMessageBox;
export const selectCreatePlansStates = (state: RootState) => state.plans.createPlansStates;
export const selectFilterBySelectedStates = (state: RootState) => state.plans.filterBySelectedStates;
export const selectAllStates = (state: RootState) => state.plans.allStates;
export const selectPlansSubmitted = (state: RootState) => state.plans.plansSubmitted;

export default createPlansSlice.reducer;
