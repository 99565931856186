//React-RTK
import React, { ReactElement } from 'react';
import { selectUserRoles } from '../features/Auth/AuthLoginSlice';
import {
  AccessDeniedHeader,
  AccessDeniedParagraph,
  RoleRouteContainer,
  RoleRouteContainerCard,
  RoleRouteContainerImage,
  ShieldIcon
} from './RoleRoute.styles';
//Hooks
import { useAppSelector } from '../app/hooks';

//types
import { IAllUserTypes } from '../features/Auth/types';
const RoleRoute = ({
  children,
  requiredRoles
}: {
  children: ReactElement;
  requiredRoles: IAllUserTypes[] | null | undefined;
}) => {
  const userRoles = useAppSelector(selectUserRoles);
  if (!requiredRoles) return <>{children}</>;
  //Iterate over the user's roles array from the store
  for (let index = 0; index < userRoles.length; index++) {
    //If the required roles array includes any of the user's roles, then return the children immediately
    if (requiredRoles.includes(userRoles[index])) return <>{children}</>;
  }

  //If the for-loop terminates, return an empty fragment, children are not rendered
  return (
    <RoleRouteContainer>
      <RoleRouteContainerImage />
      <RoleRouteContainerCard>
        <ShieldIcon />
        <AccessDeniedHeader>Access Denied</AccessDeniedHeader>
        <AccessDeniedParagraph>You are missing the required roles to view this page.</AccessDeniedParagraph>
        <AccessDeniedParagraph>Required Role/s: {requiredRoles.join(', ')}.</AccessDeniedParagraph>
      </RoleRouteContainerCard>
    </RoleRouteContainer>
  );
};

export default RoleRoute;
