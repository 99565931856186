//React-RTK
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { setUserData } from '@FAuth/AuthLoginSlice';

//hooks
import { useAppDispatch } from '@app/hooks';

//MSAL
import { MsalAuthenticationResult, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import {
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
  InteractionStatus,
  IPublicClientApplication,
  stubbedPublicClientApplication,
  Logger,
  AccountInfo
} from '@azure/msal-browser';

export type AccountIdentifiers = Partial<Pick<AccountInfo, 'homeAccountId' | 'localAccountId' | 'username'>>;

type FaaCFunction = <T>(args: T) => React.ReactNode;

export function getChildrenOrFunction<T>(children: React.ReactNode | FaaCFunction, args: T): React.ReactNode {
  if (typeof children === 'function') {
    return children(args);
  }
  return children;
}

export interface IMsalContext {
  instance: IPublicClientApplication;
  inProgress: InteractionStatus;
  accounts: AccountInfo[];
  logger: Logger;
}

/*
 * Stubbed context implementation
 * Only used when there is no provider, which is an unsupported scenario
 */
const defaultMsalContext: IMsalContext = {
  instance: stubbedPublicClientApplication,
  inProgress: InteractionStatus.None,
  accounts: [],
  logger: new Logger({})
};

//MSAL Id Token Claims type for result
interface IDTokenClaims {
  aud?: string;
  iss?: string;
  iat?: number;
  nbf?: number;
  exp?: number;
  aio?: string;
  email?: string;
  name?: string;
  nonce?: string;
  oid?: string;
  preferred_username?: string;
  rh?: string;
  roles?: string[];
  sub?: string;
  tid?: string;
  uti?: string;
  ver?: string;
}

export const MsalContext = React.createContext<IMsalContext>(defaultMsalContext);
export const MsalConsumer = MsalContext.Consumer;

export type MsalAuthenticationProps = PropsWithChildren<
  AccountIdentifiers & {
    interactionType: InteractionType;
    authenticationRequest?: PopupRequest | RedirectRequest | SsoSilentRequest;
    loadingComponent?: React.ElementType<IMsalContext>;
    errorComponent?: React.ElementType<MsalAuthenticationResult>;
  }
>;

/**
 * Attempts to authenticate user if not already authenticated, then renders child components
 * @param props
 */
export function MsalAuthenticationTemplate({
  interactionType,
  username,
  homeAccountId,
  localAccountId,
  authenticationRequest,
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  children
}: MsalAuthenticationProps): React.ReactElement | null {
  const accountIdentifier: AccountIdentifiers = useMemo(() => {
    return {
      username,
      homeAccountId,
      localAccountId
    };
  }, [username, homeAccountId, localAccountId]);
  const context = useMsal();
  const msalAuthResult = useMsalAuthentication(interactionType, authenticationRequest, accountIdentifier);
  const isAuthenticated = useIsAuthenticated(accountIdentifier);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (context.inProgress === InteractionStatus.None) {
      let tokenClaims: IDTokenClaims | null | undefined = null;
      if (msalAuthResult.result) {
        tokenClaims = msalAuthResult.result.idTokenClaims;
      } else {
        const allAcounts = context.instance.getAllAccounts(); //I guess you can have multiple accounts?
        const account = allAcounts.find((x) => x !== undefined); //Return first element of array. If no accounts, return undefined
        tokenClaims = account?.idTokenClaims;
      }
      if (tokenClaims) {
        const userRoles: string[] | undefined = tokenClaims.roles;
        const userName: string | undefined = tokenClaims.preferred_username;
        dispatch(
          setUserData({
            userRoles,
            userName
          })
        );
      }
    }
  }, [context.instance, context.inProgress]);

  if (msalAuthResult.error && context.inProgress === InteractionStatus.None) {
    if (ErrorComponent) {
      return <ErrorComponent {...msalAuthResult} />;
    }

    throw msalAuthResult.error;
  }

  if (isAuthenticated) {
    return <React.Fragment>{getChildrenOrFunction(children, msalAuthResult)}</React.Fragment>;
  }

  if (!!LoadingComponent && context.inProgress !== InteractionStatus.None) {
    return <LoadingComponent {...context} />;
  }

  return null;
}
