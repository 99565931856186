//RTK
import { createSlice } from '@reduxjs/toolkit';
import { ConsumptionType } from '../../../api/priceApi/priceApi';
import { RootState } from '../../../app/store';
import { EffectiveDateType } from './types/EffectiveDateType';
// import { nanoid } from '@reduxjs/toolkit';

type VarSplitBuilderState = {
  totalDiscount: number;
};

type ICreateDiscountState = {
  recordSeqId: number;
  rehydrateData: boolean;

  //This will be easier to maintain in the future if you had a model here. there is one in CreateDiscounts.tsx
  wholesalerSpecific: any[];

  effectiveDates: EffectiveDateType[];
  selectedWslrCustGrpSeqIds: any;
  consumptionType: ConsumptionType;
  disableMixMatch: boolean;
  varSplitBuilderState: VarSplitBuilderState;
  dimensionSelections: {
    geographies: number[];
    brandSeqIds: number[];
    pkgGrpSeqIds: number[];
    alcoholStrengths: string[];
  };
};

const initialState: ICreateDiscountState = {
  recordSeqId: 0,
  rehydrateData: true,
  wholesalerSpecific: [],
  effectiveDates: [
    {
      id: 0,
      startDate: '',
      endDate: ''
    }
  ],
  selectedWslrCustGrpSeqIds: [],
  consumptionType: 'Combined',
  disableMixMatch: true,
  varSplitBuilderState: {
    totalDiscount: 0
  },
  dimensionSelections: {
    geographies: [],
    brandSeqIds: [],
    pkgGrpSeqIds: [],
    alcoholStrengths: []
  }
};

export const createDiscountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    setWholesalerSpecific: (state, action) => {
      const wholesalerSpecific = action.payload;
      state.wholesalerSpecific = wholesalerSpecific;
    },
    setSelectedWslrCustGrpSeqIds: (state, action) => {
      const selectedWslrCustGrpSeqIds = action.payload;
      state.selectedWslrCustGrpSeqIds = selectedWslrCustGrpSeqIds;
    },
    setConsumptionType: (state, action) => {
      const consumptionType = action.payload;
      state.consumptionType = consumptionType;
    },
    setEffectiveDates: (state, action) => {
      const effectiveDates = action.payload;
      state.effectiveDates = effectiveDates;
    },
    setDisableMixMatch: (state, action) => {
      const disableMixMatch = action.payload;
      state.disableMixMatch = disableMixMatch;
    },
    setRecordSeqId: (state, action) => {
      const recordSeqId = action.payload;
      state.recordSeqId = recordSeqId;
    },
    setRehydrateData: (state, action) => {
      const rehydrateData = action.payload;
      state.rehydrateData = rehydrateData;
    },
    setVarSplitTotalDiscount: (state, action) => {
      const totalDiscount = action.payload;
      state.varSplitBuilderState = { totalDiscount: Number(totalDiscount) };
    }
  }
});

export const {
  setWholesalerSpecific,
  setSelectedWslrCustGrpSeqIds,
  setConsumptionType,
  setEffectiveDates,
  setDisableMixMatch,
  setRecordSeqId,
  setRehydrateData,
  setVarSplitTotalDiscount
} = createDiscountSlice.actions;

export const selectWholesalerSpecific = (state: RootState) => state.discount.wholesalerSpecific;
export const selectSelectedWslrCustGrpSeqIds = (state: RootState) => state.discount.selectedWslrCustGrpSeqIds;
export const selectConsumptionType = (state: RootState) => state.discount.consumptionType;
export const selectEffectiveDates = (state: RootState) => state.discount.effectiveDates;
export const selectDisableMixMatch = (state: RootState) => state.discount.disableMixMatch;
export const selectRecordSeqId = (state: RootState) => state.discount.recordSeqId;
export const selectRehydrateData = (state: RootState) => state.discount.rehydrateData;

export const selectVarSplitBuilderSTate = (state: RootState) => state.discount.varSplitBuilderState;
export const selectVarSplitTotalDiscount = (state: RootState) => {
  if (state.discount.varSplitBuilderState) {
    return state.discount.varSplitBuilderState.totalDiscount;
  } else {
    return 0;
  }
};

export default createDiscountSlice.reducer;
