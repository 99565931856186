//RTK
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

type IFobAdjustmentState = {
  geographies: number[]; //TODO: isn't this seqid and wholesaler number combo?
  brands: number[];
  packages: number[];
  alcohols: number[];

  predomPtr: number;
  predomFob: number;
  allowValueUpdates: boolean;
  wholesalerTableData: IWholesalerFoB[];
};

const initialState: IFobAdjustmentState = {
  geographies: [],
  brands: [],
  packages: [],
  alcohols: [],
  predomPtr: 0,
  predomFob: 0,
  allowValueUpdates: false,
  wholesalerTableData: []
};

//when selection changes, update the wholesaler specific table
export type IWholesalerFoB = {
  displayName: string;
  wslrNumber: string;
  wslrCustGrpSeqId: string;
  fob: number | null; // a price in 3 decimal places
  ptr: number | null; // a price in 2 decimal places
};

export const fobAdjustmentSlice = createSlice({
  name: 'fobAdjustment',
  initialState,
  reducers: {
    setGeographies: (state, action) => {
      state.geographies = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setAlcohols: (state, action) => {
      state.alcohols = action.payload;
    },

    setWholesalerTableData: (state, action) => {
      state.wholesalerTableData = action.payload;
    },
    setPredomFob: (state, action) => {
      state.predomFob = action.payload;
    },
    setPredomPtr: (state, action) => {
      state.predomPtr = action.payload;
    },
    setAllowValueUpdates: (state, action) => {
      state.allowValueUpdates = action.payload;
    },
    resetState: (state) => {
      state.geographies = [];
      state.brands = [];
      state.packages = [];
      state.alcohols = [];
      state.predomPtr = 0;
      state.predomFob = 0;
      state.wholesalerTableData = [];
    }
  }
});

export const {
  setGeographies,
  setBrands,
  setPackages,
  setAlcohols,
  setWholesalerTableData,
  setPredomFob,
  setPredomPtr,
  setAllowValueUpdates,
  resetState
} = fobAdjustmentSlice.actions;

export const selectGeographies = (state: RootState) => state.fobAdjustment.geographies;
export const selectBrands = (state: RootState) => state.fobAdjustment.brands;
export const selectPackages = (state: RootState) => state.fobAdjustment.packages;
export const selectAlcohols = (state: RootState) => state.fobAdjustment.alcohols;
export const selectWholesalerTableData = (state: RootState) => state.fobAdjustment.wholesalerTableData;
export const selectPredomFob = (state: RootState) => state.fobAdjustment.predomFob;
export const selectPredomPtr = (state: RootState) => state.fobAdjustment.predomPtr;
export const selectAllowValueUpdates = (state: RootState) => state.fobAdjustment.allowValueUpdates;
export default fobAdjustmentSlice.reducer;
