// React-RTK
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { nanoid } from '@reduxjs/toolkit';
import { INotification } from './NotificationTypes';
import { ProblemDetails } from '../../api/priceApi/priceApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export type INotificationsState = {
  barOpen: boolean;
  badgeCount: number;
  notificationsList: INotification[];
};

const initialState: INotificationsState = {
  barOpen: false,
  badgeCount: 0,
  notificationsList: [] as INotification[]
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleBar: (state) => {
      state.barOpen = !state.barOpen;
      state.badgeCount = 0;
    },
    addNotificationFromHook: (state, action) => {
      // eslint-disable-next-line prefer-const
      let { externalId, messageId, data, error, fetchError, errorString, title, message, severity, taskDescription } =
        action.payload;

      const notificationId = externalId ? externalId : nanoid();
      if (!title) {
        title = 'Pending';
      }

      if (!severity) {
        severity = 'info';
      }
      if (error as ProblemDetails) {
        //if error is defined, then it came from the API as a ProblemDetails object
        message = error.data.title;
        title = `Error while ${taskDescription}`;
        severity = 'error';
      } else if (fetchError as FetchBaseQueryError | SerializedError) {
        //if fetchError is defined, then it came from an unhandled exception or RTK Query
        if (fetchError) {
          message = fetchError.error;
        }
        title = `Error while ${taskDescription}`;
        severity = 'error';
      } else if (errorString as string) {
        //if errorString is defined, then it came from a custom error string passed by the program
        message = errorString;
        title = `Error while ${taskDescription}`;
        severity = 'error';
      }

      state.notificationsList.push({ data, notificationId, title, message, severity, taskDescription, messageId });
      state.badgeCount++;
    },
    updateNotificationFromHook: (state, action) => {
      //using let here because we're going to change them if there is an error, silly.
      // eslint-disable-next-line prefer-const
      let { notificationId, data, error, fetchError, errorString, title, message, severity, taskDescription } =
        action.payload;

      if (error as ProblemDetails) {
        //if error is defined, then it came from the API as a ProblemDetails object
        message = error.data.title;
        title = `Error while ${taskDescription}`;
        severity = 'error';
      } else if (fetchError as FetchBaseQueryError | SerializedError) {
        //if fetchError is defined, then it came from an unhandled exception or RTK Query
        if (fetchError) {
          message = fetchError.error;
        }
        title = `Error while ${taskDescription}`;
        severity = 'error';
      } else if (errorString as string) {
        //if errorString is defined, then it came from a custom error string passed by the program
        message = errorString;
        title = `Error while ${taskDescription}`;
        severity = 'error';
      }

      //update the notification's title, severity, and message
      const newNotificationList = state.notificationsList.map((notification) => {
        if (notification.notificationId === notificationId) {
          return {
            ...notification,
            title,
            severity,
            message,
            data
          };
        }

        return { ...notification };
      });
      state.notificationsList = newNotificationList;
    },

    removeNotification: (state, action: PayloadAction<string>) => {
      state.notificationsList = state.notificationsList.filter((item) => item.notificationId != action.payload);
      //if there are no notifications, close the drawer
      if (state.notificationsList.length < 1) state.barOpen = false;
    },
    clearNotifications: (state) => {
      state.notificationsList = [];
      state.barOpen = false;
    }
  }
});

export const {
  toggleBar,
  addNotificationFromHook,
  updateNotificationFromHook,
  removeNotification,
  clearNotifications
} = notificationsSlice.actions;

export const selectBarOpen = (state: RootState) => state.notifications.barOpen;
export const selectNotificationsList = (state: RootState) => state.notifications.notificationsList;
export const selectBadgeCount = (state: RootState) => state.notifications.badgeCount;

export default notificationsSlice.reducer;
